import { ZIP_RESET, ZIP_SET } from "../actions/zip.actions";

const initialState = {
    id: null,
    zip: null,
    name: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ZIP_SET:
            return {
                ...action.payload.zip
            };

        case ZIP_RESET:
            return initialState;
        default:
            return state;
    }
}
