import React, { Component } from 'react';
import {translate} from 'react-i18next';
import { connect } from "react-redux";
import Input from "../../Input/Input";
import "./index.scss";
import { serviceFilterFocused } from "../../../../store/actions/global.actions";
import classNames from "classnames";

class AdditionalServiceFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            (this.props.global.serviceFilterFocused !== nextProps.global.serviceFilterFocused) &&
            (this.props.global.serviceFilterFocused === true) &&
            (nextProps.global.serviceFilterFocused === false) &&
            this.state.value !== ''
        ) {
            this.setState({
                value: ''
            }, this.props.onChange(''))
        }
    }


    handleTextChange = (e) => {
        this.setState({
            value : e.target.value
        }, this.props.onChange(e.target.value))
    };

    render() {
        const { t, hideTitle, global: { serviceFilterFocused : isFocused }, serviceFilterFocused } = this.props;
        const { value } = this.state;

        return (
            <div className={classNames("additional-service-filter clearfix", {
                "additional-service-filter--active" : isFocused
            })}>
                {!hideTitle && <p className="title-service">{t('global.additional_service.open')}</p>}
                <Input
                    type="text"
                    name="filter"
                    value={value}
                    iconLeftClassName="icon-magnifier"
                    onChange={this.handleTextChange}
                    onClick={() => {
                        if (window.innerWidth < 992) {
                            serviceFilterFocused(true)
                        }
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
        return {
            global: state.global
        }
};
export default connect(mapStateToProps, {
    serviceFilterFocused
})(translate('translations')(AdditionalServiceFilter));
