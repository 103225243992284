export const GARAGE_LIST = '[Garage List]';

export const GARAGE_LIST_FETCH = `${GARAGE_LIST} FETCH`;
export const GARAGE_LIST_SET = `${GARAGE_LIST} SET`;
export const GARAGE_LIST_RESET = `${GARAGE_LIST} RESET`;
export const GARAGE_LIST_VIEWS = `${GARAGE_LIST} VIEWS`;
export const GARAGE_LIST_SAVE = `${GARAGE_LIST} SAVE`;

export const getGarageList = (official, labour, filters = null, sort = null, updateFilterStatus = false) => ({
    type: GARAGE_LIST_FETCH,
    payload: {
        official,
        labour,
        filters,
        sort,
        updateFilterStatus
    }
});

export const setGarageList = list => ({
    type: GARAGE_LIST_SET,
    payload: {
        list
    }
});

export const resetGarageList = list => ({
    type: GARAGE_LIST_RESET,
    payload: {
        list
    }
});

export const garageListViews = list => ({
    type: GARAGE_LIST_VIEWS,
    payload: {
        repair_shops: list
    }
});

export const saveGarageList = email => ({
    type: GARAGE_LIST_SAVE,
    payload: {
        email
    }
});
