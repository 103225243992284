import store from '../../store/store';
import history from '../../routes/history';
import routes from "../index";
import React from "react";
import {fieldExists} from "../../utils/fieldExists";

export const HasOfferToken = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            const car = store.getState().car;
            this.shouldRender = true;

            if( !(!!car && fieldExists(car, 'token')) ){
                this.shouldRender = false;
                history.replace(routes[0].path);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }

    }

};

export default HasOfferToken;