//@flow
import React, { Component } from 'react';
import './input.scss';
import classNames from 'classnames';

type Props = {
    value?: string,
    label?: string,
    placeholder?: string,
    name: string,
    id: string,
    type: string,
    onChange?: Function,
    onBlur?: Function
};

type State = {
    value: string
};

class Input extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value || ''
        };
        this.inputRef = React.createRef();
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange = (e: Event, callback) => {
        if (e.target instanceof HTMLInputElement) {

            let maxLen = parseInt(e.target.maxLength);
            if(maxLen > 0 && e.target.value.toString().length > maxLen){
                return;
            }

            this.setState({ value: e.target.value });

            callback();
        }
    };

    shouldShowRightIcon = () => {
        return this.props.iconRightClassName && this.state.value;
    };

    handleTextClear = () => {
        this.setState({
            value: ''
        });
        this.props.onTextClear();
    };

    render() {
        const { value } = this.state;
        const {
            type,
            name,
            id,
            placeholder,
            label,
            onChange,
            onClick,
            onBlur,
            onPaste,
            error,
            iconLeftClassName,
            className,
            iconRightClassName,
            maxLength,
            extraClass,
            hideErrorMessage,
            autoFocus,
            preventOnEnter,
            hidden,
            onKeyPress,
            readonly
        } = this.props;

        if (hidden) return null;

        return (
            <div className={`input-control ${extraClass}`}>
                {label && <label htmlFor={name}>{label}</label>}
                <div className="input-wrapper">
                    {iconLeftClassName && (
                        <div className="input-icon input-icon--left">
                            <span className={iconLeftClassName} />
                        </div>
                    )}
                    <input
                        onKeyPress={onKeyPress}
                        ref={ref => this.inputRef = ref}
                        type={type}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        className={classNames(className, {
                            'has-left-icon': iconLeftClassName,
                            'has-right-icon': this.shouldShowRightIcon(),
                            'input-error': error
                        })}
                        onChange={e => {
                            this.handleChange(e, () => {
                                if (onChange) {
                                    onChange(e);
                                }
                            });
                        }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                        onPaste={e => {
                            if (onPaste)
                                onPaste(e.clipboardData.getData('text/plain'));
                            return false;
                        }}
                        value={value}
                        checked={this.props.checked} //TODO Remove when input checkbox component is added
                        autoComplete="off"
                        maxLength={maxLength}
                        readOnly={readonly}
                        autoFocus={autoFocus}
                        onKeyDown={e => {
                            if (preventOnEnter && e.keyCode === 13) {
                                e.preventDefault();
                                return false;
                            }
                        }}
                    />
                    {this.shouldShowRightIcon() ? (
                        <div
                            className="input-icon input-icon--right"
                            onClick={this.handleTextClear}
                        >
                            <span className={iconRightClassName} />
                        </div>
                    ) : null}
                </div>
                {error && !hideErrorMessage && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

export default Input;
