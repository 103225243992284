//@flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from 'formik';
import Input from '../../../components/shared/Input/Input';
import {userLogout, userUpdate} from '../../../store/actions/user.actions';
import {formSubmittingBooking} from '../../../store/actions/formSubmitting.actions';
import InputCheckout from '../../shared/InputCheckout/InputCheckout';
import {bookServiceSend} from '../../../store/actions/booking.actions';
import {setGarageFormIsSubmitted} from '../../../store/actions/formGarage.actions';
import Button from '../../shared/Button/Button';
import {formFinishBookingConfig} from '../../../app/config/yup';
import {applyGarageDiscount} from "../../../store/selectors/garage";
import {voucherHideElements} from "../../../utils/voucherHideElements";
import {scroller} from "../../../utils/scroller";

class FormFinishBooking extends Component<State> {
    componentDidMount() {
        if (this.props.scrollToForm) {
            scroller('from', {
                duration: 500,
                smooth: "ease"
            })
        }
    }

    handleTextChange = (e: Event) => {
        const {handleChange} = this.props;
        const {name, value, checked} = e.target;

        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'checkbox') {
                this.setState({
                    [name]: checked
                });
            } else {
                this.setState({
                    [name]: value
                });
            }

            handleChange(e);

            if (e.target.id === 'agb_agreement') {
                this.props.updateAgbAgreement(e.target.checked);
            }
        }
    };

    logout = () => {
        this.props.userLogout();
    };

    nonValidElement = (errors) => {
        if (Object.keys(errors).length === 0 && errors.constructor === Object) return 'form';

        return Object.keys(errors)[0];
    };

    submitForm = e => {
        e.preventDefault();
        const {submitForm, setGarageFormIsSubmitted, formGarage} = this.props;

        if (!this.props.isValid) {
            scroller(this.nonValidElement(this.props.errors), {
                duration: 500,
                smooth: "ease"
            })
        }

        setGarageFormIsSubmitted(true);
        if (formGarage.isDatePickerValid) {
            submitForm();
        }
    };

    render() {
        const {
            t,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            values,
            garage,
            user,
            formSubmitting,
        } = this.props;

        return (
            <React.Fragment>
                <div className="logged-user-data">
                    <div className="row">
                        <div className="col-md-7 col-xs-6">
                            <img src={user.avatar} alt="Avatar"/> <span>{t('pages.garage.logged_as')} <strong
                            className="logged-user-data__name">{user.first_name} {user.last_name}</strong>.</span>
                            <div>
                                {t('pages.garage.link_logout')} <Button extraClass="btn--link"
                                                                        onClick={this.logout}>{t('pages.garage.link_logout_here')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="mb-10 font-size-2 text-strong text-light">{t('pages.garage.add_more_inputs')}</div>*/}
                {/*<div className="mb-20 font-size-2 text-strong">*/}
                {/*    {t('pages.garage.add_confirm_inputs')}*/}
                {/*</div>*/}

                <form onSubmit={handleSubmit} id="form">
                    <div className="row">
                        <div className="col-md-4">
                            <Input
                                type="text"
                                id="phone"
                                name="phone"
                                label={t('form.label.phone')}
                                value={values.phone}
                                placeholder={t('form.placeholder.phone')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone}
                            />
                        </div>
                    </div>
                    {!user.first_name && (
                        <Input
                            type="text"
                            id="first_name"
                            name="first_name"
                            label={t('form.label.first_name')}
                            placeholder={t('form.placeholder.first_name')}
                            onChange={this.handleTextChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            error={touched.first_name && errors.first_name}
                        />
                    )}
                    {!user.last_name && (
                        <Input
                            type="text"
                            id="last_name"
                            name="last_name"
                            label={t('form.label.last_name')}
                            placeholder={t('form.placeholder.last_name')}
                            onChange={this.handleTextChange}
                            onBlur={handleBlur}
                            checked={this.props.agbAgreement}
                            error={touched.last_name && errors.last_name}
                        />
                    )}
                    <InputCheckout
                        id="agb_agreement"
                        name="agb_agreement"
                        onChange={this.handleTextChange}
                        onBlur={handleBlur}
                        checked={this.props.agbAgreement}
                        label={
                            <span className="label-has-link">{t('form.label.agb_agreement')} <a target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                                href={window.settings.termsAndConditionsLink}>{t('form.label.terms_and_condition')}</a></span>
                        }
                        error={touched.agb_agreement && errors.agb_agreement}
                        inputControlClass="mt-30"
                    />
                    <div className="row checkout">
                        <button
                            onClick={this.submitForm}
                            className="btn btn-primary btn-md"
                            type="submit"
                            disabled={formSubmitting.booking}
                        >
                            {
                                !voucherHideElements() ?
                                    t('pages.garage.btn_checkout_for', {price: `${t('global.currency')} ${garage.totalPrice}`})
                                    :
                                    t('pages.garage.btn_checkout')
                            }
                        </button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const FormikApp = withFormik(formFinishBookingConfig)(FormFinishBooking);

const mapStateToProps = state => {
    return {
        user: state.user,
        garage: applyGarageDiscount(state),
        formSubmitting: state.formSubmitting,
        formGarage: state.formGarage,
        global: state.global,
        garageDiscount: state.garageDiscount,
    };
};

export default connect(
    mapStateToProps,
    {
        userLogout,
        userUpdate,
        bookServiceSend,
        formSubmittingBooking,
        setGarageFormIsSubmitted
    }
)(translate('translations')(FormikApp));
