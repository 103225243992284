export const ERROR = '[Error]';

export const ERROR_SET = `${ERROR} SET`;
export const ERROR_REMOVE = `${ERROR} REMOVE`;

export const setError = (id, message) => ({
    type: ERROR_SET,
    payload: {
        id,
        message
    }
});

export const removeError = (id) => ({
    type: ERROR_REMOVE,
    payload: {
        id
    }
});
