import React, {Component} from 'react';
import './Filter.scss';
import {translate} from "react-i18next";
import SelectedFilter from "./SelectedFilter";
import connect from "react-redux/es/connect/connect";
import {filterRemove, filterResetZip, filterUpdateZipWithoutStatus} from "../../../store/actions/filter.actions";
import {FILTER_MODIFIED_AND_SAVED} from "../../../store/consts/filter.constants";
import {getGarageList} from "../../../store/actions/garageList.actions";
import {clearZipData, setZipData, fetchZipData} from "../../../store/actions/zipData.actions";

class SelectedFilterList extends Component {

    handleZipReset = () => {
        const {clearZipData, zip, filterUpdateZipWithoutStatus, filterResetZip, fetchZipData} = this.props;

        filterResetZip();
        // setZipData(car.zipData);
        clearZipData();
        fetchZipData(zip.zip);
        filterUpdateZipWithoutStatus(zip);
    };

    render() {
        const {filters, filterRemove, t, getGarageList, services: {official, labour}} = this.props;

        let selectedFilters = [];

        for (let key in filters) {
            if (filters.hasOwnProperty(key) && filters[key].status === FILTER_MODIFIED_AND_SAVED) {
                selectedFilters.push(<SelectedFilter
                    key={filters[key].id}
                    filter={filters[key]}
                    onRemove={(filterId) => {
                        filterRemove(filters[key].id);

                        if (filterId === 'zipId') {
                            this.handleZipReset();
                        }

                        setTimeout(() => {
                            getGarageList(official, labour, this.props.filters, null, true);
                        }, 0);
                    }}
                />)
            }
        }

        if (!selectedFilters.length) {
            return (
                <div className="selected-filter-list selected-filter-list--no-filter">
                    <p>{t('global.filter.no_filters')}</p>
                </div>
            );
        }

        return (
            <div className="selected-filter-list">
                {selectedFilters}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filters: state.filters,
        services: state.services,
        car: state.car,
        zip: state.zip,
    };
};

export default connect(mapStateToProps, {
    filterRemove,
    filterResetZip,
    setZipData,
    fetchZipData,
    clearZipData,
    getGarageList,
    filterUpdateZipWithoutStatus
})(translate('translations')(SelectedFilterList));
