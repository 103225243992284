import i18n from '../../app/config/i18n';
import { SORT_BY_CHANGE } from '../actions/sort.actions';
import { voucherHideElements } from "../../utils/voucherHideElements";

const t = i18n.store.data[window.base.language].translations.pages.services.sort_by;


const initialState = [
    {
        id: 1,
        active: !!!voucherHideElements(),
        key: 'best_match',
        name: t.best_match
    },
    {
        id: 2,
        active: false,
        key: 'price',
        name: t.affordability
    },
    {
        id: 3,
        active: !!voucherHideElements(),
        key: 'distance',
        name: t.proximity
    }
];

export default function(state = initialState, action) {
    switch (action.type) {
        case SORT_BY_CHANGE:
            return state.map(item => {
                item.active = (item.id === action.payload.id);
                return item;
            });
        default:
            return state;
    }
}
