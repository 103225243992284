import React, {Component} from 'react';
import {translate} from "react-i18next";
import InputRange from "react-input-range";
import classNames from 'classnames';

class InputRangeWrapper extends Component {
    render() {
        const { t, from, to, value, unit, onChange, isDisabled, label } = this.props;

        return (
            <div className={classNames("input-range__container", {
                "input-range__container--disabled": isDisabled === true
            })}>
                <div className="input-range__header">
                    <p className="title">{label}</p>
                    <p className="value">
                        {value.min} {unit}
                        &nbsp;{t('global.to')}&nbsp;
                        {value.max} {unit}
                    </p>
                </div>
                <div className="input-range__body">
                    <InputRange
                        draggableTrack
                        minValue={from}
                        maxValue={to}
                        value={value}
                        onChange={value => onChange(value)} />
                </div>
            </div>
        )
    }
}

export default translate('translations')(InputRangeWrapper);
