import { DIALOG_GET, DIALOG_SET } from '../actions/dialog.actions';
import dialog from '../models/dialog';

export default function(state = dialog, action) {
    switch (action.type) {
        case DIALOG_SET:
            return {
                ...state,
                [action.payload.name]: action.payload.isOpened
            };

        case DIALOG_GET:
            return { ...state };

        default:
            return state;
    }
}
