/* eslint-disable */
import React from "react";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage'
        },
        titles: {
            home: 'Carhelper – Enter your car information',
            services: 'Carhelper – Compare prices of garages in {{city}}',
            garage: 'Carhelper – Book a service at {{garageName}}',
            booking: 'Carhelper – Your request has been sent.',
        },
        global: {
            filter: {
                title: 'Filters',
                hide: 'Hide filters',
                distance: 'Distance',
                price: 'Price',
                else: 'Else',
                ratings: {
                    description: 'Only garages with ratings',
                    label: 'Need ratings'
                },
                instant_booking: {
                    description: 'Only garages with instant booking',
                    label: 'Instant booking'
                },
                official_garage: {
                    description: 'Only certified {carBrand} partner garages',
                    label: 'Official garage'
                },
                no_filters: 'No filters selected',
                save: 'Apply',
                reset: 'Reset'
            },
            illustration_card: {
                no_garages_nearby: {
                    title: "No garage nearby offering selected services",
                    description: "Change your location to see offers in different area."
                }
            },
            currency: 'CHF',
            tax: 'Tax',
            total: 'Total',
            total_with_vat: 'Total incl. VAT',
            discount: 'Discount',
            service: 'Service',
            to: 'to',
            sponsored: "Sponsored",
            sponsored_post: 'Sponsored post',
            km: 'km',
            car: {
                make: 'Make',
                range: 'Range',
                type: 'Type',
                go_back: 'Edit',
                update_info: 'Update car information'
            },
            official_service: {
                title: 'RECOMMENDATION FOR YOUR CAR',
                description: 'Thanks to the data you have provided us together with the manufacturer\'s specifications for {{carBrand}}, we have set up a personalised service for your car: ',
                includes: 'INCLUDES'
            },
            preselected_service: {
                title: 'Selected services: '
            },
            additional_service: {
                open: 'Additional service list',
                title: 'Maintenance',
                description: 'Pick additional works to refine the results.',
                uncheck_all: 'Uncheck all',
                save: 'Save'
            }
        },
        tab: {
            car: 'CAR DATA',
            services_car: 'My car',
            services_maintenance: 'Car service and maintenance',
            order_overview: 'Order overview',
        },
        form: {
            placeholder: {
                range: 'Select range',
                type: 'Select type',
                gear: 'Select gear',
                first_registration: '2019',
                qual_md_list: 'Select car features',
                qual_lt_list: 'Select vehicle body',
                mileage: 'Select mileage',
                zip: 'Select zip',
                make: 'Select make',
                username: 'Enter username',
                year: '2019',
                km_stand: 'Select mileage',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                password: 'Password',
                city: 'City',
                color: 'Color',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Please select',
                },
                date: 'Pick a date',
                morning: 'Morning',
                afternoon: 'Afternoon',
                voucher: 'Discount coupon',
            },
            label: {
                licence_plate: 'Numberplate',
                make: 'Make',
                range: 'Range',
                type: 'Type',
                gear: 'Gear',
                first_registration: 'Year of registration',
                qual_md_list: 'Car features',
                qual_lt_list: 'Vehicle body',
                mileage: 'Mileage',
                zip: 'ZIP',
                username: 'Username',
                year: 'Year of registration',
                km_stand: 'Mileage',
                first_name: 'First name',
                last_name: 'Last name',
                phone: 'Phone',
                email: 'Email',
                password: 'Password',
                agb_agreement: 'Accept the',
                terms_and_condition: ' terms and conditions',
                date: 'Date',
                availability: 'Availability',
                bookFor: 'Book for',
                book: 'Request',
                city: 'City',
            },
            button: {
                next: 'Next',
                confirm: 'Confirm',
                submit: 'Search for offers',
            },
            select: {
                no_result: 'No result found.'
            },
            saveGarageList: {
                title: 'Save for later',
                description: 'Save this request for later by entering your email address. You’ll receive a link in a few minutes.',
                submit: 'Save garage list',
                save: 'Save for later',
                success: "We successfully saved your list. You'll receive a confirmation by email in minutes.",
            },
            resetPassword: {
                link: 'Forgot password?',
                title: 'Reset password',
                description: 'Forgot your password? Enter your email, and we will send you instructions how to reset it.',
                submit: 'Reset password',
                success: "If email exist, you should receive email with the token for password reset",
            },
        },
        validations: {
            label: {
                plate_number: 'plate number',
                make: 'make',
                range: 'range',
                type: 'type',
                first_registration: 'year of registration',
                gear: 'gear',
                qual_md_list: 'car features',
                qual_lt_list: 'Vehicle body',
                mileage: 'mileage',
                zip: 'ZIP',
                km_stand: 'mileage',

                firstname: 'first name',
                lastname: 'last name',
                phone: 'phone',
                email: 'email',
                password: 'password',
                agb_agreement: 'terms and conditions',

                zip_not_found: 'No cities with ZIP code {{name}}',
                zip_not_valid: 'Please select valid ZIP code.',
                zip_required: 'ZIP is required.',
                zip_must_be_number: 'Must be a number.',

                voucher: 'Voucher',
            },
            default: {
                mixed: {
                    required: '${label} required'
                },
                string: {
                    email: 'Please fill in a valid email',
                    min: 'Value too short',
                    max: 'Value too long',
                    test: 'You have to agree with our ${label}',
                    matches: 'Invalid entry',
                },
                boolean: {
                    required: 'You have to agree with our ${label}'
                },
            },
            termin_picker: {
                drop_off_title: "Drop-off availabilities",
                drop_off_description: "Select your available date and preferred time to drop-off your car.",
                drop_off_description_upto: 'Please select a desired date and the drop-off time for your car.',
                maximum: 'Maximum number of dates to pick is 5.',
                required: 'Please pick a date.',
            },
            licence_plate: {
                canton: {
                    valid: "Valid canton",
                    nonValid: "Invalid canton"
                },
                number: {
                    valid: "Valid number",
                    nonValid: "Invalid number"
                },
                attempts: 'You have {{attempt}} extra tries.',
                attempt: 'You have 1 extra try.'
            },
            voucher_minimum_price: "Discount not applied.\nMinimum order value of {{price}} CHF",
        },
        pages: {
            home: {
                title: 'Enter your car information',
                chat_text: 'Unsure? ',
                chat_button: ' Live Chat',
                licence_plate: {
                    title: 'Enter your car information and get instant offers.',
                    manual: 'Don\'t know your plate number?',
                    sourcing: 'Sourcing your car Information',
                    sourced_success: 'We successfully sourced your car information.',
                },
                form_sidebar_label_1: '2 years warranty on work and parts',
                form_sidebar_label_2: 'Comparison of more than 450 garages',
                form_sidebar_label_3: 'Free cancellation',
                licence_plate_tooltip: 'The vehicle identification is carried out with the help of the following source: Federal Roads Office FEDRO.',
                source_provider: 'Source: Federal Roads Office (FEDRO) - Astra.',
            },
            services: {
                labour_search_item_tooltip: "The following maintenance is included in this service:",
                title: 'Perfect! Now you only need to select a garage',
                submit: 'Show offers',
                title_garages: 'Garages',
                make_recommendation: 'Recommended service for',
                save_up_to: 'Carhelper customer save up to {{percent}}%',
                labour: {
                    title: 'Additional maintenance work',
                    not_found: 'No results found...',
                    not_exist: 'Ooops, it seems that this service is not available for your vehicle. Please check, or contact our support for an individual offer for this.',
                },
                sort_by: {
                    best_match: 'Best match',
                    affordability: 'Affordability',
                    proximity: 'Proximity',
                    save_research: 'Save the research',
                    save_research_mobile: 'Save',
                    title: 'Sort By',
                },
                rating: {
                    rate1: 'Okay',
                    rate2: 'Fair',
                    rate3: 'Good',
                    rate4: 'Very Good',
                    rate5: 'Excellent',
                },
                garage_api_error: 'Oops, something happened on the server... We couldn\'t fetch garages at the moment.',
                garages_no_results: 'No garage found.',
                garages_load_more: 'Load more',
                ad_baloise: {
                    title: 'Car insurance? Calculate the premium for your car now',
                    description: 'The most favourable car insurance in Switzerland with best grades for ' +
                        'Customer satisfaction and individual insurance benefits.',
                    btn: 'To the calculator of baloise.ch',
                },
                garage_item: {
                    highly_booked: 'Highly booked',
                    garage_of_year: 'Garage of the year',
                    cheapest: 'Best deal',
                    certificated: 'Certified {{make}} Partner',
                    book_now: 'View Deal',
                    show_details: 'Show details',
                    axa_partner: 'AXA Premium Partner',
                },
                official_not_found_too_young: 'Whoops, we could not find any recommended service for your car. Please select manually below.',
                official_not_found: 'Whoops, we could not find any recommended service for your car. Please select' +
                    ' manually below.',
                voucher: {
                    upto: {
                        show_more: 'How it works',
                        more_description: `
                        <ul>
                        <li><span>1</span> <span>Select a garage nearby</span></li>
                        <li><span>2</span> <span>Pick a desired date</span></li>
                        <li><span>3</span> <span>Receive confirmation</span></li>
                        </ul> `,
                    }
                }
            },
            garage: {
                checkoutPaymentTitle: "Payment",
                checkoutPaymentDescription: "<p>You will only be billed once the service is successfully completed by the garage and you’re happy with it.<p/><p>You can directly pay at the garage when you pick-up your car.</p>",
                login_via_google: 'Sign up with Google',
                login_via_facebook: 'Sign up with Facebook',
                login_separator_or: 'or',

                // TODO REMOVE
                please_login: 'Please login or',
                link_sign_up: 'sign up',
                register_to_finalize_booking: 'to finalise your booking.',
                login_to_finalize_booking: 'or sign up to finalise your booking.',
                please: 'Please',
                btn_login: 'login',
                // TODO REMOVE

                includes: 'INCLUDED',
                price_details: 'See details',
                btn_continue: 'Continue',
                btn_finish_booking: 'Confirm booking',
                btn_checkout_for: 'Checkout now for {{price}}',
                btn_checkout: 'Request',
                link_logout: 'If you’d like to log-out, please click',
                link_logout_here: 'here',
                logged_as: 'Logged in as',
                add_more_inputs: 'And one last thing',
                add_confirm_inputs: 'Please add or confirm your phone number so that your chosen ' +
                    'garage can get in touch with you.',
                show_summary: 'Show order summary',

                auth: {
                    register: 'Please register to complete your booking, or <1> log in </1>.',
                    login: 'Please log in to complete your booking, or <1> sign up </1>.',
                },

                termin_picker: {
                    i_am_flexible: 'I am flexible'
                },
                why_carhelper: {
                    title: 'Why should you book via Carhelper?',
                    item1: 'Favourable fixed price',
                    item2: 'Easy comparison of over 450+ garages',


                    item3: 'High quality and transparency through customer feedbacks',
                    item4: '2 years guarantee on work and car parts',
                },
                predefined: {
                    selected: 'Selected garage',
                    nearby: 'Nearby garages',
                    inactive: '{{garageName}} is not yet registered on Carhelper. We can not calculate the price for this service. Please select an alternative garage from the same region below:',
                    out_of_make: 'This garage does not offer services for {{make}}. Please select an alternative garage from the same region below:',
                },
                progress_load: {
                    searching_for_offers: 'Searching for offers near {{city}}',
                    sourcing_prices: 'Sourcing prices from Garages',
                    finding_deals: 'Finding the best deals',
                },
                ab_test_discount: {
                    title: 'Exclusive Carhelper Deal – Only today',
                    value: 'Save {{discount}}% on all orders. Best prices warranty.',
                },
                ab_test_discount_checkout: {
                    title: 'Exclusive Carhelper Deal',
                    value: '{{discount}}% on all orders only today.',
                },
                testimonials_title: 'What other customers say',
                termin: 'Appointment',
                customer_data: 'Customer data',
            },
            confirm: {
                title: 'Congratulations! Your request has been sent.',
                description: `The garage will check and confirm your request as soon as possible.
                            You will receive a confirmation via e-mail and SMS. If your desired date
                            shouldn't be available, the garage will call you to set up a new and final date.
                            for your car service. Do you have questions about your request? Feel free to contact us.`,
                order_number: 'Order number:',
                carhelper_team: 'Your Carhelper team',
                order_overview: 'Order overview',
            }
        },
        preparedServices: {
            oil: {
                name: null,
                second_name: null
            },
            zahriemen: {
                name: 'timing belt',
                second_name: 'check'
            },
            break_fluid: {
                name: null,
                second_name: null
            },
            airfilter: {
                name: null,
                second_name: null
            },
            klimafilter: {
                name: null,
                second_name: null
            }
        },
    }
};

