import {LOGIN_FACEBOOK, LOGIN_GOOGLE} from "../../actions/login.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../actions/api.actions";
import {setLocalStorageItem} from "../../../utils/storage";
import {STORAGE_USER_TOKEN} from "../../../app/consts/storage.consts";
import {userSet} from "../../actions/user.actions";
import {addFlashMessage} from "../../actions/flashMessage.actions";
import {FLASH_MESSAGE_ERROR} from "../../consts/flashMessage.constants";
import {getRequestErrorMessage} from "../../../utils/getRequestErrorMessage";

const LOGIN_GOOGLE_URL = 'google-login';
const LOGIN_FACEBOOK_URL = 'facebook-login';

export const loginMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);
        switch (action.type) {
            case LOGIN_GOOGLE:
                dispatch(
                    apiRequest({
                        body: {
                            ...action.payload.data.profileObj,
                            ...{accessToken: action.payload.data.accessToken}
                        },
                        method: 'POST',
                        url: LOGIN_GOOGLE_URL,
                        signature: LOGIN_GOOGLE
                    })
                );
                break;

            case `${LOGIN_GOOGLE} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_USER_TOKEN, action.payload.data.token);
                dispatch(userSet(action.payload.data));

                break;

            case `${LOGIN_GOOGLE} ${API_ERROR}`:
                dispatch(addFlashMessage(
                    getRequestErrorMessage(action.payload.error),
                    FLASH_MESSAGE_ERROR
                ));

                break;

            case LOGIN_FACEBOOK:
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: LOGIN_FACEBOOK_URL,
                        signature: LOGIN_FACEBOOK
                    })
                );
                break;

            case `${LOGIN_FACEBOOK} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_USER_TOKEN, action.payload.data.token);
                dispatch(userSet(action.payload.data));

                break;

            case `${LOGIN_FACEBOOK} ${API_ERROR}`:
                dispatch(addFlashMessage(
                    getRequestErrorMessage(action.payload.error),
                    FLASH_MESSAGE_ERROR
                ));

                break;

            default:
                break;
        }
    };
};

export default {
    loginMiddleware
};
