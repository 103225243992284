export const LOADER_INIT_OFFER = 'initOffer';
export const LOADER_SERVICE_CHECK = 'serviceCheck';
export const LOADER_FORM_VEHICLE = 'formVehicle';
export const LOADER_ZIP_DATA_LIST = 'zipDataList';
export const LOADER_PLACEHOLDER_GARAGE_LIST = 'placeholderGarageList';
export const LOADER_PLACEHOLDER_OFFICIAL_SERVICES = 'placeholderOfficialServices';
export const LOADER_LICENCE_PLATE = 'licencePlate';
export const LOADER_API_GET_CAR = 'apiGetCar';
export const LOADER_API_LIST_SERVICES = 'listServices';

export default {
    LOADER_INIT_OFFER,
    LOADER_SERVICE_CHECK,
    LOADER_FORM_VEHICLE,
    LOADER_PLACEHOLDER_GARAGE_LIST,
    LOADER_PLACEHOLDER_OFFICIAL_SERVICES,
    LOADER_ZIP_DATA_LIST,
    LOADER_LICENCE_PLATE,
    LOADER_API_GET_CAR,
    LOADER_API_LIST_SERVICES,
};
