import {GARAGE_CLICK, GARAGE_SELECT, setGarage} from '../../actions/garage.actions';
import history from '../../../routes/history';
import pages from '../../../app/consts/routes';
import {API_ERROR, API_SUCCESS, apiRequest} from "../../actions/api.actions";

const GARAGE_CLICK_URL = 'repair-shop-click';

export const garageMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case GARAGE_SELECT:
                dispatch(setGarage(action.payload.garage));
                history.push(`${pages.garage}${action.payload.garage.slug}`);
                break;

            case GARAGE_CLICK:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: {repair_shop_token: action.payload.garageId},
                        url: GARAGE_CLICK_URL,
                        signature: GARAGE_CLICK
                    })
                );
                break;

            case `${GARAGE_CLICK} ${API_SUCCESS}`:
                break;

            case `${GARAGE_CLICK} ${API_ERROR}`:
                break;

            default:
                break;
        }
    };
};

export default {
    garageMiddleware
};
