//@flow

import React, { Component } from 'react';
import './index.scss';
import Yup from '../../../app/config/yup/yup';
import { withFormik } from 'formik';
import Input from '../Input/Input';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import {makeInputNumericOnly} from '../../../utils/common';

class LicencePlate extends Component<{}, {}> {
    constructor(props: Props) {
        super(props);
        this.cantonRef = React.createRef();
        this.numberRef = React.createRef();
        this.BACKSPACE_CHAR_CODE = 8;
        this.REGEX_LETTERS_ONLY = /[^a-z]+/gi;
        this.REGEX_NUMBERS_ONLY = /\D/g;

        this.state = {
            numberBlur: false,
            isPasted: false
        };

        document.addEventListener('keyup', this.handleKeyPress);
    }

    componentDidMount() {
        const { value } = this.props;

        if (value) {
            this.formatValue(value);
        }

        makeInputNumericOnly(document.getElementById('number'), value => {
            return /^\d*$/.test(value);
        });
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        const {
            onChange,
            onReset,
            values: { canton, number },
            setFieldTouched
        } = this.props;

        if (canton !== nextProps.values.canton || number !== nextProps.values.number) {
            onReset();
        }

        if (number !== nextProps.values.number) {
            this.setState({
                numberBlur: false
            });
        }

        if (number !== nextProps.values.number && nextProps.values.number.toString().length === 0) {
            this.setState({
                numberBlur: false
            });
        }

        if (number !== nextProps.values.number && nextProps.values.number.toString().length !== 0) {
            setTimeout(() => {
                this.setState({ numberBlur: true }, setFieldTouched('number', true));
            }, 1000);
        }

        if (canton !== nextProps.values.canton && nextProps.values.canton.toString().length === 2) {
            setTimeout(() => {
                if (this.numberRef && this.numberRef.inputRef) {
                    this.numberRef.inputRef.focus();
                }
            }, 0);
        }

        if (
            (canton !== nextProps.values.canton &&
                nextProps.values.canton.toString().length === 2) ||
            (number !== nextProps.values.number && nextProps.values.number.toString().length > 0)
        ) {
            onChange(nextProps.values.canton, nextProps.values.number);
        }
    }

    handleKeyPress = e => {
        this.handleBackToNumberFocus(e);
        this.handleBackspaceFocus(e);
    };

    handleBackToNumberFocus = e => {
        const NUMBER_CHARS = '0123456789';
        const { setFieldValue, values } = this.props;
        const { isPasted } = this.state;

        if (
            e.keyCode !== this.BACKSPACE_CHAR_CODE &&
            values.canton.length === 2 &&
            values.number.length === 0 &&
            !isPasted
        ) {
            setTimeout(() => {
                if (NUMBER_CHARS.includes(e.key) && this.numberRef && this.numberRef.inputRef) {
                    this.numberRef.inputRef.focus();
                    setFieldValue('number', e.key);
                }
            }, 0);
        }
    };

    handleBackspaceFocus = e => {
        const {
            values: { number }
        } = this.props;

        if (e.keyCode === this.BACKSPACE_CHAR_CODE && number.length === 0) {
            setTimeout(() => {
                if (this.cantonRef && this.cantonRef.inputRef) {
                    this.cantonRef.inputRef.focus();
                }
            }, 0);
        }
    };

    formatValue = value => {
        const { setValues, setTouched } = this.props;

        const canton = this.escapeNonValidCharacters(value, this.REGEX_LETTERS_ONLY).slice(0, 2);
        const number = this.escapeNonValidCharacters(value, this.REGEX_NUMBERS_ONLY).slice(0, 6);

        setValues({ canton, number });
        setTouched({ canton: true, number: true });
    };

    renderValidationLabel = (textValid, textNonValid, isValid) => {
        return (
            <p
                className={classNames({
                    valid: isValid,
                    'non-valid': !isValid
                })}
            >
                <span
                    className={classNames({
                        'icon-cross-circle': !isValid,
                        'icon-check-circle': isValid
                    })}
                />
                {isValid ? textValid : textNonValid}
            </p>
        );
    };

    escapeNonValidCharacters = (value, regex) => {
        return value.replace(regex, '').trim();
    };

    handleChange = (e, regex) => {
        const value = this.escapeNonValidCharacters(e.target.value, regex);
        this.props.setFieldValue([e.target.id], value);
        this.props.onErrorReset();
    };

    isCantonValid = () => {
        const { values, errors, list } = this.props;

        return (
            !errors.canton &&
            values.canton.toString().length === 2 &&
            list.find(cantonName => cantonName.toLowerCase() === values.canton.toLowerCase())
        );
    };

    render() {
        const {
            t,
            values,
            handleBlur,
            touched,
            errors,
            onManual,
            attempts,
            error: errorParent
        } = this.props;

        const isNumberValid = !errors.number && values.number.toString().length > 0;

        return (
            <div className="licence-plate clearfix">
                <label>{t('form.label.licence_plate')}</label>
                <div className="licence-plate-input">
                    <Input
                        value={values.canton}
                        ref={ref => (this.cantonRef = ref)}
                        type="text"
                        id="canton"
                        name="canton"
                        placeholder={t('form.placeholder.canton')}
                        error={(touched.canton && !this.isCantonValid()) || errorParent}
                        onBlur={e => {
                            if (values.canton.length > 0) {
                                handleBlur(e);
                            }
                        }}
                        onChange={e => this.handleChange(e, this.REGEX_LETTERS_ONLY)}
                        onPaste={this.formatValue}
                        maxLength={2}
                        hideErrorMessage
                    />

                    <Input
                        value={values.number}
                        ref={ref => (this.numberRef = ref)}
                        type="tel"
                        id="number"
                        name="number"
                        placeholder={t('form.placeholder.plate_number')}
                        error={(touched.number && errors.number) || errorParent}
                        onBlur={e => {
                            if (values.number.length > 0) {
                                handleBlur(e);
                                this.setState({
                                    numberBlur: true
                                });
                            }
                        }}
                        onChange={e => this.handleChange(e, this.REGEX_NUMBERS_ONLY)}
                        onPaste={this.formatValue}
                        maxLength={6}
                        hideErrorMessage
                    />
                </div>

                <div className="licence-plate-validation">
                    <div className="licence-plate-validation-wrapper">
                        {!touched.canton ? (
                            <p
                                className={classNames('default', {
                                    'non-valid': errorParent
                                })}
                            >
                                <span className="icon-check-circle" />
                                {t('validations.licence_plate.canton.valid')}
                            </p>
                        ) : (
                            this.renderValidationLabel(
                                t('validations.licence_plate.canton.valid'),
                                t('validations.licence_plate.canton.nonValid'),
                                this.isCantonValid()
                            )
                        )}

                        {!touched.number ? (
                            <p
                                className={classNames('default', {
                                    'non-valid': errorParent
                                })}
                            >
                                <span className="icon-check-circle" />
                                {t('validations.licence_plate.number.valid')}
                            </p>
                        ) : (
                            this.renderValidationLabel(
                                t('validations.licence_plate.number.valid'),
                                t('validations.licence_plate.number.nonValid'),
                                isNumberValid
                            )
                        )}
                    </div>
                </div>

                <div className="licence-plate-extra">
                    <span className="search-item-tooltip">
                        <span className="icon-question-circle" />
                        <span className="search-item-tooltip--top search-item-tooltip__text">
                            {t('pages.home.licence_plate_tooltip')}
                        </span>
                    </span>
                </div>

                {attempts <= 3 && (
                    <div className="licence-plate-attempts">
                        <span>
                            {attempts > 1
                                ? t('validations.licence_plate.attempts', { attempt: attempts })
                                : t('validations.licence_plate.attempt')}
                        </span>
                    </div>
                )}

                <div className="licence-plate-redirect">
                    <a href="javascript:void(0)" onClick={onManual}>
                        {t('pages.home.licence_plate.manual')}
                    </a>
                </div>
            </div>
        );
    }
}

const licencePlateConfig = {
    mapPropsToValues(props) {
        return {
            canton: props.canton || '',
            number: props.number || ''
        };
    },

    validationSchema: () => {
        return Yup.object().shape({
            canton: Yup.string()
                .nullable()
                .required(),

            number: Yup.string()
                .nullable()
                .required()
        });
    }
};

export default translate('translations')(withFormik(licencePlateConfig)(LicencePlate));
