import React, {Component} from 'react';
import './TerminPicker.scss';
import {translate} from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate, getMinDateWithoutWeekends } from "../../../utils/common";
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import Button from "../Button/Button";
import classNames from 'classnames';
import {AVAILABILITY_AFTERNOON, AVAILABILITY_MORNING, HOLIDAYS} from "../../../store/consts/terminPicker.constants";
import {terminReset, terminSetDate, terminSetTime} from "../../../store/actions/terminPicker.actions";
import Input from "../Input/Input";
import "../../../../src/app/config/datePicker";
import {scroller} from "../../../utils/scroller";
import {
    setGarageFormIsSubmitted,
    setGarageIsFormDatePickerValid
} from '../../../store/actions/formGarage.actions';
import {voucherHideElements} from "../../../utils/voucherHideElements";

class TerminPicker extends Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {
            termin: {
                date: null,
                time: null
            },
            error: false,
            shouldValidate: props.shouldValidate
        };
    }

    componentDidMount = () => {
        const termin = this.props.termin[0];

        if (termin.date !== null) {
            this.setState({ termin });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const shouldValidate = prevProps.shouldValidate !== this.props.shouldValidate;
        const hasDatesChanged = JSON.stringify(prevProps.termin) !== JSON.stringify(this.props.termin);

        let termin = this.props.termin[0];
        this.props.termin.forEach(t => {
            if(t.date !== null && t.time !== null){
                termin = t;
            }
        });

        if (shouldValidate || (hasDatesChanged && termin.date !== null && termin.time !== null)) {
            this.setState({
                error: termin.date === null || termin.time === null
            });
            this.props.setGarageFormIsSubmitted(false);
        }

        if(hasDatesChanged){
            this.props.setGarageIsFormDatePickerValid(termin.date !== null && termin.time !== null);
        }

        if (this.state.error) {
            scroller('sdp', {
                duration: 500,
                smooth: "ease"
            })
        }
    }

    componentWillUnmount(): void {
        // this.props.terminReset();
    }

    handleTimeChange = (time, period) => {
        this.setState({
            termin: {
                ...this.state.termin,
                time

            }
        }, this.props.terminSetTime(time, period));
    };

    handleDateChange = (date) => {
        this.setState({
            termin: {
                ...this.state.termin,
                date
            }
        }, this.props.terminSetDate(formatDate(date)));
    };

    filterDates = (date) => {
        const dayOfWeekNumber = moment(date).format('d');
        return !(dayOfWeekNumber === "0" || dayOfWeekNumber === "6" || HOLIDAYS.indexOf(moment(date).format('D-M')) !== -1)
    };

    renderDatePicker = () => {
        let date = this.props.termin[0].date;
        const { averageWaitingTime, t } = this.props;

        if (typeof date === 'string'){
            date = moment(date, 'DD/MM/YYYY').toDate();
        }

        return <DatePicker
                    locale="datepicker-locale"
                    customInput={<Input
                        type="text"
                        placeholder={t('form.placeholder.date')}
                        name="mileage"
                        iconLeftClassName="icon-date-picker"
                        readonly
                    />}
                    dateFormat="dd.MM.yyyy"
                    placeholderText={t('form.placeholder.date')}
                    selected={date}
                    minDate={getMinDateWithoutWeekends(moment(), averageWaitingTime)}
                    onChange={(date) => this.handleDateChange(date)}
                    filterDate={this.filterDates}
                />
    };

    renderDate = () => {
        const { t, horizontal } = this.props;

        return (
            <div className={classNames("sdp-date", {
                'sdp-date--horizontal': horizontal === true
            })}>
                <label htmlFor="date">
                    <p>{t('form.label.date')}</p>
                </label>
                <div className="field">
                    {this.renderDatePicker()}
                </div>
            </div>
        )
    };

    renderTimeMorning = () => {
        const { t, termin } = this.props;

        return (
            <div
                onClick={() => this.handleTimeChange('08:00 - 12:00', AVAILABILITY_MORNING)}
                className={classNames("sdp-time sdp-time--left", {
                    'active': termin[AVAILABILITY_MORNING].time
                })}
            >
                <span className="sdp-time__title">{t('form.placeholder.morning')}</span>
                <span className="sdp-time__text">08h - 12h</span>
            </div>
        )
    };

    renderTimeAfternoon = () => {
        const { t, termin } = this.props;

        return (
            <div
                onClick={() => this.handleTimeChange('13:00 - 17:00', AVAILABILITY_AFTERNOON)}
                className={classNames("sdp-time sdp-time--right", {
                    'active': termin[AVAILABILITY_AFTERNOON].time
                })}
            >
                <span className="sdp-time__title">{t('form.placeholder.afternoon')}</span>
                <span className="sdp-time__text">13h - 17h</span>
            </div>
        )
    };

    renderTime = () => {
        const { t, horizontal } = this.props;

        return (
            <div className={classNames("sdp-availability", {
                'sdp-availability--horizontal': horizontal === true
            })}>
                <label htmlFor="time">
                    <p>{t('form.label.availability')}</p>
                </label>
                <div className="field">
                    {this.renderTimeMorning()}
                    {this.renderTimeAfternoon()}
                </div>
            </div>
        )
    };

    renderButton = () => {
        const { t, onItemSelect, price, hideButton, termin } = this.props;

        if(hideButton === true){
            return null;
        }

        return <Button
                  size="md"
                  type="primary"
                  extraClass="garage-item-options__cta"
                  onClick={onItemSelect}
                  disabled={termin[0].date === null || (termin[0].time === null && termin[1].time === null)}
              >
                {!voucherHideElements() ? t('form.label.bookFor') : t('form.label.book')} {!voucherHideElements() ? price : null}
              </Button>
    };

    render() {
        const {error} = this.state;
        const {t} = this.props;

        return (
            <div className="sdp container-fluid">
                {this.renderDate()}
                {this.renderTime()}
                {this.renderButton()}
                {error ? (
                    <div className="dp-errors">
                        <p>{t('validations.termin_picker.required')}</p>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        termin: state.termin
    }
};

export default connect(
    mapStateToProps,
    {
        terminSetDate,
        terminSetTime,
        setGarageFormIsSubmitted,
        setGarageIsFormDatePickerValid,
        terminReset,

    }
)(translate('translations')(translate('translations')(TerminPicker)));
