//@flow
import React, { Component } from 'react';
import './DialogServiceErrorView.scss';

class DialogServiceErrorView extends Component<{}, {}> {
    render() {
        const {
            payload: { message }
        } = this.props;

        let messageText = null;
        let messageExtra = null;

        if (message && message.includes('|')) {
            messageText = message.split('|')[0];
            messageExtra = message.split('|')[1];
        }

        return (
            <div className="modal__body modal-error">
                {messageText && <p className="modal-error__message">{messageText}</p>}
                {messageExtra && (
                    <div className="modal-error__not-found">
                        <p>
                            <strong>{messageExtra}</strong>
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

export default DialogServiceErrorView;
