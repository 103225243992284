//@flow
import React, { Component } from 'react';
import './Loader.scss';
import {
    LOADER_API_GET_CAR,
    LOADER_FORM_VEHICLE, LOADER_INIT_OFFER,
    LOADER_SERVICE_CHECK
} from '../../../store/consts/loader.constants';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.isLoading ? props.isLoading : false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.isLoading !== nextProps.isLoading) {
            this.setState({
                isLoading: nextProps.isLoading
            });
        }
    }

    renderLoaderLinear = loaderClassName => {
        return (
            <div className={loaderClassName}>
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            </div>
        );
    };

    renderLoaderCircular = loaderClassName => {
        return (
            <div className={`${loaderClassName} sk-fading-circle`}>
                <div className="sk-circle1 sk-circle" />
                <div className="sk-circle2 sk-circle" />
                <div className="sk-circle3 sk-circle" />
                <div className="sk-circle4 sk-circle" />
                <div className="sk-circle5 sk-circle" />
                <div className="sk-circle6 sk-circle" />
                <div className="sk-circle7 sk-circle" />
                <div className="sk-circle8 sk-circle" />
                <div className="sk-circle9 sk-circle" />
                <div className="sk-circle10 sk-circle" />
                <div className="sk-circle11 sk-circle" />
                <div className="sk-circle12 sk-circle" />
            </div>
        );
    };

    render() {
        if (!this.state.isLoading || !this.props.type) return null;

        if (this.props.type.includes(`${LOADER_SERVICE_CHECK}-`)) {
            return this.renderLoaderCircular('loader-service-check');
        }

        switch (this.props.type) {
            case LOADER_SERVICE_CHECK:
                return this.renderLoaderCircular('loader-service-check');
            case LOADER_FORM_VEHICLE:
                return this.renderLoaderCircular('loader-form-vehicle');
            case LOADER_API_GET_CAR:
                return this.renderLoaderCircular('loader-button-funnel');
            case LOADER_INIT_OFFER:
                return this.renderLoaderLinear('loader-global');
            default:
                return null;
        }
    }
}

export default Loader;
