import shortid from 'shortid';
import store from '../store';
import {
    FLASH_MESSAGE_ADD,
    FLASH_MESSAGE_GET_ALL,
    FLASH_MESSAGE_REMOVE,
    removeFlashMessage
} from '../actions/flashMessage.actions';
import flashMessages from "../models/flashMessages";

export default function(state = flashMessages, action) {
    const messageId = shortid.generate();
    const newState = [...state];

    switch (action.type) {
        case FLASH_MESSAGE_ADD:
            setTimeout(() => {
                store.dispatch(removeFlashMessage(messageId));
            }, 4000);

            return [
                ...state,
                {
                    id: messageId,
                    message: action.payload.message,
                    type: action.payload.type
                }
            ];

        case FLASH_MESSAGE_REMOVE:
            newState.splice(newState.findIndex(item => item.id === action.payload.id), 1);
            return newState;

        case FLASH_MESSAGE_GET_ALL:
            return newState;

        default:
            return state;
    }
}
