//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SocialMediaLogin.scss';
import { login, LOGIN_FACEBOOK, LOGIN_GOOGLE } from "../../../store/actions/login.actions";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import imagesPath from "../../../utils/imagesPath";

class SocialMediaLogin extends Component<State> {

    responseFacebook = (response) => {
        if(response.hasOwnProperty('id')){
            this.props.login(LOGIN_FACEBOOK, response);
        }
    };

    responseGoogle = (response) => {
        if(response.hasOwnProperty('profileObj')) {
            this.props.login(LOGIN_GOOGLE, response);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="social-media-login">
                    <div>
                        <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_ID}
                            fields="name,email,picture"
                            callback={this.responseFacebook}
                            cssClass="btn btn-primary btn-sm btn-social-media"
                            textButton={t('pages.garage.login_via_facebook')}
                            icon={<img src={imagesPath('Facebook.png')} alt="Facebook"/>}
                            disableMobileRedirect={true}
                        />
                    </div>
                    <div>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Login"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            className="btn btn-primary btn-sm btn-social-media"
                            textButton={t('pages.garage.login_via_google')}
                            icon={false}
                            render={renderProps => (
                                <div className="btn btn-primary btn-sm btn-social-media"
                                     onClick={renderProps.onClick}>
                                    <img src={imagesPath('google.png')} alt="Google"/> {t('pages.garage.login_via_google')}
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className="line-word-separator">
                    <span>{t('pages.garage.login_separator_or')}</span>
                </div>
            </div>
        );
    };
}

let mapStateToProps = state => {
    return {
        step: state.step
    };
};

export default connect(
    mapStateToProps,
    {
        login
    }
)(SocialMediaLogin);
