export const DIALOG = '[Dialog]';

export const DIALOG_SET = `${DIALOG} SET`;
export const DIALOG_GET = `${DIALOG} GET`;

export const setDialog = (name, isOpened) => ({
    type: DIALOG_SET,
    payload: {
        name,
        isOpened
    }
});

export const getDialog = () => ({
    type: DIALOG_GET
});
