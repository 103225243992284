export const USER = '[User]';

export const USER_FETCH = `${USER} FETCH`;
export const USER_SET = `${USER} SET`;
export const USER_UPDATE = `${USER} UPDATE`;
export const USER_LOGOUT = `${USER} LOGOUT`;
export const USER_REMOVE = `${USER} REMOVE`;

export const userFetch = () => ({
    type: USER_FETCH
});

export const userSet = data => ({
    type: USER_SET,
    payload: data
});

export const userUpdate = data => ({
    type: USER_UPDATE,
    payload: {
        data
    }
});

export const userLogout = () => ({
    type: USER_LOGOUT
});

export const userRemove = () => ({
    type: USER_REMOVE
});
