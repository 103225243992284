import {PREDEFINED_GARAGE_SET} from "../actions/predefinedGarage.actions";
import predefinedGarage from "../models/predefinedGarage";

export default function(state = predefinedGarage, action) {
    switch (action.type) {

        case PREDEFINED_GARAGE_SET:
            return action.payload.garage;

        default:
            return state;
    }
}
