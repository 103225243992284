export const ZIP_DATA = '[Zip Data]';

export const ZIP_DATA_FETCH = `${ZIP_DATA} FETCH_DATA`;
export const ZIP_DATA_SET = `${ZIP_DATA} SET_DATA`;
export const ZIP_DATA_CLEAR = `${ZIP_DATA} CLEAR_DATA`;

export const fetchZipData = zipId => ({
    type: ZIP_DATA_FETCH,
    payload: {
        zipId
    }
});

export const setZipData = zipData => ({
    type: ZIP_DATA_SET,
    payload: {
        zipData
    }
});

export const clearZipData = () => ({
    type: ZIP_DATA_CLEAR
});
