import Yup from "./yup";

export default {
    mapPropsToValues: ({user, agbAgreement}) => {
        return {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            password: user.password,
            phone: user.phone,
            agb_agreement: agbAgreement
        };
    },
    validationSchema: ({ t }) => {
        return Yup.object().shape({
            first_name: Yup.string()
                .required()
                .label(t('validations.label.firstname')),
            last_name: Yup.string()
                .required()
                .label(t('validations.label.lastname')),
            email: Yup.string()
                .email()
                .required()
                .label(t('validations.label.email')),
            password: Yup.string()
                .min(6)
                .required()
                .label(t('validations.label.password')),
            phone: Yup.string()
                .required()
                .matches(/^(\+)?\d+$/)
                .min(6)
                .label(t('validations.label.phone')),
            agb_agreement: Yup.bool()
                .test('consent', t('validations.default.boolean.required'), value => value === true)
                .label(t('validations.label.agb_agreement'))
        });
    },
    handleSubmit(values, formikBag) {
    const { userSignUp, formSubmittingSignUp } = formikBag.props;

    formSubmittingSignUp(true);
    userSignUp(values);
}
}
