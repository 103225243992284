import axios from '../../../app/config/axios';
import axiosMain from 'axios';

import {API_REQUEST, apiError, apiSuccess} from '../../actions/api.actions';
import {removeError} from '../../actions/error.actions';

const apiMiddleware = ({dispatch}) => next => action => {
    next(action);

    if (action.type.includes(API_REQUEST)) {
        const {method, url, signature} = action.meta;
        const body = action.payload;
        dispatch(removeError());

        switch (method) {
            case 'POST':
                axios
                    .post(url, body)
                    .then(response => dispatch(apiSuccess({response, signature})))
                    .catch(error => {
                        if(!axiosMain.isCancel(error)){
                            const response = error.response;
                            dispatch(
                                apiError({
                                    error: response ? response.data : '',
                                    status: response ? response.status : 0,
                                    signature
                                })
                            )
                        }
                    });
                break;
            case 'GET':
                axios
                    .get(url, body)
                    .then(response => {
                        dispatch(apiSuccess({response, signature}));
                    })
                    .catch(error => {
                        if(!axiosMain.isCancel(error)) {
                            dispatch(
                                apiError({
                                    error: error.response.data,
                                    signature
                                })
                            )
                        }
                    });
                break;
            case 'PUT':
                axios
                    .put(url, body)
                    .then(response => dispatch(apiSuccess({response, signature})))
                    .catch(error => {
                        if(!axiosMain.isCancel(error)) {
                            dispatch(
                                apiError({
                                    error: error.response.data,
                                    signature
                                })
                            )
                        }
                    });
                break;
            default:
                break;
        }
    }
};

export default apiMiddleware;
