export const SERVICES = '[Service]';

export const SERVICES_FETCH_ALL = `${SERVICES} FETCH_ALL`;
export const SERVICES_SET = `${SERVICES} SET`;
export const SERVICES_UPDATE_LABOUR = `${SERVICES} UPDATE_LABOUR`;
export const SERVICES_TOGGLE_OFFICIAL = `${SERVICES} TOGGLE_OFFICIAL`;
export const SERVICES_TOGGLE_OFFICIAL_SELECTION = `${SERVICES} SERVICES_TOGGLE_OFFICIAL_SELECTION`;
export const SERVICES_CHECK = `${SERVICES} CHECK`;
export const SERVICES_UNCHECK = `${SERVICES} UNCHECK`;
export const SERVICES_RESET = `${SERVICES} RESET`;
export const SERVICES_UNCHECK_ALL = `${SERVICES} UNCHECK ALL`;

export const uncheckAllServices = () => ({
    type: SERVICES_UNCHECK_ALL
});

export const fetchServices = () => ({
    type: SERVICES_FETCH_ALL
});

export const setServices = data => ({
    type: SERVICES_SET,
    payload: {
        data
    }
});

export const officialServiceToggle = selected => ({
    type: SERVICES_TOGGLE_OFFICIAL,
    payload: {
        selected
    }
});

export const officialServiceToggleSelection = selected => ({
    type: SERVICES_TOGGLE_OFFICIAL_SELECTION,
    payload: {
        selected
    }
});

export const labourServiceUpdate = (serviceType, service) => ({
    type: SERVICES_UPDATE_LABOUR,
    payload: {
        serviceType,
        service
    }
});

export const serviceCheck = (itemId, isPreselectedByOfficial = false) => ({
    type: SERVICES_CHECK,
    payload: {
        itemId,
        isPreselectedByOfficial
    }
});

export const serviceUncheck = (serviceType, serviceId) => ({
    type: SERVICES_UNCHECK,
    payload: {
        serviceType,
        serviceId
    }
});

export const resetService = () => ({
    type: SERVICES_RESET,
});
