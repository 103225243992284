import { GARAGE_SET } from '../actions/garage.actions';
import garage from '../models/garage';

export default function(state = garage, action) {
    switch (action.type) {
        case GARAGE_SET:
            return action.payload.garage;

        default:
            return state;
    }
}
