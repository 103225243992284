import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import CarDetail from '../../components/shared/CarDetail/CarDetail';
import OfficialService from '../../components/shared/OfficialService/';
import {getOffer} from '../../store/actions/offer.actions';
import {getValueFromArray} from '../../utils/car';
import './index.scss';
import '../../components/shared/LabourSearchItem/LabourSearchItem.scss';
import 'react-placeholder/lib/reactPlaceholder.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/minimal-example.css';
import GarageListContainer from '../../containers/GarageListContainer/GarageListContainer';
import {getGarageList, setGarageList} from '../../store/actions/garageList.actions';
import Dialog from '../../components/dialogs/Dialog/Dialog';
import {DIALOG_SAVE_GARAGE_LIST, DIALOG_SERVICE_ERROR} from '../../store/consts/dialog.constants';
import {getDialog, setDialog} from '../../store/actions/dialog.actions';
import {fetchServices, officialServiceToggle} from '../../store/actions/services.actions';
import ReactPlaceholder from "react-placeholder";
import garageListPlaceholder from "../../components/placeholders/garageList/garageList.placeholder";
import officialServicePlaceholder from "../../components/placeholders/officialService/officialService.placeholder";
import {
    filterPredefinedServices,
    getSelectedLabourServices,
    getSelectedOfficialService,
    getSelectedServices, isOfficialWithNoLabour
} from "../../store/selectors/services";
import {debounce} from "lodash";
import {changeStepData} from "../../store/actions/step.actions";
import {ServiceCheckStep} from "../../routes/middleware/service/ServiceCheckStep";
import {HasOfferToken} from "../../routes/middleware/HasOfferToken";
import {ERROR_API_GARAGE_LIST} from "../../store/consts/error.constants";
import {scroller} from "../../utils/scroller";
import AdditionalService from "../../components/shared/AdditionalService";
import PreselectedServiceList from "../../components/shared/PreselectedServiceList";
import {getAdditionalService} from "../../utils/common";
import preselectedServiceListPlaceholder from "../../components/placeholders/preselectedServiceListPlaceholder";
import {HideFooter} from "../../routes/middleware/HideFooter";
import {uncheckFilters} from "../../store/actions/filter.actions";
import Filter from "../../components/shared/Filter/Filter";
import GarageSortBy from "../../components/shared/GarageSortBy/GarageSortBy";
import garageListOptionsPlaceholder from "../../components/placeholders/garageList/garageListOptions.placeholder";
import Voucher from "../../components/shared/Voucher/Voucher";
import {applyGarageListDiscount} from "../../store/selectors/garageList";
import {voucherHideElements} from "../../utils/voucherHideElements";
import {LOADER_API_LIST_SERVICES} from "../../store/consts/loader.constants";

class ServiceContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            optionsDidMount: false,
            isGarageListButtonFixed: true,
            autoSelectOfficialService: !(!!this.props.selectedServices.length) && this.props.predefinedServices.official
        };

        this.handleGarageListDebounce = debounce(this.handleGarageListFetch, 1000);
    }

    componentDidMount() {
        document.title = this.props.t('titles.services', {city: this.props.zipData.length > 0 && this.props.zipData[0].name});
        this.props.uncheckFilters();
        this.props.changeStepData(2);

        if (!this.props.selectedServices.length) {
            this.props.fetchServices();
        }

        this.props.getDialog();

        window.addEventListener('scroll', this.fixGarageListButton);

        ServiceContainer.scrollToServicesContainer();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.fixGarageListButton);
        this.handleGarageListDebounce.cancel();
        this.props.uncheckFilters();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selectedOfficialService.length && this.state.autoSelectOfficialService === true) {
            this.setState({
                autoSelectOfficialService: false
            });
        }

        if (this.props.selectedLabourServices.length !== nextProps.selectedLabourServices.length) {
            this.handleGarageListDebounce();
        } else if (nextProps.isOfficialWithNoLabour !== this.props.isOfficialWithNoLabour) {
            this.handleGarageListDebounce();
        } else if (this.props.loader[LOADER_API_LIST_SERVICES] === false && nextProps.loader[LOADER_API_LIST_SERVICES] === true
            && this.props.predefinedServices.official === false && this.props.predefinedServices.labours.length === 0) {
            this.handleGarageListDebounce();
        }

        if (
            (JSON.stringify(this.props.garageList) !== JSON.stringify(nextProps.garageList)) &&
            (nextProps.garageList.length > 0) &&
            (this.state.optionsDidMount === false)
        ) {
            this.setState({
                optionsDidMount: true
            });
        }
    }

    handleGarageListFetch = () => {
        const {getGarageList, services: {labour, official}} = this.props;
        getGarageList(official, labour, null, null);
    };

    static scrollToServicesContainer = () => {
        scroller('aside-wrapper', {
            duration: 500,
            smooth: "ease"
        })
    };

    static scrollToGarageList = () => {
        scroller('main', {
            duration: 500,
            smooth: "ease"
        })
    };

    getCheapestPercent = (garageList) => {
        for (let item in garageList) {
            if (parseInt(garageList[item].cheapest_percent) > 0 && garageList[item].cheapest) {
                return parseInt(garageList[item].cheapest_percent);
            }
        }
    };

    showCheapestPercent = (garageList) => {
        const {loader} = this.props;

        if (loader.placeholderGarageList === false && garageList.length === 0) {
            return false;
        }

        return !(garageList.length === 1 && (parseInt(garageList[0].cheapest_percent) === 100 || parseInt(garageList[0].cheapest_percent) === 0));
    };

    showLabourList = () => {
        const {
            services: {official},
            predefinedServices
        } = this.props;

        return official.selected === null || predefinedServices.labours.length > 0 || voucherHideElements();
    };

    render() {
        const {autoSelectOfficialService} = this.state;
        const {
            t,
            car,
            error,
            garageList,
            services: {official, labour},
            officialServiceToggle,
            loader,
            selectedOfficialService,
            predefinedServices,
            setDialog,
            voucher,
            garageDiscount
        } = this.props;

        const garageListError = error.find(error => error.id === ERROR_API_GARAGE_LIST);

        let searchList = [];

        if (labour) {
            searchList = [...labour.search, ...labour.custom];
        }

        const options = (
            <div className="garage-list garage-list--margin-bottom-10">
                <GarageSortBy
                    onSaveList={() => setDialog(DIALOG_SAVE_GARAGE_LIST, true)}
                />
                <Filter/>
            </div>
        );

        const sidebar = (
            <Accordion accordion={false}>
                <AccordionItem className="accordion__item accordion__item--no-border">
                    <AccordionItemTitle>
                        <h5 className="u-position-relative">
                            {t('tab.services_car')}
                            <span className="accordion__arrow icon-chevron-up" role="presentation"/>
                        </h5>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <CarDetail car={car}/>
                    </AccordionItemBody>
                </AccordionItem>
                <AccordionItem expanded disabled className="accordion__item accordion__item--white-on-mobile">
                    <AccordionItemTitle>
                        <h5 className="u-position-relative">
                            {voucherHideElements() ? t('global.preselected_service.title') : t('tab.services_maintenance')}
                        </h5>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            ready={!loader.placeholderOfficialServices}
                            customPlaceholder={preselectedServiceListPlaceholder}
                        >
                            <PreselectedServiceList list={searchList}/>
                        </ReactPlaceholder>

                        {!voucherHideElements() &&
                        <ReactPlaceholder
                            showLoadingAnimation={true}
                            ready={!loader.placeholderOfficialServices}
                            customPlaceholder={officialServicePlaceholder}
                        >
                            <OfficialService
                                make={getValueFromArray(car.makes, 'id', car.make_id)}
                                range={getValueFromArray(car.ranges, 'id', car.range_id)}
                                firstRegistration={car.first_registration}
                                list={official && official[official.selected]}
                                onOfficialServiceToggle={checked => officialServiceToggle(checked)}
                                checked={!!selectedOfficialService.length}
                                autoSelect={autoSelectOfficialService}
                            />
                            <AdditionalService
                                list={getAdditionalService(searchList, predefinedServices.labours)}
                                show={this.showLabourList()}
                            />
                        </ReactPlaceholder>
                        }
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        );

        const main = (
            <React.Fragment>

                <div className="garage-list-options">
                    {(voucher && voucher.brand && voucher.hide_voucher_partner === false) &&
                    <Voucher voucher={voucher}/>
                    }
                    {garageDiscount &&
                    <div className="w-box">
                        <div className="w-box__body">
                            <div className="garage-list-discount">
                                <div className="row">
                                    <div className="col-md-7 col-sm-7 col-xs-6">
                                        <div className="garage-list-discount__headline"><i
                                            className="icon-check-circle-full"/> {t('pages.garage.ab_test_discount.title')}
                                        </div>
                                        <div
                                            className="garage-list-discount__label">{t('pages.garage.ab_test_discount.value', {discount: garageDiscount})}</div>
                                    </div>
                                    <div className="col-md-1 col-sm-1 col-xs-2">
                                        <div className="garage-list-discount__value">-{garageDiscount}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <ReactPlaceholder
                    showLoadingAnimation={true}
                    ready={!loader.placeholderGarageList || !!garageListError || this.state.optionsDidMount}
                    customPlaceholder={garageListOptionsPlaceholder}
                >

                    {options}
                </ReactPlaceholder>

                <ReactPlaceholder
                    showLoadingAnimation={true}
                    ready={!loader.placeholderGarageList || !!garageListError}
                    customPlaceholder={garageListPlaceholder}
                >
                    <GarageListContainer
                        list={garageList}
                        error={garageListError}
                    />
                </ReactPlaceholder>
            </React.Fragment>
        );

        return (
            <div className="service-page-container container">
                <div className="ior-token">{car.token}</div>
                <Dialog
                    type={DIALOG_SERVICE_ERROR}
                    payload={{
                        message: error.message
                    }}
                />
                <div className="service-page-sidebar col-md-3">
                    {sidebar}
                </div>
                <div className="service-page-main col-md-5">
                    {main}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        zipData: state.zipData,
        error: state.error,
        garageList: applyGarageListDiscount(state),
        dialog: state.dialog,
        services: state.services,
        selectedOfficialService: getSelectedOfficialService(state),
        selectedServices: getSelectedServices(state),
        selectedLabourServices: getSelectedLabourServices(state),
        isOfficialWithNoLabour: isOfficialWithNoLabour(state),
        loader: state.loader,
        formSubmitting: state.formSubmitting,
        predefinedServices: state.predefinedServices,
        filteredPredefinedServices: filterPredefinedServices(state),
        voucher: state.voucher,
        garageDiscount: state.garageDiscount,
    };
};

export default connect(
    mapStateToProps,
    {
        officialServiceToggle,
        fetchServices,
        getOffer,
        getGarageList,
        getDialog,
        changeStepData,
        uncheckFilters,
        setDialog,
        setGarageList
    }
)(ServiceCheckStep(HasOfferToken(HideFooter(translate('translations')(ServiceContainer)))));
