import React, { Component } from 'react';
import Select from '../../shared/Select/Select';
import Input from '../../shared/Input/Input';
import { translate } from 'react-i18next';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
    carDataChange,
    carResetFieldsBellow,
    carUpdateField,
    carFetchServices,
    fetchCarData,
    onCarIframeSubmit,
    carLicencePlate,
    carMultipleVehicleChoose
} from '../../../store/actions/car.actions';
import { clearZipData, fetchZipData } from '../../../store/actions/zipData.actions';
import ZipPicker from '../../shared/ZipPicker/ZipPicker';
import Button from '../../shared/Button/Button';
import { setLoader } from '../../../store/actions/loader.actions';
import {
    LOADER_API_GET_CAR,
    LOADER_FORM_VEHICLE,
    LOADER_ZIP_DATA_LIST
} from '../../../store/consts/loader.constants';
import scroller from '../../../utils/scroller';
import { formVehicleConfig } from '../../../app/config/yup';
import { fetchServices } from '../../../store/actions/services.actions';
import {
    FORM_VEHICLE_TYPE_DATA_COMPLETE,
    FORM_VEHICLE_TYPE_FORM_STEP_2,
    FORM_VEHICLE_TYPE_LICENCE_PLATE,
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE,
    FORM_VEHICLE_TYPE_SOURCED_COMPLETE
} from '../../../app/consts/vehicle';
import { isFormOnly } from '../../../utils/form';
import LicencePlate from '../../shared/LicencePlate';
import { resetPredefinedGarageToken } from '../../../store/actions/predefinedGarageToken.actions';
import './FormVehicle.scss';
import { resetToken } from '../../../utils/resetToken';
import { getServiceImgPath } from '../../../utils/service';
import { getValueFromArray } from '../../../utils/car';
import { carStepPop, carStepSet } from '../../../store/actions/carStep.actions';
import { storeReset } from '../../../store/actions/store.actions';
import { fieldExists } from '../../../utils/fieldExists';
import imagesPath from '../../../utils/imagesPath';
import { isCarFieldValid } from '../../../utils/isCarFieldValid';
import Loader from '../../shared/Loader/Loader';
import ContactViaChat from '../../shared/ContactViaChat/ContactViaChat';
import classNames from 'classnames';
import { zipSet } from '../../../store/actions/zip.actions';
import { isMobileView } from '../../../utils/common';

class FormVehicle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            licence_plate: null
        };
    }

    componentDidMount() {
        if (this.props.car.used_licence_plate === false) {
            this.props.carStepSet(FORM_VEHICLE_TYPE_MANUAL_1);
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {
            carStep: { step },
            loader
        } = this.props;

        this.onErrorScrollTo(prevProps.errors, prevProps.isSubmitting);

        if (
            !!this.props.car.zip_id === true &&
            prevProps.car.zip_id !== this.props.car.zip_id &&
            !isFormOnly()
        ) {
            this.props.resetPredefinedGarageToken();
        }

        if (
            this.props.licencePlateAttempts === 0 &&
            this.props.carStep.step === FORM_VEHICLE_TYPE_LICENCE_PLATE
        ) {
            this.resetToManual();
        }

        if (
            isMobileView() &&
            (step !== prevProps.carStep.step ||
                loader.licencePlate !== prevProps.loader.licencePlate)
        ) {
            scroller('form-container', {
                duration: 500,
                smooth: 'ease'
            });
        }
    }

    onErrorScrollTo = (errors, isSubmitting) => {
        const errorFields = Object.keys(errors);
        if (errorFields.length && isSubmitting) {
            scroller(errorFields[0], {
                duration: 500,
                smooth: 'ease'
            });
        }
    };

    onFormFieldChange = (name, value, shouldCallAPI = false) => {
        this.updateFormField(name, value);
        this.props.carDataChange(
            name,
            value,
            shouldCallAPI
        ); /* update redux store and eventually call api */
    };

    updateFormField = (name, value) => {
        const { values, setValues } = this.props;

        values[name] = value;
        setValues(values);
    };

    handleHasOneOption = (fieldId, { id }) => {
        /* if there is only one item in the list and the value is not yet set for it */
        if (!this.props.values[fieldId]) {
            this.onFormFieldChange(fieldId, id);
        }
    };

    handleChangeSelectForMakes = (name, { id }) => {
        if (this.props.car.first_registration == null) {
            this.handleChangeSelect(name, { id });
        }
    };

    handleChangeSelect = (name, { id }) => {
        if (name === 'make_id' && !window.settings.persistMake) {
            resetToken();
        }

        this.onFormFieldChange(name, id, true);
        this.props.setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        });
    };

    handleChangeSelectColorSelect = (name, { id }) => {
        this.onFormFieldChange(name, id, false);
        this.props.setLoader(LOADER_FORM_VEHICLE, {
            [name]: true
        });
    };

    handleTextChange = (e: Event) => {
        const { handleChange } = this.props;
        const { name, value } = e.target;

        if (e.target instanceof HTMLInputElement) {
            handleChange(e);
            this.onFormFieldChange(name, value);
        }
    };

    handleZipChange = () => {
        this.onFormFieldChange('zip_id', '');
    };

    handleZipClear = () => {
        this.onFormFieldChange('zip_id', '');
        this.props.clearZipData();
        this.props.setLoader(LOADER_API_GET_CAR, false);
    };

    handleZipSelect = zip => {
        this.onFormFieldChange(
            'zip_id',
            zip.id,
            typeof window.predefined === 'object' && window.predefined.zipData.length > 0
        );
        this.props.zipSet(zip);
    };

    handleZipFetchData = zipCode => {
        this.props.clearZipData();

        if (zipCode) {
            this.props.fetchZipData(zipCode);
        }
    };

    goToFunnelBtn() {
        if (isFormOnly() && this.props.type && this.props.type === FORM_VEHICLE_TYPE_FORM_STEP_2) {
            return 'funnel-initiated';
        }
        return '';
    }

    getSelectedZipCode = () => {
        if (window.predefined) {
            return this.hasPredefinedZipData();
        }

        return this.props.zipData.find(zip => zip.id === this.props.values.zip_id);
    };

    hasPredefinedZipData = () => {
        if (window.predefined) {
            return window.predefined.zipData.find(zip => zip.id === this.props.values.zip_id);
        }
    };

    shouldForceChangeMake = () => {
        const makesLength =
            typeof window.predefined === 'object' &&
            window.predefined.makes &&
            window.predefined.makes.length;
        return (
            typeof window.predefined === 'object' &&
            makesLength === 1 &&
            this.props.car.first_registration_list.length === 0
        );
    };

    getFormSubmitLabel = () => {
        const { carStep, t } = this.props;

        if (carStep.step === FORM_VEHICLE_TYPE_DATA_COMPLETE) {
            return t('form.button.confirm');
        } else if (carStep.step === FORM_VEHICLE_TYPE_SOURCED_COMPLETE) {
            return t('form.button.confirm');
        } else if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_1) {
            return t('form.button.confirm');
        } else if (carStep.step === FORM_VEHICLE_TYPE_MANUAL_2) {
            return t('form.button.confirm');
        } else if (carStep.step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
            return t('form.button.confirm');
        }

        return t('form.button.submit');
    };

    renderSubmit = (extraClass, isDisabled) => {
        const isButtonLoading = this.props.loader[LOADER_API_GET_CAR];
        return (
            <Button
                size="lg"
                type="primary"
                extraClass={`button-funnel ${extraClass}`}
                disabled={isDisabled || isButtonLoading}
            >
                {this.getFormSubmitLabel(isButtonLoading)}
                <Loader isLoading={isButtonLoading} type={LOADER_API_GET_CAR} />
            </Button>
        );
    };

    isVisibleStep = formType => {
        return isFormOnly() && this.props.type && this.props.type !== formType;
    };

    handleLicencePlateChange = (canton, number) => {
        const isCantonFound = !this.props.cantonList.find(
            cantonName => cantonName.toLowerCase() === canton.toLowerCase()
        );
        const isNumberEmpty = number.toString().length === 0;

        if (isCantonFound || isNumberEmpty) return this.onFormFieldChange('licence_plate', null);

        this.setState({
            licence_plate: canton.toUpperCase() + number
        });

        this.onFormFieldChange('licence_plate', `${canton.toUpperCase()}${number}`);
    };

    handleLicencePlateReset = () => {
        this.props.errors['licence_plate'] = null;
        this.onFormFieldChange('licence_plate', null);
    };

    resetToManual = () => {
        const { carStepSet, storeReset } = this.props;

        this.setState({ licence_plate: null });
        storeReset();
        carStepSet(FORM_VEHICLE_TYPE_MANUAL_1);
    };

    renderFormStep = () => {
        const {
            carStep: { step },
            loader
        } = this.props;

        if (loader.licencePlate === true) {
            return this.renderLicencePlateLoader();
        }

        if (step === FORM_VEHICLE_TYPE_DATA_COMPLETE) {
            return this.renderFormStepDataComplete();
        } else if (step === FORM_VEHICLE_TYPE_SOURCED_COMPLETE) {
            return this.renderFormStepDataComplete();
        } else if (step === FORM_VEHICLE_TYPE_MANUAL_1) {
            return this.renderFormStepManualFirst();
        } else if (step === FORM_VEHICLE_TYPE_MANUAL_2) {
            return this.renderFormStepManualSecond();
        } else if (step === FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) {
            return this.renderFormStepMultipleVehicle();
        }

        return this.renderFormStepLicencePlate();
    };

    renderFormStepLicencePlate = () => {
        const {
            t,
            car,
            values,
            touched,
            errors,
            loader,
            cantonList,
            isSubmitting,
            handleBlur,
            zipData,
            formSubmitting,
            licencePlateAttempts,
            setErrors
        } = this.props;

        return (
            <div key="licence-plate">
                <div className="form-container__header form-container__header--border-bottom">
                    <h5>{t('pages.home.licence_plate.title')}</h5>
                </div>

                <div>
                    <LicencePlate
                        onChange={this.handleLicencePlateChange}
                        list={cantonList}
                        error={touched.licence_plate && errors.licence_plate}
                        error={touched.licence_plate && errors.licence_plate}
                        onErrorReset={() => {
                            let err = errors;
                            delete err['licence_plate'];
                            setErrors(err);
                        }}
                        onReset={this.handleLicencePlateReset}
                        value={car.licence_plate || this.state.licence_plate}
                        onManual={this.resetToManual}
                        attempts={licencePlateAttempts}
                        t={t}
                    />

                    <Input
                        type="number"
                        placeholder={t('form.placeholder.mileage')}
                        name="mileage"
                        label={t('form.label.mileage')}
                        value={values.mileage}
                        onBlur={e => {
                            if (values.mileage.length > 0) {
                                handleBlur(e);
                            }
                        }}
                        onChange={this.handleTextChange}
                        error={touched.mileage && errors.mileage}
                        selected={car.mileage}
                        maxLength={6}
                    />

                    <ZipPicker
                        id="zip_id"
                        name="zip_id"
                        label={t('form.label.zip')}
                        list={zipData}
                        onChange={value => this.handleZipChange(value)}
                        onZipSelect={zip => this.handleZipSelect(zip)}
                        onReadyForFetchData={zipCode => this.handleZipFetchData(zipCode)}
                        onZipClear={this.handleZipClear}
                        onZipDataClear={() => clearZipData()}
                        onBlur={handleBlur}
                        error={touched.zip_id && errors.zip_id}
                        selected={this.getSelectedZipCode()}
                        forceValidate={isSubmitting}
                        hide={!!this.hasPredefinedZipData()}
                        isZipDataListLoading={loader[LOADER_ZIP_DATA_LIST]}
                    />
                </div>

                {this.renderSubmit(
                    'mt-15 mb-15',
                    (formSubmitting && formSubmitting.vehicle) ||
                    (loader[LOADER_API_GET_CAR] && !loader[LOADER_API_GET_CAR])
                )}
            </div>
        );
    };

    renderLicencePlateLoader = () => {
        const { t } = this.props;

        return (
            <div className="licence-plate-loader">
                <div className="licence-plate-loader__animation">
                    <img src={imagesPath('circle-rolling-bold.svg')} alt="Loading..." />
                    <span className="licence-plate-loader__animation__label">
                        {t('pages.home.licence_plate.sourcing')}
                    </span>
                </div>

                {this.renderSubmit('mt-0', true)}
            </div>
        );
    };

    renderFormStepManualFirst = () => {
        const {
            t,
            car,
            values,
            touched,
            errors,
            loader,
            isSubmitting,
            handleBlur,
            zipData,
            formSubmitting,
            carStep,
            carStepPop,
            licencePlateAttempts
        } = this.props;

        return (
            <div key="manual-first">
                {carStep.message !== '' ? (
                    <div className="form-container__message form-container__message--warning">
                        <i className="icon-question-circle" />
                        {carStep.message}
                    </div>
                ) : (
                    <div className="form-container__header">
                        <h5>{t('pages.home.licence_plate.title')}</h5>
                    </div>
                )}

                {this.funnelProgressBar()}

                <div className="row">
                    <div
                        className={
                            typeof car.first_registration === 'boolean' &&
                            car.first_registration === true
                                ? 'col-md-8'
                                : 'col-md-4'
                        }
                    >
                        <Select
                            id="make_id"
                            list={car.makes}
                            value={values.make_id}
                            label={t('form.label.make')}
                            onHasOneOption={(fieldId, option) =>
                                this.handleChangeSelectForMakes(fieldId, option)
                            }
                            error={touched.make_id && errors.make_id}
                            selected={car.make_id}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            forceOnChange={this.shouldForceChangeMake()}
                            directSearch
                        >
                            {t('form.placeholder.make')}
                        </Select>
                    </div>
                    <div className="col-md-4">
                        <Select
                            id="first_registration"
                            list={car.first_registration_list}
                            value={values.first_registration}
                            label={t('form.label.year')}
                            onChange={(id, option) => this.handleChangeSelect(id, option)}
                            onHasOneOption={(fieldId, option) =>
                                this.handleHasOneOption(fieldId, option)
                            }
                            error={touched.first_registration && errors.first_registration}
                            disabled={!car.make_id}
                            selected={car.first_registration}
                            hidden={
                                typeof car.first_registration === 'boolean' &&
                                car.first_registration === true
                            }
                            isLoading={loader[LOADER_FORM_VEHICLE]['make_id']}
                        >
                            {t('form.placeholder.year')}
                        </Select>
                    </div>
                </div>
                <Select
                    id="range_id"
                    list={(window.predefined && window.predefined.ranges) || car.ranges}
                    value={values.range_id}
                    label={t('form.label.range')}
                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.range_id && errors.range_id}
                    disabled={!car.ranges.length}
                    selected={car.range_id}
                    isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                    attributes={['productionFrom', 'productionTo']}
                    attributesSeparator={' - '}
                >
                    {t('form.placeholder.range')}
                </Select>

                <Select
                    id="type_id"
                    list={car.types}
                    value={values.type_id}
                    label={t('form.label.type')}
                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.type_id && errors.type_id}
                    disabled={!car.types.length}
                    selected={car.type_id}
                    hidden={typeof car.type_id === 'boolean' && car.type_id === true}
                    isLoading={loader[LOADER_FORM_VEHICLE]['range_id']}
                    attributes={['productionFrom', 'productionTo']}
                    attributesSeparator={' - '}
                >
                    {t('form.placeholder.type')}
                </Select>

                <Input
                    type="number"
                    placeholder={t('form.placeholder.mileage')}
                    name="mileage"
                    label={t('form.label.mileage')}
                    value={values.mileage}
                    onBlur={handleBlur}
                    onChange={this.handleTextChange}
                    error={touched.mileage && errors.mileage}
                    selected={car.mileage}
                    hidden={carStep.incomplete === true}
                    maxLength={6}
                />

                <ZipPicker
                    id="zip_id"
                    name="zip_id"
                    label={t('form.label.zip')}
                    list={zipData}
                    onChange={value => this.handleZipChange(value)}
                    onZipSelect={zip => this.handleZipSelect(zip)}
                    onReadyForFetchData={zipCode => this.handleZipFetchData(zipCode)}
                    onZipClear={this.handleZipClear}
                    onZipDataClear={() => clearZipData()}
                    onBlur={handleBlur}
                    error={touched.zip_id && errors.zip_id}
                    selected={this.getSelectedZipCode()}
                    forceValidate={isSubmitting}
                    hide={!!this.hasPredefinedZipData() || carStep.incomplete === true}
                    isZipDataListLoading={loader[LOADER_ZIP_DATA_LIST]}
                />

                <div className="row">
                    {(licencePlateAttempts > 0 ||
                        carStep.prevSteps.indexOf(FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) !== -1 ||
                        (licencePlateAttempts === 0 && car.first_registration === true)) && (
                        <div className="col-md-2 col-sm-2 col-xs-2 text-center mt-5">
                            <div className="btn btn--md btn--dark btn--round back-btn" onClick={() => carStepPop()}>
                                <i className="icon-chevron-left" />
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames('', {
                            'col-md-6 col-sm-6 col-xs-6':
                                licencePlateAttempts > 0 ||
                                carStep.prevSteps.indexOf(FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) !==
                                -1 ||
                                (licencePlateAttempts === 0 && car.first_registration === true),
                            'col-md-8 col-sm-8 col-xs-8':
                                licencePlateAttempts === 0 &&
                                carStep.prevSteps.indexOf(FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE) ===
                                -1 &&
                                !(licencePlateAttempts === 0 && car.first_registration === true)
                        })}
                    >
                        {this.renderSubmit(
                            'mt-10 mb-15',
                            (formSubmitting && formSubmitting.vehicle) ||
                            (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderFormStepManualSecond = () => {
        const {
            t,
            car,
            values,
            touched,
            errors,
            loader,
            formSubmitting,
            carStep,
            carStepPop
        } = this.props;

        return (
            <div key="manual-second">
                {carStep.message !== '' && (
                    <div className="form-container__message form-container__message--warning">
                        <i className="icon-question-circle" />
                        {carStep.message}
                    </div>
                )}

                {this.funnelProgressBar()}

                <Select
                    id="gear_id"
                    list={car.gears}
                    value={values.gear_id}
                    label={t('form.label.gear')}
                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.gear_id && errors.gear_id}
                    disabled={!car.gears.length}
                    selected={car.gear_id}
                    hidden={car.gears.length <= 1}
                    // isLoading={loader[LOADER_FORM_VEHICLE]['first_registration']}
                >
                    {t('form.placeholder.gear')}
                </Select>

                <Select
                    id="qual_md"
                    list={car.qual_md_list}
                    value={values.qual_md}
                    label={t('form.label.qual_md_list')}
                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.qual_md && errors.qual_md}
                    disabled={!car.qual_md_list.length}
                    selected={car.qual_md}
                    hidden={car.qual_md_list.length <= 1}
                    isLoading={loader[LOADER_FORM_VEHICLE]['gear_id']}
                >
                    {t('form.placeholder.qual_md_list')}
                </Select>

                <Select
                    id="qual_lt"
                    list={car.qual_lt_list}
                    value={values.qual_lt}
                    label={t('form.label.qual_lt_list')}
                    onChange={(id, option) => this.handleChangeSelect(id, option)}
                    onHasOneOption={(fieldId, option) => this.handleHasOneOption(fieldId, option)}
                    error={touched.qual_lt && errors.qual_lt}
                    disabled={!car.qual_lt_list.length}
                    selected={car.qual_lt}
                    hidden={car.qual_lt_list.length <= 1}
                    isLoading={loader[LOADER_FORM_VEHICLE]['qual_md']}
                >
                    {t('form.placeholder.qual_lt_list')}
                </Select>

                <div className="row">
                    <div className="col-md-2 col-sm-2 col-xs-2 text-center mt-5">
                        <div className="btn btn--md btn--dark btn--round back-btn" onClick={() => carStepPop()}>
                            <i className="icon-chevron-left" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        {this.renderSubmit(
                            'mt-10 mb-15',
                            (formSubmitting && formSubmitting.vehicle) ||
                            (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderFormStepDataComplete = () => {
        const { car, formSubmitting, loader, t, carStepPop } = this.props;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        return (
            <div key="data-complete">
                <div className="form-container__message form-container__message--success">
                    <i className="icon-check-circle-thicker" />
                    {t('pages.home.licence_plate.sourced_success')}
                </div>

                <div>
                    <div className="car-data-complete">
                        <div className="row">
                            <div className="col-md-2 col-sm-2 col-xs-2">
                                <div className="car-data-complete__logo ">
                                    <img
                                        src={
                                            make.name && getServiceImgPath(make.name.toLowerCase())
                                        }
                                        alt={make.name}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6 p-0">
                                <div className="car-data-complete__info ">
                                    <div>
                                        <div>{make.name}</div>
                                        <div>{range.name}</div>
                                    </div>
                                    {type && <div>{type.name}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="source-provider">{t('pages.home.source_provider')}</div>

                <div className="row mt-30">
                    <div className="col-md-2 col-sm-2 col-xs-2 text-center mt-5">
                        <div className="btn btn--md btn--dark btn--round back-btn" onClick={() => carStepPop()}>
                            <i className="icon-chevron-left" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        {this.renderSubmit(
                            'mt-10 mb-15',
                            (formSubmitting && formSubmitting.vehicle) ||
                            (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    renderFormStepMultipleVehicle = () => {
        const {
            car,
            formSubmitting,
            touched,
            loader,
            values,
            t,
            errors,
            carStep,
            carStepPop
        } = this.props;

        return (
            <div key="multiple-vehicle">
                {carStep.message !== '' && (
                    <div className="form-container__message form-container__message--warning">
                        <i className="icon-question-circle" />
                        {carStep.message}
                    </div>
                )}

                <div>
                    <Select
                        id="color_make_id"
                        list={
                            (window.predefined && window.predefined.makes) ||
                            window.settings.car.makes
                        }
                        value={values.color_make_id}
                        label={t('form.label.make')}
                        onHasOneOption={(fieldId, option) =>
                            this.handleChangeSelectForMakes(fieldId, option)
                        }
                        error={touched.color_make_id && errors.color_make_id}
                        selected={car.color_make_id}
                        onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                        directSearch
                    >
                        {t('form.placeholder.make')}
                    </Select>

                    <Select
                        id="color"
                        list={car.color_list}
                        value={values.color}
                        label={t('form.label.color')}
                        onChange={(id, option) => this.handleChangeSelectColorSelect(id, option)}
                        onHasOneOption={(fieldId, option) =>
                            this.handleHasOneOption(fieldId, option)
                        }
                        error={touched.color && errors.color}
                        selected={car.color}
                    >
                        {t('form.placeholder.color')}
                    </Select>
                </div>

                <div className="row">
                    <div className="col-md-2 col-sm-2 col-xs-2 text-center mt-5">
                        <div className="btn btn--md btn--dark btn--round back-btn" onClick={() => carStepPop()}>
                            <i className="icon-chevron-left" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        {this.renderSubmit(
                            'mt-10 mb-15',
                            (formSubmitting && formSubmitting.vehicle) ||
                            (loader[LOADER_API_GET_CAR] && loader[LOADER_API_GET_CAR] === true)
                        )}
                    </div>
                </div>
            </div>
        );
    };

    funnelProgressBar = () => {
        const { car } = this.props;
        const fields = [
            { key: 'make_id', nullable: false },
            { key: 'first_registration', nullable: false },
            { key: 'range_id', nullable: false },
            { key: 'type_id', nullable: false },
            { key: 'gear_id', nullable: true, array: 'gears', dependOn: 'type_id' },
            { key: 'qual_lt', nullable: true, array: 'qual_lt_list', dependOn: 'type_id' },
            { key: 'qual_md', nullable: true, array: 'qual_md_list', dependOn: 'type_id' },
            { key: 'zip_id', nullable: false }
        ];

        let precent = 0;

        for (let field of fields) {
            if (field.hasOwnProperty('dependOn') && fieldExists(car, field.dependOn)) {
                if (isCarFieldValid(car, field.key, field.array, field.nullable)) {
                    precent += 100 / fields.length;
                }
            } else {
                if (fieldExists(car, field.key)) {
                    precent += 100 / fields.length;
                }
            }
        }

        return (
            <div className="form-container__progress-container mb-20">
                <div className="form-container__progress-container__line" />
                <div
                    className="form-container__progress-container__bar"
                    style={{ width: precent + '%' }}
                />
            </div>
        );
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="form-holder">
                <div className="form-container">
                    <form onSubmit={e => handleSubmit(e)}>{this.renderFormStep()}</form>
                </div>

                {!isFormOnly() && <ContactViaChat center={true} />}
            </div>
        );
    }
}

const FormikApp = withFormik(formVehicleConfig)(FormVehicle);

const mapStateToProps = state => {
    return {
        car: state.car,
        error: state.error,
        zipData: state.zipData,
        loader: state.loader,
        global: state.global,
        formSubmitting: state.formSubmitting,
        cantonList: state.cantonList,
        carStep: state.carStep,
        licencePlateAttempts: state.licencePlateAttempts
    };
};

export default connect(
    mapStateToProps,
    {
        carResetFieldsBellow,
        carUpdateField,
        carFetchServices,
        carDataChange,
        setLoader,
        fetchCarData,
        fetchServices,
        fetchZipData,
        clearZipData,
        onCarIframeSubmit,
        resetPredefinedGarageToken,
        carLicencePlate,
        carStepSet,
        storeReset,
        carMultipleVehicleChoose,
        carStepPop,
        zipSet
    }
)(translate('translations')(FormikApp));
