import moment from 'moment';
import {DAY_SATURDAY_ISO, DAY_SUNDAY_ISO, HOLIDAYS} from "../store/consts/terminPicker.constants";
import strNormalize from "./strNormalize";

export const SCREEN_MD_MIN = 768;

export function disableBackgroundScroll(disable) {
    const style = document.body.style;
    if (disable) style.overflow = 'hidden';
    else style.overflow = 'auto';
}

export function compare(item1, item2) {
    return JSON.stringify(item1) !== JSON.stringify(item2);
}

export function getClassName(baseClass = '', isTrue = false, classIfTrue = '', classIfFalse = '') {
    return isTrue === true ? `${baseClass} ${classIfTrue}` : `${baseClass} ${classIfFalse}`;
}

export function sortListByKey(a, b, key, fromMinToMax = true) {
    if (fromMinToMax) return a[key] - b[key];

    return b[key] - a[key];
}

export function itemAlreadyExist(array, item) {
    return JSON.stringify(array).includes(JSON.stringify(item));
}

export function formatDate(date) {
    return moment(date).format('DD-MM-YYYY');
}

export function isMobileView() {
    return window.innerWidth < 768;
}

export function getMinDateWithoutWeekends(date, waiting, counter = 0) {
    if (waiting === 0) {
        return getInitialDate(date);
    }

    for (let i = 0; i <= waiting; i++) {
        const nextDate = moment(date).add(1, 'days');
        let dayOfWeek = parseInt(moment(nextDate).format('E'));

        if (dayOfWeek === DAY_SATURDAY_ISO || dayOfWeek === DAY_SUNDAY_ISO || HOLIDAYS.indexOf(moment(nextDate).format('D-M')) !== -1) {
            return getMinDateWithoutWeekends(nextDate, waiting, ++counter);
        }

        return getMinDateWithoutWeekends(nextDate, --waiting, ++counter);
    }
}

export function getInitialDate(date = moment()) {
    let dayOfWeek = parseInt(moment(date).format('E'));

    if (dayOfWeek === DAY_SATURDAY_ISO || dayOfWeek === DAY_SUNDAY_ISO) {
        const nextDate = moment(date).add(1, 'days');
        return getInitialDate(nextDate);
    }

    return moment(date).toDate();
}

export function filterServiceList(list, filterValue) {
    if (filterValue === '') {
        return list.filter(item => {
            if ((item.is_official_selected === true && item.selected === true) || (item.is_official_selected === true && item.exist === false)) {
                return null;
            }
            return item;
        });
    }

    return list.filter(item => {
        if (strNormalize(item.name).toLowerCase().includes(strNormalize(filterValue).toLowerCase())) {
            return item;
        }
        return null;
    });
}

export function isUncheckButtonDisabled(filteredList) {
    return filteredList.filter(service => service.exist === true && service.is_official_selected === false && service.selected === true).length === 0;
}

// if id contains in [7, 23] and selected === true
// if false, then go down
// if is down and click select, don't go up
export function getPredefinedServices(list, preselectedIds) {
    if (preselectedIds.length === 0)
        return [];

    return list.filter(service => {
        if (preselectedIds.includes(service.id))
            return service;
    });
}

export const urlSearchParams = (name, href) => {
    if (!href) {
        href = window.location.href
    }

    let url = new URL(href)
    let params = new URLSearchParams(url.search.slice(1))

    return params.get(name)
}


export function getAdditionalService(list, preselectedIds) {
    if (preselectedIds.length === 0)
        return list;

    return list.filter(service => {
        if (!preselectedIds.includes(service.id))
            return service;
    });
}

export const updateUrl = (key, value) => {
    return window.location.pathname + updateQueryStringParameter(window.location.search, key, value) + window.location.hash
}

export const updateQueryStringParameter = (uri, key, value) => {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

export const makeInputNumericOnly = (textbox, inputFilter) => {
    ['input', 'keydown', 'keyup'].forEach(function(event) {
        if(!textbox){
            return;
        }

        textbox.addEventListener(event, function() {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty('oldValue')) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
};

export default {
    disableBackgroundScroll,
    compare,
    getClassName,
    SCREEN_MD_MIN,
    updateUrl,
    urlSearchParams,
    makeInputNumericOnly
};
