import { API_SUCCESS, apiRequest } from '../../actions/api.actions';
import {LOADER_PLACEHOLDER_GARAGE_LIST, LOADER_SERVICE_CHECK} from '../../consts/loader.constants';
import { setLoader } from '../../actions/loader.actions';
import { addFlashMessage } from '../../actions/flashMessage.actions';
import { labourServiceUpdate, SERVICES_CHECK } from '../../actions/services.actions';
import {
    SERVICE_TYPE_LABOUR_CUSTOM,
    SERVICE_TYPE_LABOUR_SEARCH
} from '../../consts/services.constants';
import { FLASH_MESSAGE_ERROR } from "../../consts/flashMessage.constants";
import i18n from 'i18next';

export const SERVICE_CHECK_URL = 'check-service';

export const serviceCheckMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case `${SERVICES_CHECK}`:
                dispatch(
                    setLoader(`${LOADER_SERVICE_CHECK}-${action.payload.itemId}`, true)
                );
                dispatch(setLoader(LOADER_PLACEHOLDER_GARAGE_LIST,true));
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: {
                            id: action.payload.itemId,
                            is_official_selected: action.payload.isPreselectedByOfficial
                        },
                        url: SERVICE_CHECK_URL,
                        signature: SERVICES_CHECK
                    })
                );
                break;

            case `${SERVICES_CHECK} ${API_SUCCESS}`:
                dispatch(labourServiceUpdate(SERVICE_TYPE_LABOUR_CUSTOM, action.payload.data));
                dispatch(labourServiceUpdate(SERVICE_TYPE_LABOUR_SEARCH, action.payload.data));

                if (!action.payload.data.exist) {
                    if(!action.payload.data.is_official_selected){
                        dispatch(addFlashMessage(
                            i18n.getResource(window.base.language, 'translations', 'pages.services.labour.not_exist'),
                            FLASH_MESSAGE_ERROR
                        ));
                    }

                    dispatch(setLoader(LOADER_PLACEHOLDER_GARAGE_LIST,false));
                }

                dispatch(setLoader(`${LOADER_SERVICE_CHECK}-${action.payload.data.id}`, false));
                break;

            default:
                break;
        }
    };
};
