import React, {Component} from 'react';
import './Filter.scss';
import { translate } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import FilterBar from "./FilterBar";
import FilterListDropdown from "./FilterListDropdown";
import CSSTransitionGroup from 'react-addons-css-transition-group';

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpened: false
        }
    }

    handleClickOutside = () => {
    };

    showDropdown = (bool) => {
        this.setState({
            isDropdownOpened: bool
        })
    };

    render() {
        const { isDropdownOpened } = this.state;

        return (
            <div className="filter-list-dropdown-container">
                <FilterBar
                    onDropdownOpen={() => this.showDropdown(true)}
                />

                <CSSTransitionGroup
                    transitionName="ctg-filter-dropdown"
                    transitionAppear={isDropdownOpened}
                    transitionEnterTimeout={500}
                    transitionAppearTimeout={500}
                    transitionLeaveTimeout={300}
                >
                    {isDropdownOpened && <FilterListDropdown
                        onClose={() => this.showDropdown(false)}
                    />}
                </CSSTransitionGroup>
            </div>
        )
    }
}

export default translate('translations')(onClickOutside(Filter));
