//@flow

import React, {Component} from 'react';
import './Select.scss';
import onClickOutside from 'react-onclickoutside';
import {compare} from '../../../utils/common';
import {LOADER_FORM_VEHICLE} from '../../../store/consts/loader.constants';
import Loader from '../Loader/Loader';
import classNames from 'classnames';
import Input from "../Input/Input";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {selectDropdownOpened} from "../../../store/actions/global.actions";
import strNormalize from "../../../utils/strNormalize";
import TextHighlighter from "../Highlighter/TextHighlighter";

class Select extends Component<{}, {}> {
    constructor(props: Props) {
        super(props);
        this.optionsRef = React.createRef();
        this.selectRef = React.createRef();

        this.state = {
            list: this.props.list || null,
            isOpened: this.props.isOpened || false,
            selectedOption: this.props.selected
                ? this.props.list.find(item => item.id === this.props.selected)
                : null,
            placeholder: this.props.children || this.props.t('form.placeholder.default.select'),
            filter: {
                value: '',
                isOpened: false
            },
            attributes: this.props.attributes || [],
            attributesSeparator: this.props.attributesSeparator || '',
            index: -1
        };

        document.addEventListener('keyup', this.onKeyUpOrDown);
        document.addEventListener('keyup', this.onKeyEnter);
    }

    componentDidMount(): void {
        const {list} = this.state;
        const {id, onTouched, onHasOneOption} = this.props;

        if (list.length > 0) {
            if (onTouched) {
                onTouched();
            }
        }

        if (list.length === 1) {
            const selected = list[0];
            onHasOneOption(id, selected);
            this.selectOne(selected);
        }
    }

    componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
        if (this.state.isOpened !== nextState.isOpened) {
            this.props.selectDropdownOpened(nextState.isOpened);
        }

        if (
            (this.optionsRef !== null && this.optionsRef.current !== null) &&
            (this.state.filter.value !== nextState.filter.value)
        ) {
            const items = this.optionsRef.querySelector('.dd-list__options').children || false;

            if (items) {
                Array.from(items).forEach(item => {
                    item.className = item.className.replace('highlighted', '');
                })
            }
        }

        if ((this.state.isOpened !== nextState.isOpened) && !this.state.indexLast && this.state.selectedOption) {
            const selectedItemIndex = this.state.list.indexOf(this.state.selectedOption);

            this.setState({
                index: selectedItemIndex
            })
        }

        if (this.state.filter.value !== nextState.filter.value && nextState.filter.value.length === 0) {
            this.setState({
                selectedOption: null,
                indexLast: -1,
                index: -1
            }, () => {
                this.props.onChange(this.props.id, {});
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const {list} = this.state;

        if (list && JSON.stringify(list) !== JSON.stringify(nextProps.list)) {
            this.setState({
                list: nextProps.list
            }, () => {
                if (nextProps.list.length === 1) {
                    const selected = nextProps.list[0];
                    this.props.onHasOneOption(nextProps.id, selected);
                    this.selectOne(selected);
                }
            });
        }

        if (nextProps.openListOnMultipleOptions && (JSON.stringify(list) !== JSON.stringify(nextProps.list))) {
            this.setState({ filter: { value: '' }} )
        }

        if (nextProps.openListOnMultipleOptions && (JSON.stringify(list) !== JSON.stringify(nextProps.list)) && nextProps.list.length > 1) {
            this.setState({
                isOpened: true,
            })
        }

        if(this.props.selected !== nextProps.selected){
            this.setState({
                selectedOption: nextProps.selected
                    ? nextProps.list.find(item => item.id === nextProps.selected)
                    : null,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list} = this.state;

        if (compare(list, prevState.list)) {
            if (list.length === 1) {
                this.selectOne(list[0]);
            } else {
                this.setState({
                    selectedOption: null
                });
            }
        }

        if ((this.props.list.length !== prevProps.list.length) && this.props.list.length === 1) {
            const selected = list[0];

            this.props.onHasOneOption(this.props.id, selected);
            this.selectOne(selected);
        }

        if (
            (this.optionsRef !== null && this.optionsRef.current !== null) &&
            (
                (this.state.filter.value !== prevState.filter.value) ||
                (this.state.isOpened !== prevState.isOpened && this.state.isOpened === true)
            ) &&
            (this.state.selectedOption === null && this.getFilteredList(this.state.list).length > 0)
        ) {
            const items = this.optionsRef.querySelector('.dd-list__options').children || false;

            if (items) {
                this.highlightFirstItem(items[0]);
            }
        }
    }

    highlightFirstItem = (item) => {
        item.className += " highlighted";
        this.setState({
            index: 0
        });
    };

    selectOne = option => {
        const { id, onChange, list, forceOnChange } = this.props;
        const {selectedOption} = this.state;

        if (selectedOption && (option.id === selectedOption.id))
            return this.setState({
                isOpened: false
            });

        const selectedIndex = this.state.list.findIndex(opt => opt.id === option.id);

        this.setState({
                selectedOption: option,
                index: selectedIndex,
                indexLast: selectedIndex,
                filter: {
                    value: option.name.toString().toLowerCase()
                }
            },
            () => {
                this.setState({
                    isOpened: false
                });

                /* trigger onChange only if there's more then 1 item in list */
                /* or forceOnChange prop is passed */
                if (list.length !== 1 || forceOnChange) {
                    onChange(id, option);
                }
            }
        );
    };

    toggleList = () => {
        this.setState({
            isOpened: !this.state.isOpened
        });
    };

    scrollToPreselectedItem = () => {
        const { selectedOption } = this.state;

        if (!selectedOption)
            return;

        const elementToScrollTo = this.optionsRef.querySelector(`#option-${selectedOption.id}`);
        const LIST_ABOVE_THE_FOLD_HEIGHT = 160;
        const HALF_OF_THE_LIST_HEIGHT = (this.optionsRef.clientHeight / 2.5);

        if (!elementToScrollTo)
            return;

        if (elementToScrollTo.offsetTop > LIST_ABOVE_THE_FOLD_HEIGHT) {
            this.optionsRef.scrollTop = elementToScrollTo.offsetTop - HALF_OF_THE_LIST_HEIGHT;
        }
    };

    highlightSelection = (direction) => {
        const items = this.optionsRef.querySelector('.dd-list__options').children;
        const NUMBER_OF_VISIBLE_ITEMS = 3;

        items.forEach((item, index) => {
            item.classList.remove('highlighted');

            if (index === this.state.index) {
                item.className += ' highlighted';

                if ((index > NUMBER_OF_VISIBLE_ITEMS) && direction === 'DOWN') {
                    this.optionsRef.scrollTop += item.clientHeight;
                }

                if (direction === 'UP') {
                    this.optionsRef.scrollTop -= item.clientHeight;
                }
            }
        })
    };

    onKeyEnter = (e) => {
        e.preventDefault();
        const {isOpened, list} = this.state;

        if (e.keyCode === 13 && isOpened) {
            if (this.optionsRef && this.optionsRef.current !== null) {
                const selected = this.optionsRef.querySelector('.highlighted');

                if (selected) {
                    this.selectOne({
                        id: parseInt(selected.getAttribute('data-item-id')),
                        name: selected.getAttribute('data-item-name'),
                        productionTo: selected.getAttribute('data-item-production-to'),
                        productionFrom: selected.getAttribute('data-item-production-from'),
                    });
                } else {
                    const firstItemInList = this.getFilteredList(list)[0];

                    if (firstItemInList) {
                        this.selectOne({
                            id: firstItemInList.id,
                            name: firstItemInList.name
                        });
                    }

                }
            }
        }
    };

    onKeyUpOrDown = (e) => {
        const KEY_UP = 38;
        const KEY_DOWN = 40;
        const { index, list, isOpened } = this.state;

        if (!isOpened)
            return;

        switch (e.keyCode) {

            case KEY_UP:
                if (index > 0) {
                    this.setState({
                        index: index - 1
                    }, () => {
                        this.highlightSelection('UP');
                    });
                }

                // if (selectedOption && this.optionsRef.current) {
                //     this.optionsRef.querySelector(`#option-${selectedOption.id}`);
                //     Array.from(document.querySelectorAll('.dd-list li')).indexOf(document.getElementById(`#option-${selectedOption.id}`))
                // }
                break;

            case KEY_DOWN:
                if (index < (this.getFilteredList(list).length - 1))
                    this.setState({
                        index: index + 1
                    }, () => {
                        this.highlightSelection('DOWN');
                    });
                break;

            default:
                return null;
        }
    };

    openList = () => {
        const hasOnlyOneItem = (this.state.list.length === 1);

        this.setState({
            isOpened: !hasOnlyOneItem
        }, () => {
            if (!hasOnlyOneItem) {
                this.scrollToPreselectedItem()
            }
        });
    };

    removeListHighliter = () => {
        if (!this.state.isOpened) return;

        if (this.optionsRef && this.optionsRef.current !== null && this.optionsRef.querySelector('.dd-list__options')) {
            Array.from(this.optionsRef.querySelector('.dd-list__options').children).map(child => {
                return child.classList.remove('highlighted');
            });
        }
    };

    handleClickOutside = () => {
        const {selectedOption} = this.state;

        this.setState({
            isOpened: false,
            index: (!this.state.indexLast) ? -1 : this.state.indexLast,
            filter: {
                value: (selectedOption)
                    ? selectedOption.name.toString().toLowerCase()
                    : '',
                isOpened: false
            }
        });
    };

    renderSelectItem = (option) => {
        const {name, id} = option;
        const {filter} = this.state;

        return (
            <li
                key={id}
                id={`option-${id}`}
                className={classNames('dd-list__options--item', {
                    'selected': (this.state.selectedOption && parseInt(this.state.selectedOption.id) === parseInt(option.id))
                })}
                onClick={() => this.selectOne(option)}
                data-item-id={id}
                data-item-name={option.name}
                data-item-production-from={option.productionFrom}
                data-item-production-to={option.productionTo}
            >
                <TextHighlighter target={filter.value}>{name}</TextHighlighter>
                {' '}{this.renderAttributes(option)}
            </li>
        );
    };

    shouldRenderIconDropdown = (isLoading, listLength) => {
        return (isLoading === false || typeof isLoading === 'undefined') && (listLength > 1) && (this.state.isOpened === false);
    };

    renderAttributes(option) {
        if (!this.state.attributes.length) {
            return null;
        }

        return (
            <small>{
                this.state.attributes.map((item, i) => {
                    return (option[item] ? option[item] : '...') + (this.state.attributes.length - 1 > i ? this.state.attributesSeparator : '');
                })
            }</small>
        )
    }

    handleFilterChange = (e) => {
        const {value} = e.target;

        this.setState({
            filter: {
                value
            }
        });

        if (value === '' && !this.state.indexLast) {
            this.setState({
                index: -1
            })
        } else if (value === '' && this.state.indexLast) {
            this.setState({
                index: this.state.indexLast
            })
        } else if (value !== '') {
            this.optionsRef.scrollTop = 0;
            this.setState({
                index: -1
            })
        }
    };

    handleFilterValueReset = () => {
        this.setState({
            filter: {
                value: ''
            }
        })
    };

    renderFilter = () => {
        const {placeholder, filter} = this.state;

        return (
            <Input
                autoFocus
                type="text"
                placeholder={placeholder}
                className="dd-filter-input"
                name="filter"
                value={filter.value}
                iconLeftClassName='icon-magnifier'
                iconRightClassName='icon-cross'
                onChange={this.handleFilterChange}
                onTextClear={this.handleFilterValueReset}
                preventOnEnter={true}
            />
        )
    };

    getFilteredList = (list) => {
        const {value} = this.state.filter;
        const selectedOptionName = this.state.selectedOption && this.state.selectedOption.name.toString().toLowerCase();

        if (value === '' || (selectedOptionName === value.toLowerCase()))
            return list;

        if (this.props.directSearch) {
            return list.filter(item => item.name.toString().toLowerCase().startsWith(value.toString().toLowerCase()));
        }

        return list.filter(item => strNormalize(item.name.toString().toLowerCase()).includes(strNormalize(value.toString().toLowerCase())));
    };

    render() {
        const {
            id,
            disabled,
            error,
            label,
            hidden,
            isTransparent,
            iconClass,
            isLoading,
            hideErrorMessage,
            t
        } = this.props;
        const {isOpened, selectedOption, placeholder, list } = this.state;

        const filteredList = this.getFilteredList(list);

        if (hidden) return null;

        return (
            <div className="dd-container" ref={this.selectRef}>
                {label ? (
                    <div className="dd-title">
                        <label>{label}</label>
                    </div>
                ) : null}
                <div className="dd-header">
                    {selectedOption ? (
                        <div
                            id={id}
                            className={classNames('dd-select', {
                                'dd-select--disabled': disabled,
                                'dd-select--single-item': filteredList.length === 1,
                                'dd-select--transparent': isTransparent === true,
                                'dd-select--error': error
                            })}
                        >
                            {isOpened && this.renderFilter()}

                            <div className="dd-select-trigger-filter" onClick={() => this.openList()}>
                                {selectedOption.name}{' '}{this.renderAttributes(selectedOption)}
                            </div>
                        </div>
                    ) : (
                        <div
                            id={id}
                            className={classNames('dd-select', {
                                'dd-select--disabled': disabled,
                                'dd-select--single-item': filteredList.length === 1,
                                'dd-select--transparent': isTransparent === true,
                                'dd-select--error': error,
                                'dd-select--opened': this.state.isOpened
                            })}
                        >

                            {isOpened && this.renderFilter()}

                            <div className="dd-select-trigger-filter dd-select-trigger-filter--placeholder"
                                 onClick={() => this.openList()}>{placeholder}</div>
                        </div>
                    )}

                    {isLoading === true && (
                        <Loader isLoading={isLoading} type={LOADER_FORM_VEHICLE}/>
                    )}

                    {this.shouldRenderIconDropdown(isLoading, filteredList.length) && (
                        <div
                            className={`dd-header__icon ${
                                iconClass ? iconClass : 'icon-drop-down'
                                } `}
                        />
                    )}
                </div>
                {filteredList && isOpened && !disabled && (
                    <div className="dd-list" ref={el => (this.optionsRef = el)} onKeyPress={(e) => e.preventDefault()}>
                        <ul className="dd-list__options">
                            {filteredList && !!filteredList.length && filteredList.map(option => this.renderSelectItem(option))}
                            {filteredList && !!!filteredList.length && (
                                <div className='dd-no-result'>
                                    <p>{t('form.select.no_result')}</p>
                                </div>
                            )}
                        </ul>
                    </div>
                )}

                {error && !hideErrorMessage && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        global: state.global
    };
};

export default connect(
    mapStateToProps,
    {
        selectDropdownOpened
    }
)(translate('translations')(onClickOutside(Select)));
