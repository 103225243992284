export const AUTH = '[Auth]';

export const USER_SIGN_UP = `${AUTH} USER SIGN UP`;
export const USER_LOGIN = `${AUTH} USER LOGIN`;

export const userSignUp = data => ({
    type: USER_SIGN_UP,
    payload: {
        data
    }
});

export const userLogin = data => ({
    type: USER_LOGIN,
    payload: {
        data
    }
});
