import i18n from '../../app/config/i18n';
import { FILTER_NOT_MODIFIED } from "../consts/filter.constants";
const t = i18n.store.data[window.base.language].translations.global.filter;

export default {
    zipId: {
        id: "zipId",
        status: FILTER_NOT_MODIFIED,
        label: 'Zip',
        data: {
            zipData: []
        }
    },
    distance: {
        id: "distance",
        status: FILTER_NOT_MODIFIED,
        label: t.distance,
        unit: 'km',
        from: 0,
        to: 50,
        data: {
            min: 0,
            max: 50
        }
    },
    // price: {
    //     id: "price",
    //     status: FILTER_NOT_MODIFIED,
    //     label: t.price,
    //     unit: 'CHF',
    //     from: 230,
    //     to: 360,
    //     data: {
    //         min: 230,
    //         max: 360
    //     }
    // },
    ratings: {
        id: "ratings",
        status: FILTER_NOT_MODIFIED,
        label: t.ratings.label,
        description: t.ratings.description,
        data: {
            checked: false
        }
    },
    instantBooking: {
        id: "instantBooking",
        status: FILTER_NOT_MODIFIED,
        label: t.instant_booking.label,
        description: t.instant_booking.description,
        data: {
            checked: false
        }
    },
    officialGarages: {
        id: "officialGarages",
        status: FILTER_NOT_MODIFIED,
        label: t.official_garage.label,
        description: t.official_garage.description,
        data: {
            checked: false
        }
    }
}
