import React, {Component} from 'react';
import './GarageItem.scss';
import ItemExtraDetail from '../../shared/ItemExtraDetail/ItemExtraDetail';
import Label from '../../shared/Label/Label';
import {translate} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import {terminReset} from "../../../store/actions/terminPicker.actions";
import TerminPicker from "../TerminPicker/TerminPicker";
import classNames from 'classnames';
import {CSSTransition} from "react-transition-group";
import Button from "../Button/Button";
import Price from "../Price";
import {voucherHideElements} from "../../../utils/voucherHideElements";

class GarageItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cssTransition: {
                appear: true,
                timeout: 600,
                classNames: 'example'
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isExpanded !== this.props.isExpanded && nextProps.isExpanded === false) {
            this.props.terminReset();
        }
    }

    getRating(rate) {
        const {t} = this.props;
        if (rate <= 2.5) {
            return t('pages.services.rating.rate1');
        } else if (rate <= 3.5) {
            return t('pages.services.rating.rate2');
        } else if (rate <= 4) {
            return t('pages.services.rating.rate3');
        } else if (rate <= 4.5) {
            return t('pages.services.rating.rate4');
        }

        return t('pages.services.rating.rate5');
    };

    renderExpandedItem = () => {
        const {t, item, onItemSelect} = this.props;
        const {appear, timeout, classNames} = this.state.cssTransition;

        return (
            <CSSTransition in={appear} appear={true} timeout={timeout} classNames={classNames}>
                <div className="garage-item-expanded container-fluid clearfix">
                    <div className="garage-item-expanded__drop-off col-xs-8 col-lg-4">
                        <p className="garage-item-expanded__drop-off-title">{t('validations.termin_picker.drop_off_title')}</p>

                        <p className="garage-item-expanded__drop-off-text text-light">
                            {!voucherHideElements() ? t('validations.termin_picker.drop_off_description') : t('validations.termin_picker.drop_off_description_upto')}
                        </p>
                    </div>
                    <div className="garage-item-expanded__date-book col-xs-8 col-lg-4">
                        <TerminPicker
                            price={`${t('global.currency')} ${item.price}`}
                            onItemSelect={() => onItemSelect(item)}
                            averageWaitingTime={item.average_waiting}
                        />
                    </div>
                </div>
            </CSSTransition>
        )
    }

    render() {
        const {t, index, item, make, onExpandItem, terminReset, isExpanded, hideOptions, hideAxaPartner} = this.props;

        if (!item) return null;

        return (
            <div className="col-lg-8 garage-item-container">
                <div className="garage-item-collapsed clearfix"
                     onClick={() => {
                         if (hideOptions !== true) {
                             terminReset();
                             onExpandItem(index, !isExpanded)
                         }
                     }}
                >
                    <div className="garage-item col-lg-6">
                        <div className="garage-item__logo">
                            <img src={item.logo} alt={item.name}/>
                        </div>
                        <div className="garage-item-detail">
                            <div className="garage-item-detail-name">
                                <h5>
                                    <span className="garage-item-detail-name__garage">{item.name}</span>

                                    {!voucherHideElements() && item.highly_booked && (
                                        <Label size="md" type="info" icon="icon-rise-thicker">
                                            {t('pages.services.garage_item.highly_booked')}
                                        </Label>
                                    )}

                                    {!voucherHideElements() && item.garage_of_year && (
                                        <Label size="md" type="warning" icon="icon-trophy">
                                            {t('pages.services.garage_item.garage_of_year')}
                                        </Label>
                                    )}

                                    {!voucherHideElements() && item.cheapest && (
                                        <Label size="md" type="success" icon="icon-percentage-thicker">
                                            {t('pages.services.garage_item.cheapest')}
                                        </Label>
                                    )}
                                </h5>
                            </div>

                            <div className={classNames("garage-item-detail-extra", {
                                'topMarginModification': item.cheapest || item.garage_of_year || item.highly_booked
                            })}>
                                {item.is_axa_partner && hideAxaPartner === false && (
                                    <ItemExtraDetail
                                        color="blue"
                                        value={t('pages.services.garage_item.axa_partner')}
                                        extraClass="item-extra-detail-axa-partner"
                                    />
                                )}
                                {item.distance && (
                                    <ItemExtraDetail
                                        color="blue"
                                        icon="icon-map-marker-thicker"
                                        extra={`(${item.distance} ${t('global.km')})`}
                                        value={item.address}
                                    />
                                )}
                                {item.rating && (
                                    <ItemExtraDetail
                                        color="yellow"
                                        icon="icon-star-thicker"
                                        extra={`(${this.getRating(item.rating)})`}
                                        value={item.rating}
                                    />
                                )}
                                {item.is_certified && make && (
                                    <ItemExtraDetail
                                        color="green"
                                        icon="icon-check-circle-thicker"
                                        value={t('pages.services.garage_item.certificated', {certificated: make})}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {hideOptions !== true &&
                    <div className="garage-item-options col-lg-2">
                        <div className="col-xs-3 no-padding arrow-xs">
                            <Price
                                garage={item}
                                currency={t('global.currency')}
                                hidePrice={voucherHideElements()}
                            />
                        </div>
                        <div className="col-xs-5 no-padding arrow-xs">
                            <Button type="silver" size="md">
                                {t('pages.services.garage_item.show_details')} <span className={classNames("icon", {
                                'icon-chevron-down': !isExpanded,
                                'icon-chevron-up': isExpanded
                            })}/>
                            </Button>
                        </div>
                        <div className="arrow-lg">
                            <Price
                                garage={item}
                                currency={t('global.currency')}
                                hidePrice={voucherHideElements()}
                            >
                            <span className={classNames("icon", {
                                'icon-chevron-down': !isExpanded,
                                'icon-chevron-up': isExpanded
                            })}/>
                            </Price>
                        </div>
                    </div>
                    }
                </div>
                {isExpanded === true && this.renderExpandedItem()}
            </div>
        );
    }
}

export default connect(null, {terminReset})(translate('translations')(translate('translations')(GarageItem)));
