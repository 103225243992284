//@flow
import React, {Component} from 'react';
import {translate} from "react-i18next";
import classNames from "classnames";


class ContactViaChat extends Component<Props, {}> {

    showChat = () => {

        // if(typeof window.Intercom === 'function'){
        //     window.Intercom('boot'); window.Intercom('show');
        //     window.Intercom('onHide', function() {
        //         window.Intercom('shutdown');
        //     });
        // }
    };

    render() {
        const center = this.props.center ? this.props.center : false;

        return (
            <p className={classNames("", {
                "form-secondary-cta": center
            })}>
                {this.props.t('pages.home.chat_text')}
                <a className="btn--link" rel="noopener noreferrer" href={window.settings.supportPageLink}
                   target="_blank"> {this.props.t('pages.home.chat_button')}
                </a>
            </p>
        );
    }
}

export default translate('translations')(ContactViaChat);
