export const ZIP = '[Zip]';

export const ON_ZIP_SELECTED = `${ZIP} ON SELECTED`;
export const ON_ZIP_RESET = `${ZIP} ON ZIP RESET`;
export const ZIP_SET = `${ZIP} SET`;
export const ZIP_RESET = `${ZIP} RESET`;

export const onZipSelected = zip => ({
    type: ON_ZIP_SELECTED,
    payload: {
        zip
    }
});

export const onZipReset = () => ({
    type: ON_ZIP_RESET
});

export const zipSet = zip => ({
    type: ZIP_SET,
    payload: {
        zip
    }
});

export const zipReset = () => ({
    type: ZIP_RESET
});
