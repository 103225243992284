import store from '../../../store/store';
import history from '../../history';
import routes from "../../index";
import React from "react";

export const GarageCheckStep = (Component) => {
    return class extends React.Component {

        constructor(props) {
            super(props);

            const storeState = store.getState();
            this.shouldRender = true;

            if( !(Object.keys(storeState.garage).length && this.props.match.params.slug === storeState.garage.slug) ){
                this.shouldRender = false;
                history.replace(routes[1].path);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default GarageCheckStep;
