import React from "react";

export const HideFooter = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            this.shouldRender = true;

        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }

    }

};

export default HideFooter;
