import React, { Component } from 'react';
import {translate} from 'react-i18next';
import InputCheckout from "../InputCheckout/InputCheckout";
import classNames from 'classnames';
import Loader from "../Loader/Loader";
import { LOADER_SERVICE_CHECK } from "../../../store/consts/loader.constants";
import "./index.scss";

class ServiceToggler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasBackgroundHoverEffect: false
        };
    }

    handleClick = () => {
        this.setState({
            hasBackgroundHoverEffect: true
        })
    };

    handleMouseOut = () => {
        this.setState({
            hasBackgroundHoverEffect: false
        })
    };

    handleMouseOver= () => {
        this.setState({
            hasBackgroundHoverEffect: true
        })
    };

    render() {
        const { t, service, index, onToggle, isLoading, isAdditional, isPreselected } = this.props;
        const { hasBackgroundHoverEffect } = this.state;

        const siblingsTooltip = (
            <div className="search-item-extra">
                <span className="search-item-tooltip">
                    <span className="icon-question-circle"/>
                    <span className="search-item-tooltip--top search-item-tooltip__text">
                        {t('pages.services.labour_search_item_tooltip')}
                        {service.siblings.map((sibling, index) => <div key={index}>- {sibling.name}</div>)}
                    </span>
                </span>
            </div>
        );

        return (
            <div key={service.id} className={classNames('service-toggler clearfix', {
                     'service-toggler--disabled': isLoading || !service.exist,
                     'service-toggler--selected': service.selected,
                     'service-toggler--additional': isAdditional,
                     'service-toggler--preselected': isPreselected,
                     'service-toggler--hasHoverEffect': hasBackgroundHoverEffect
                 })}
                 onClick={this.handleClick}
                 onMouseOut={this.handleMouseOut}
                 onMouseOver={this.handleMouseOver}
            >
                <InputCheckout
                    id={`additional-service-${index}`}
                    name={`additional-service-${index}`}
                    checked={service.selected}
                    inputControlClass="m-0"
                    label={service.name}
                    onChange={e => onToggle(e, service)}
                />
                <Loader isLoading={isLoading} type={`${LOADER_SERVICE_CHECK}-${service.id}`} />
                {!!service.siblings.length && siblingsTooltip}
            </div>
        )
    }
}
export default translate('translations')(ServiceToggler);
