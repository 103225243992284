import React, {Component} from 'react';
import './GarageLoadProgressBar.scss';
import {translate} from "react-i18next";
import {
    getSelectedLabourServices,
} from "../../../store/selectors/services";
import {connect} from "react-redux";
import {debounce} from "lodash";
import imagesPath from "../../../utils/imagesPath";

class GarageLoadProgressBar extends Component {

    static MAX_PROGRESS = 99;
    static SERVICES_INCREASE_INTERVAL = 0.1;
    static GARAGE_LIST_INCREASE_INTERVAL = 1;
    static INTERVAL_PROGRESS_WIDTH_TIMEOUT = 40;
    static MESSAGE_INTERVAL_TIMEOUT = 1000;

    constructor(props){
        super(props);

        this.state = {
            currentMessage: 0,
            totalMessages: 2,
            progressWidthPercent: 0,
            messages: [
                props.t('pages.garage.progress_load.searching_for_offers', {city: this.getCity()}),
                props.t('pages.garage.progress_load.sourcing_prices'),
                props.t('pages.garage.progress_load.finding_deals')
            ],
            intervalIncrease: GarageLoadProgressBar.SERVICES_INCREASE_INTERVAL,
            servicesInterval: setInterval(this.intervalProgressWidth,GarageLoadProgressBar.INTERVAL_PROGRESS_WIDTH_TIMEOUT)
        };

        this.handleGarageListDebounce = debounce(this.garageListDebounce, 1000);
    }

    componentDidMount() {
        if(this.props.services.labour.search.length > 0 && this.props.selectedLabourServices.length === 0){
            this.handleGarageListDebounce();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.messagesInterval);
        clearInterval(this.state.servicesInterval);
        clearInterval(this.state.garageListInterval);
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if(this.props.selectedLabourServices.length > 0){
            if(this.state.intervalIncrease === GarageLoadProgressBar.GARAGE_LIST_INCREASE_INTERVAL){
                clearInterval(this.state.messagesInterval);
                clearInterval(this.state.garageListInterval);
                this.setState({
                    currentMessage: 0,
                    totalMessages: 2,
                    progressWidthPercent: 0,
                    intervalIncrease: GarageLoadProgressBar.SERVICES_INCREASE_INTERVAL,
                    servicesInterval: setInterval(this.intervalProgressWidth,GarageLoadProgressBar.INTERVAL_PROGRESS_WIDTH_TIMEOUT)
                });
            }

            this.handleGarageListDebounce();
        }
    }

    garageListDebounce = () => {
        clearInterval(this.state.servicesInterval);

        this.setState({
            messagesInterval: setInterval(this.messagesInterval,GarageLoadProgressBar.MESSAGE_INTERVAL_TIMEOUT),
            garageListInterval: setInterval(this.intervalProgressWidth,GarageLoadProgressBar.INTERVAL_PROGRESS_WIDTH_TIMEOUT),
            intervalIncrease: GarageLoadProgressBar.GARAGE_LIST_INCREASE_INTERVAL
        });

    };

    messagesInterval = () => {
        if(this.state.currentMessage === this.state.totalMessages){
            clearInterval(this.state.messagesInterval);

            return;
        }

        this.setState({
            currentMessage: this.state.currentMessage + 1
        });
    };

    intervalProgressWidth = () => {
        if(this.state.progressWidthPercent >= 100){
            clearInterval(this.state.garageListInterval);

            return;
        }

        this.setState({
            progressWidthPercent: this.state.progressWidthPercent + this.state.intervalIncrease
        });
    };

    getCity = () => {
        const {zipData, car} = this.props;

        for(let zip of zipData){
            if(zip.id === car.zip_id){
                return zip.name;
            }
        }
    };

    render() {
        const { progressWidthPercent, messages, currentMessage } = this.state;

        return (
            <div className="garage-load-progress-bar">
                <div className="progress-bar-description"><img src={imagesPath('circle-rolling-bold.svg')} alt="Loading..."/> {messages[currentMessage]}...</div>
                <div className='progress-bar'>
                    <div className='progress-bar-label'
                         style={{ width: (progressWidthPercent > GarageLoadProgressBar.MAX_PROGRESS ? GarageLoadProgressBar.MAX_PROGRESS : progressWidthPercent) + '%' }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        services: state.services,
        zipData: state.zipData,
        selectedLabourServices: getSelectedLabourServices(state),
    };
};
export default connect(
    mapStateToProps, {}
)(translate('translations')(GarageLoadProgressBar));
