import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import { setError } from '../../actions/error.actions';
    import {
    officialServiceToggleSelection,
    SERVICES_FETCH_ALL,
    SERVICES_TOGGLE_OFFICIAL,
    serviceCheck,
    setServices, serviceUncheck, SERVICES_UNCHECK
    } from '../../actions/services.actions';
import store from '../../store';
import { SERVICE_TYPE_LABOUR_SEARCH } from '../../consts/services.constants';
import { getOfficialService } from '../../selectors/services';
import { setLoader } from "../../actions/loader.actions";
import {
    LOADER_API_LIST_SERVICES,
    LOADER_PLACEHOLDER_GARAGE_LIST,
    LOADER_PLACEHOLDER_OFFICIAL_SERVICES
} from "../../consts/loader.constants";

export const SERVICE_LIST_URL = 'list-services';

export const servicesMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case `${SERVICES_FETCH_ALL}`:
                dispatch(setLoader(
                    LOADER_PLACEHOLDER_GARAGE_LIST,
                    true
                ));
                dispatch(setLoader(
                    LOADER_PLACEHOLDER_OFFICIAL_SERVICES,
                    true
                ));
                dispatch(setLoader(
                    LOADER_API_LIST_SERVICES,
                    false
                ));
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: {},
                        url: SERVICE_LIST_URL,
                        signature: SERVICES_FETCH_ALL
                    })
                );
                break;

            case `${SERVICES_FETCH_ALL} ${API_SUCCESS}`:
                dispatch(setServices(action.payload.data));

                dispatch(setLoader(
                    LOADER_API_LIST_SERVICES,
                    true
                ));

                if ( !!!action.payload.data.services.official[action.payload.data.services.official.selected] ) {
                    dispatch(setLoader(
                        LOADER_PLACEHOLDER_GARAGE_LIST,
                        false
                    ));
                }

                dispatch(setLoader(
                    LOADER_PLACEHOLDER_OFFICIAL_SERVICES,
                    false
                ));

                break;

            case `${SERVICES_FETCH_ALL} ${API_ERROR}`:
                dispatch(setError(`We couldn't find any service details about your car. Sorry.`));
                dispatch(setLoader(
                    LOADER_API_LIST_SERVICES,
                    true
                ));
                break;

            case SERVICES_TOGGLE_OFFICIAL:
                dispatch(officialServiceToggleSelection(action.payload.selected));

                const selectedServices = getOfficialService(store.getState());

                selectedServices.map(service => {
                    if (action.payload.selected) {
                        dispatch(serviceCheck(service.id, true));
                    } else {
                        dispatch(serviceUncheck(SERVICE_TYPE_LABOUR_SEARCH, service.id));
                    }

                    return service;
                });

                break;

            case SERVICES_UNCHECK:
                dispatch(setLoader(LOADER_PLACEHOLDER_GARAGE_LIST,true));
                break;

            default:
                break;
        }
    };
};
