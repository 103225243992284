import lang from '../app/lang';

const defaultLang = lang[window.base.language].translations;

export const preparedLabourSearchServices = [
    {
        id: 76,
        name: defaultLang.preparedServices.zahriemen.name,
        second_name: defaultLang.preparedServices.zahriemen.second_name
    },
    {
        id: 444,
        name: defaultLang.preparedServices.oil.name,
        second_name: defaultLang.preparedServices.oil.second_name
    },
    {
        id: 451,
        name: defaultLang.preparedServices.break_fluid.name,
        second_name: defaultLang.preparedServices.break_fluid.second_name
    },
    {
        id: 190,
        name: defaultLang.preparedServices.airfilter.name,
        second_name: defaultLang.preparedServices.airfilter.second_name
    },
    {
        id: 435,
        name: defaultLang.preparedServices.klimafilter.name,
        second_name: defaultLang.preparedServices.klimafilter.second_name
    }
];

export function checkByName(official, labour) {
    return official.korId !== 9 && official.name.toLowerCase().includes(labour.prepared.name);
}

export function checkById(official, labour) {
    return official.ItemMpId === labour.prepared.id && official.korId !== 9;
}

export function checkByChildName(officialChild, labour) {
    return officialChild.name.toLowerCase().includes(labour.prepared.name);
}

export function checkByChildId(officialChild, labour) {
    return officialChild.itemMpId === labour.prepared.id;
}

export default {
    preparedLabourSearchServices,
    checkByName,
    checkById,
    checkByChildName,
    checkByChildId
};
