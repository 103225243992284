import { createSelector } from 'reselect';
import i18n from 'i18next';
import roundCeilPrice from "../../utils/roundCeilPrice";

const state = state => state;

export const applyGarageDiscount = createSelector(
    state,
    state => {

        const garage = state.garage;
        const voucher = state.voucher;
        const garageDiscount = state.garageDiscount;

        if(!garage || !garage.hasOwnProperty('price')){
            return garage;
        }

        garage.totalPrice = garage.price;

        if(voucher){

            if(garage.price < voucher.minimum_amount){
                return garage;
            }

            if(voucher.discount_type === 1){
                garage.discount = (garage.price * (voucher.discount / 100));
                garage.discountValue = `-${voucher.discount}%`;
            }
            else if(voucher.discount_type === 2){
                garage.discount = voucher.discount;
                garage.discountValue = `-${voucher.discount} ${i18n.getResource(window.base.language, 'translations', 'global.currency')}`;
            }

            garage.totalPrice = roundCeilPrice(garage.price - garage.discount);
        }
        else if(garageDiscount){
            garage.discount = (garage.price * (garageDiscount / 100));
            garage.totalPrice = roundCeilPrice(garage.price - garage.discount);
            garage.discountValue = `-${garageDiscount}%`;
        }

        return garage;
    }
);
