/* eslint-disable */
import React from "react";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage'
        },
        titles: {
            home: 'Carhelper – Erzähl von deinem Auto',
            services: 'Carhelper – Vergleiche Garagen-Preise in {{city}}',
            garage: 'Carhelper – Buche einen Service bei {{garageName}}',
            booking: 'Carhelper – Deine Anfrage wurde verschickt.',
        },
        global: {
            filter: {
                title: 'Filter',
                hide: 'Filter ausblenden',
                distance: 'Distanz',
                price: 'Preis',
                else: 'Sonstiges',
                ratings: {
                    label: 'Braucht Bewertungen',
                    description: 'Nur Garagen mit Bewertungen',
                },
                instant_booking: {
                    label: 'Instant booking',
                    description: 'Only garages with instant booking',
                },
                official_garage: {
                    label: 'Offizielle Markengarage',
                    description: 'Nur offizielle {carBrand} Markenvertreter',
                },
                no_filters: 'Keine Filter ausgewählt',
                save: 'Suchen',
                reset: 'Zurücksetzen'
            },
            illustration_card: {
                no_garages_nearby: {
                    title: "Keine Garage in der Nähe, die ausgewählte Dienstleistungen anbietet",
                    description: "Ändern Sie Ihren Standort, um Angebote in verschiedenen Regionen anzuzeigen."
                }
            },
            currency: 'CHF',
            tax: 'MwSt.',
            total: 'Total',
            total_with_vat: 'Total inkl MwSt',
            discount: 'Rabatt',
            service: 'Service',
            sponsored: "Gesponsert",
            sponsored_post: 'Gesponserter Beitrag',
            to: 'zu',
            km: 'km',
            car: {
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                go_back: 'Bearbeiten',
                update_info: 'Fahrzeuginformationen aktualisieren'
            },
            official_service: {
                title: 'EMPFEHLUNG FÜR DEIN AUTO',
                description: 'Dank der durch dich zur Verfügung gestellten Daten, sowie den Herstellerangaben von {{carBrand}}, können wir dir einen personalisierten Service für dein Fahrzeug empfehlen.',
                includes: 'BEINHALTET',
            },
            preselected_service: {
                title: 'Ausgewählte Services: '
            },
            additional_service: {
                open: 'Zusätzliche Services',
                title: 'Reparatur',
                description: 'Wähle weitere Services aus um die Suche zu verfeinern.',
                uncheck_all: 'Alle abwählen',
                save: 'Speichern'
            }
        },
        tab: {
            car: 'FAHRZEUGDATEN',
            services_car: 'Mein Auto',
            services_maintenance: 'Autoservice- und reparatur',
            order_overview: 'Auftragsübersicht',
        },
        form: {
            placeholder: {
                range: 'Modell',
                type: 'Fahrzeugausführung',
                gear: 'Getriebeart',
                first_registration: '2019',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                mileage: 'Kilometerstand',
                zip: 'PLZ',
                make: 'Marke',
                username: 'Benutzername eingeben',
                year: '2019',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                email: 'E-Mail',
                phone: 'Telefonnummer',
                password: 'Passwort',
                city: 'Stadt',
                color: 'Farbe',
                canton: 'ZH',
                plate_number: '18 341',
                default: {
                    select: 'Bitte auswählen',
                },
                date: 'Datum auswählen',
                morning: 'Morgen',
                afternoon: 'Nachmittag',
                voucher: 'Rabattcode',
            },
            label: {
                licence_plate: 'Nummernschild',
                make: 'Marke',
                range: 'Modell',
                type: 'Ausführung',
                gear: 'Getriebeart',
                first_registration: 'Jahr der Erstzulassung',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                mileage: 'Kilometerstand',
                zip: 'PLZ',
                username: 'Benutzername',
                year: 'Jahr der Erstzulassung',
                km_stand: 'Kilometerstand',
                first_name: 'Vorname',
                last_name: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                agb_agreement: 'Ich akzeptiere die Allgemeinen',
                terms_and_condition: ' Geschäftsbedingungen',
                date: 'Datum',
                availability: 'Verfügbarkeit',
                bookFor: 'Buchen für',
                book: 'Anfragen',
                city: 'Stadt',
                color: 'Farbe:',
            },
            button: {
                next: 'Weiter',
                confirm: 'Bestätigen',
                submit: 'Angebote suchen',
            },
            select: {
                no_result: 'Kein Ergebnis gefunden'
            },
            saveGarageList: {
                title: 'Für später speichern',
                description: 'Gib deine E-Mail-Adresse ein, um deine Suche für später zu speichern. Du erhältst dann in den nächsten Minuten einen Link.',
                submit: 'Garagenliste speichern',
                save: 'Für später speichern',
                success: 'Wir haben deine Liste erfolgreich gespeichert. Du erhältst in wenigen Minuten eine Bestätigung per E-Mail.'
            },
            resetPassword: {
                link: 'Passwort vergessen?',
                title: 'Passwort zurücksetzen',
                description: 'Haben Sie Ihr Passwort vergessen? Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen Anweisungen zum Zurücksetzen.',
                submit: 'Passwort zurücksetzen',
                success: "Wenn eine E-Mail vorhanden ist, sollten Sie eine E-Mail mit dem Token zum Zurücksetzen des Kennworts erhalten",
            },
        },
        validations: {
            label: {
                plate_number: 'plate number [de]',
                make: 'Marke',
                range: 'Modell',
                type: 'Version',
                first_registration: 'Jahr der Erstzulassung',
                gear: 'Getriebeart',
                qual_md_list: 'Fahrzeugeigenschaften',
                qual_lt_list: 'Fahrzeugaufbau',
                mileage: 'Kilometerstand',
                zip: 'PLZ',
                km_stand: 'Kilometerstand',

                firstname: 'Vorname',
                lastname: 'Nachname',
                phone: 'Telefonnummer',
                email: 'E-Mail',
                password: 'Passwort',
                agb_agreement: 'Allgemeine Geschäftsbedingungen',

                zip_not_found: 'Keine Ergebnisse mit PLZ {{name}}',
                zip_not_valid: 'Bitte wähle eine gültige PLZ.',
                zip_required: 'PLZ ist obligatorisch.',
                zip_must_be_number: 'Bitte gib nur Zahlen ein.',

                voucher: 'Voucher',
            },
            default: {
                mixed: {
                    required: '${label} fehlt',
                },
                string: {
                    email: 'Bitte E-Mail prüfen',
                    min: 'Zu kurzer Wert',
                    max: 'Zu langer Wert',
                    test: '${label} zustimmen',
                    matches: 'Ungültiger Eintrag',
                },
                boolean: {
                    required: '${label} zustimmen'
                }
            },
            termin_picker: {
                drop_off_title: "Verfügbarkeit für die Fahrzeugabgabe",
                drop_off_description: "Wähle das passende Datum und die Uhrzeit für die Abgabe deines Autos.",
                drop_off_description_upto: 'Wähle bitte deinen Wunschtermin und die Uhrzeit für die Abgabe deines Autos.',
                maximum: 'Es können maximal 5 Daten gewählt werden.',
                required: 'Bitte wähle ein Datum.',
            },
            licence_plate: {
                canton: {
                    valid: "Gültiger Kanton",
                    nonValid: "Ungültiger Kanton"
                },
                number: {
                    valid: "Gültige Nummer",
                    nonValid: "Ungültige Nummer"
                },
                required: 'Gültiges Nummernschild ist erforderlich',
                cantonAsString: 'Kanton darf nur Buchstaben enthalten',
                attempts: 'Du hast {{attempt}} zusätzliche Versuche.',
                attempt: 'Sie haben 1 zusätzlichen Versuch.'
            },
            voucher_minimum_price: "Rabatt nicht anwendbar\nMindestbestellwert ist CHF {{price}}.",
        },
        pages: {
            home: {
                title: 'Erzähl von deinem Auto',
                chat_text: 'Unsicher? ',
                chat_button: ' Live Chat',
                licence_plate: {
                    title: 'Gebe deine Fahrzeugdaten ein und erhalte sofort Angebote.',
                    manual: 'Weisst du deine Autokennzeichennummer nicht?',
                    sourcing: 'Deine Fahrzeugdaten werden geladen',
                    sourced_success: 'Wir konnten deine Fahrzeugdaten erfolgreich finden.',
                },
                form_sidebar_label_1: '2 Jahre Garantie auf die Arbeit und Teile',
                form_sidebar_label_2: 'Einfache Vergleichsmöglichkeit von über 450 Garagen',
                form_sidebar_label_3: 'Kostenlose Stornierung',
                licence_plate_tooltip: 'Die Fahrzeugidentifikation erfolgt mithilfe folgender Quelle: Bundesamt für Strassen ASTRA.',
                source_provider: 'Quelle: Bundesamt für Strassen ASTRA.',
            },
            services: {
                labour_search_item_tooltip: "Die folgenden Wartungsarbeiten sind in diesem Service enthalten:",
                title: 'Perfekt! Jetzt musst du nur noch eine Garage auswählen',
                submit: 'Angebote anzeigen',
                title_garages: 'Garagen',
                make_recommendation: 'Empfohlener Service für',
                save_up_to: 'Carhelper Kunden sparen bis zu {{percent}}%',
                labour: {
                    title: 'Zusätzliche Arbeit',
                    not_found: 'Es konnten keine Resultate gefunden werden...',
                    not_exist: 'Hoppla, anscheinend ist dieser Service für dein Fahrzeug nicht verfügbar. Bitte prüfe deine Anfrage erneut oder kontaktiere uns',
                },
                sort_by: {
                    best_match: 'Beste Übereinstimmung',
                    affordability: 'Preis',
                    proximity: 'Nähe',
                    save_research: 'Resultate speichern',
                    title: 'Sortieren nach',
                    save_research_mobile: 'Speichern',
                },
                rating: {
                    rate1: 'Akzeptabel',
                    rate2: 'Angemessen',
                    rate3: 'Gut',
                    rate4: 'Sehr gut',
                    rate5: 'Hervorragend',
                },
                garage_api_error: '[DE] Oops, something happened on the server... We couldn\'t fetch garages at the moment.',
                garages_no_results: 'Es konnte keine Garage gefunden werden.',
                garages_load_more: 'Mehr anzeigen',
                ad_baloise: {
                    title: 'Autoversicherung? Jetzt die Prämie für dein Auto berechnen.',
                    description: 'Die günstigste Autoversicherung der Schweiz mit Bestnoten für ' +
                        'Kundenzufriedenheit und individuelle Versicherungsleistungen.',
                    btn: 'Zum Rechner auf baloise.ch',
                },
                garage_item: {
                    highly_booked: 'Oft gebucht',
                    garage_of_year: 'Garage des Jahres',
                    cheapest: 'Bester Deal',
                    show_details: 'Details zeigen',
                    certificated: 'Zertifiziert durch {{make}}',
                    book_now: 'Zum Angebot',
                    axa_partner: 'AXA Premium Partner',
                },
                official_not_found_too_young: 'Hups, wir konnten keine Wartungsempfehlung für dein Auto finden. Bitte wähle deinen Service manuell.',
                official_not_found: 'Hups, wir konnten keine Wartungsempfehlung für dein Auto finden. Bitte wähle deinen Service manuell.',
                voucher: {
                    upto: {
                        show_more: 'So geht’s',
                        more_description: `
                        <ul>
                        <li><span>1</span> <span>Garage in deiner Nähe auswählen</span></li>
                        <li><span>2</span> <span>Wunschtermin angeben</span></li>
                        <li><span>3</span> <span>Reservierungsbestätigung erhalten</span></li>
                        </ul> `,
                    }
                }
            },
            garage: {
                checkoutPaymentTitle: 'Zahlung',
                checkoutPaymentDescription: '<p>Eine Zahlung wird erst fällig, sobald der Service durch die Garage erfolgreich durchgeführt wurde und du zufrieden damit bist.</p><p>Die Bezahlung kann zum Zeitpunkt der Abholung deines Fahrzeugs direkt bei der Garage vorgenommen werden.</p>',
                login_via_google: 'Mit Google anmelden',
                login_via_facebook: 'Mit Facebook anmelden',
                login_separator_or: 'oder',

                // TODO REMOVE
                please_login: 'Bitte logge dich ein',
                link_sign_up: 'registriere dich',
                register_to_finalize_booking: 'um deine Buchung abzuschliessen.',
                login_to_finalize_booking: 'oder melde dich an, um deine Buchung abzuschliessen.',
                please: 'Bitte',
                btn_login: 'logge dich ein',
                // TODO REMOVE

                includes: 'ENTHALTEN',
                price_details: 'Details ansehen',
                btn_continue: 'Weiter',
                btn_finish_booking: 'Buchung bestätigen',
                btn_checkout_for: 'Jetzt buchen für {{price}}',
                btn_checkout: 'Anfragen',
                link_logout: 'Wenn Sie sich abmelden möchten, klicken Sie',
                link_logout_here: 'hier',
                logged_as: 'Eingeloggt als',
                add_more_inputs: 'Nur noch eine letzte Sache',
                add_confirm_inputs: 'Bitte ergänze oder bestätige deine Telefonnummer damit deine ausgewählte ' +
                    'Garage mit dir Kontakt aufnehmen kann.',
                show_summary: 'Bestellübersicht anzeigen',


                auth: {
                    register: 'Bitte registriere dich um deine Buchung abzuschliessen, oder <1>logge dich ein </1>.',
                    login: 'Bitte logge dich ein um deine Buchung abzuschliessen, oder <1>registriere dich </1>.',
                },

                termin_picker: {
                    i_am_flexible: 'Ich bin flexibel'
                },
                why_carhelper: {
                    title: 'Wieso soll ich über Carhelper buchen?',
                    item1: 'Attraktive Festpreise',
                    item2: 'Einfache Vergleichsmöglichkeit von über 450 Garagen',
                    item3: 'Hohe Qualität und Transparenz durch Kundenfeedbacks ',
                    item4: '2 Jahre Garantie auf die Arbeit und Teile',
                },
                predefined: {
                    selected: 'Ausgewählte Garage',
                    nearby: 'Nächstgelegene Garagen',
                    inactive: '{{garageName}} ist noch nicht auf Carhelper registriert. Wir können deshalb keinen Preis für diesen Service berechnen. Bitte wähle untenstehend eine andere Garage aus deiner Region aus:',
                    out_of_make: 'Diese Garage bietet keine Services an für {{make}}. Bitte wähle untenstehend eine andere Garage aus deiner Region aus:',
                },
                progress_load: {
                    searching_for_offers: 'Angebote in der Nähe von {{city}} werden gesucht',
                    sourcing_prices: 'Garagenpreise werden geladen',
                    finding_deals: 'Die besten Angebote werden geladen',
                },
                ab_test_discount: {
                    title: 'Exklusives Carhelper Angebot – Nur noch heute',
                    value: 'Spare {{discount}}% auf alle Buchungen. Best-Preis garantiert.',
                },
                ab_test_discount_checkout: {
                    title: 'Exklusives Carhelper Angebot',
                    value: 'Spare {{discount}}% auf alle Buchungen.',
                },
                testimonials_title: 'Bewertungen von anderen Kunden',
                termin: 'Termin',
                customer_data: 'Kundendaten',
            },
            confirm: {
                title: 'Gratuliere! Deine Anfrage wurde versendet.',
                description: `Die Garage prüft nun den Auftrag und wird diesen schnellstmöglich
                            bestätigen. Die Bestätigung erhältst du per E-Mail und SMS. Falls die
                            Garage deine Wunschtermine nicht einhalten kann, wird sie sich telefonisch
                            bei dir melden. Hast du Fragen zu deinem Auftrag? Du kannst unseren Support
                            jederzeit kontaktieren.`,
                order_number: 'Auftragsnummer:',
                carhelper_team: 'Dein Carhelper Team',
                order_overview: 'Bestellübersicht',
            }
        },
        preparedServices: {
            oil: {
                name: null,
                second_name: null
            },
            zahriemen: {
                name: 'zahnriemen',
                second_name: 'prüfen'
            },
            break_fluid: {
                name: null,
                second_name: null
            },
            airfilter: {
                name: null,
                second_name: null
            },
            klimafilter: {
                name: null,
                second_name: null
            }
        }
    }
};
