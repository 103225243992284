export const GARAGE_DISCOUNT = '[GARAGE_DISCOUNT]';

export const GARAGE_DISCOUNT_SET = `${GARAGE_DISCOUNT} SET`;

export const setGarageDiscount = discount => ({
    type: GARAGE_DISCOUNT_SET,
    payload: {
        discount
    }
});
