export const persistReset = (state) => {
    const models = ['loader', 'user', 'licencePlateAttempts'];
    let store = {};

    models.forEach(model => {
        store[model] = state[model];
    });

    return store;
};

export default persistReset;