import React, { Component } from "react";
import Slider from "react-slick";
import './Slider.scss';

export default class SimpleSlider extends Component {

    constructor(props) {
        super(props);
        this.settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
        }
    }

    renderRating = (rating) => {
        if (rating === 0) return;

        let content = [];

        for (let i = 0; i < rating; i++) {
            content.push(
                <span key={i} className="icon-full-star"></span>
            )
        }

        return content;
    };

    render() {
        const { slides } = this.props;

        return (
            <div className="slide-testemonial">
                <Slider {...this.settings}>
                    {slides.map((slide, index) => (
                        <div data-index={index} key={index}>
                            <div className="slide-testemonial__header clearfix">
                                <div className="slide-testemonial__avatar">
                                    <img src={slide.image} alt={slide.name}/>
                                </div>
                                <div className="slide-testemonial__rating">
                                    <h5>{slide.name}</h5>
                                    {this.renderRating(slide.rating)}
                                </div>
                            </div>
                            <div className="slide-testemonial__body">
                                <p>"{slide.content}"</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}
