export const VOUCHER = '[Voucher]';

export const VOUCHER_CHECK = `${VOUCHER} CHECK`;
export const VOUCHER_SET = `${VOUCHER} SET`;

export const voucherCheck = (voucher, garage) => ({
    type: VOUCHER_CHECK,
    payload: {
        voucher,
        garage
    }
});

export const voucherSet = voucher => ({
    type: VOUCHER_SET,
    payload: {
        voucher
    }
});