//@flow
import React, { Component } from 'react';
import './VoucherInput.scss';
import {translate} from "react-i18next";
import Input from "../Input/Input";
import {withFormik} from "formik";
import {connect} from "react-redux";
import {formSubmittingSignUp} from "../../../store/actions/formSubmitting.actions";
import {formErrorsVoucherRemove} from "../../../store/actions/formErrors.actions";
import {formVoucherConfig} from '../../../app/config/yup';
import {voucherCheck} from "../../../store/actions/voucher.actions";

class VoucherInput extends Component<{}, {}> {

    componentDidUpdate() {
        const {formErrors, setFieldError, formErrorsVoucherRemove} = this.props;

        if (formErrors.voucher) {
            Object.keys(formErrors.voucher).map(field => {
                return setFieldError(field, formErrors.voucher[field]);
            });

            formErrorsVoucherRemove();
        }
    }

    handleTextChange = (e: Event) => {
        const {handleChange} = this.props;
        const {name, value} = e.target;

        if (e.target instanceof HTMLInputElement) {
            this.setState({
                [name]: value
            });

            handleChange(e);
        }
    };


    isMinimumPrice = () => {
        const {t, garage, voucher} = this.props;

        if(voucher && garage.price < voucher.minimum_amount && !this.state) {
            return t('validations.voucher_minimum_price', {price: voucher.minimum_amount});
        }

        return null;
    };

    render() {
        const {t, handleSubmit, touched, errors, values, formSubmitting} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-8 col-sm-4 col-xs-7 col-sm-push-2 col-xs-push-0 col-md-push-0">
                        <div className="input-group-voucher">
                            <Input
                                type="text"
                                id="voucher"
                                name="voucher"
                                placeholder={t('form.placeholder.voucher')}
                                extraClass="checkout-voucher-input"
                                error={(touched.voucher && errors.voucher) || this.isMinimumPrice()}
                                onChange={this.handleTextChange}
                                value={values.voucher}
                            />
                            <button
                                className="btn btn-primary btn-sm checkout-voucher-btn"
                                type="submit" disabled={formSubmitting.voucher}
                            >
                                <i className="icon-arrow-right"/>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const FormikApp = withFormik(formVoucherConfig)(VoucherInput);

const mapStateToProps = state => {
    return {
        formErrors: state.formErrors,
        formSubmitting: state.formSubmitting,
        garage: state.garage,
        voucher: state.voucher
    };
};

export default connect(
    mapStateToProps,
    {
        formSubmittingSignUp,
        formErrorsVoucherRemove,
        voucherCheck
    }
)(translate('translations')(FormikApp));
