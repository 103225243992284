import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import {
    BOOKING_SERVICE_FINISH,
    BOOKING_SERVICE_PAGE,
    BOOKING_SERVICE_SEND,
    bookServicePage,
    bookServiceSend
} from '../../actions/booking.actions';
import { formSubmittingBooking, formSubmittingSignUp } from '../../actions/formSubmitting.actions';
import history from '../../../routes/history';
import { setError } from '../../actions/error.actions';
import store from '../../store';
import pages from '../../../app/consts/routes';
import { ERROR_BOOKING_SERVICE_ID } from "../../consts/error.constants";
import {addFlashMessage} from "../../actions/flashMessage.actions";
import {FLASH_MESSAGE_ERROR} from "../../consts/flashMessage.constants";

export const BOOKING_URL = 'save-offer';

export const bookingMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case BOOKING_SERVICE_FINISH:
                dispatch(
                    bookServiceSend({
                        bookingDates: store.getState().termin,
                        repairShopToken: store.getState().garage.id,
                        voucher: store.getState().voucher ? store.getState().voucher.name : null,
                    })
                );
                break;
            case BOOKING_SERVICE_SEND:
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: BOOKING_URL,
                        signature: BOOKING_SERVICE_SEND
                    })
                );
                break;

            case `${BOOKING_SERVICE_SEND} ${API_SUCCESS}`:
                dispatch(bookServicePage());
                dispatch(setError(ERROR_BOOKING_SERVICE_ID));
                break;

            case `${BOOKING_SERVICE_SEND} ${API_ERROR}`:
                dispatch(formSubmittingSignUp(false));
                dispatch(formSubmittingBooking(false));
                dispatch(setError(ERROR_BOOKING_SERVICE_ID, action.payload.error.message));
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));
                break;

            case BOOKING_SERVICE_PAGE:
                history.push(pages.confirm);
                break;

            default:
                break;
        }
    };
};
