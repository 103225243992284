export const FORM_VEHICLE_TYPE_FORM_STEP_1 = 'FORM_VEHICLE_TYPE_FORM_STEP_1';
export const FORM_VEHICLE_TYPE_FORM_STEP_2 = 'FORM_VEHICLE_TYPE_FORM_STEP_2';

export const FORM_VEHICLE_TYPE_LICENCE_PLATE = 'FORM_VEHICLE_TYPE_LICENCE_PLATE';
export const FORM_VEHICLE_TYPE_MANUAL_1 = 'FORM_VEHICLE_TYPE_MANUAL_1';
export const FORM_VEHICLE_TYPE_MANUAL_2 = 'FORM_VEHICLE_TYPE_MANUAL_2';
export const FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE = 'FORM_VEHICLE_TYPE_MULTIPLE_VEHICLE';
export const FORM_VEHICLE_TYPE_DATA_COMPLETE = 'FORM_VEHICLE_TYPE_DATA_COMPLETE';
export const FORM_VEHICLE_TYPE_SOURCED_COMPLETE = 'FORM_VEHICLE_TYPE_SOURCED_COMPLETE';

export const ALLOWED_IN_CAR_STEP_POP = [
    FORM_VEHICLE_TYPE_LICENCE_PLATE,
    FORM_VEHICLE_TYPE_MANUAL_1,
    FORM_VEHICLE_TYPE_MANUAL_2,
    FORM_VEHICLE_TYPE_DATA_COMPLETE,
    FORM_VEHICLE_TYPE_SOURCED_COMPLETE
];