//@flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import './ConfirmPageContainer.scss';
import {
    getSelectedLabourServicesOfficialDiff,
    getSelectedOfficialServiceDefault
} from '../../store/selectors/services';
import {changeStepData} from "../../store/actions/step.actions";
import {ConfirmPageStep} from "../../routes/middleware/confirm-page/ConfirmPageStep";
import {HasOfferToken} from "../../routes/middleware/HasOfferToken";
import {storeReset} from "../../store/actions/store.actions";
import {HideFooter} from "../../routes/middleware/HideFooter";
import { removeSessionStorageItem } from "../../utils/storage";
import {STORAGE_OFFER_TOKEN} from "../../app/consts/storage.consts";
// import discount from "../../utils/discount";
import {applyGarageDiscount} from "../../store/selectors/garage";
import {voucherHideElements} from "../../utils/voucherHideElements";

class ConfirmPageContainer extends Component<State> {

    constructor(props) {
        super(props);

        this.state = {
            car: props.car,
            garage: props.garage,
            garageDiscount: props.garageDiscount,
            selectedServices: [...props.selectedOfficialService, ...props.selectedServices]
        }
    }

    componentDidMount() {
        document.title = this.props.t('titles.booking');
        this.props.changeStepData(4);
        this.props.storeReset();
    }

    componentWillUnmount() {
        removeSessionStorageItem(STORAGE_OFFER_TOKEN);
    }

    printSelectedServices = () => {
        const {selectedServices} = this.state;

        return selectedServices.map(item => {
            return (
                <div className="confirm-page__overview-item" key={item.itemMpId}>
                    <div>{item.nameAlternative ? item.nameAlternative : item.name}</div>
                    <div className="confirm-page__overview-item__siblings">
                        {item.hasOwnProperty('siblings') &&
                        item.siblings.map(sibling => {
                            return <div key={sibling.id}>- {sibling.name}</div>;
                        })}
                    </div>
                </div>
            );
        });
    };

    renderTotalPrice = () => {
        const {garage} = this.state;
        const {t} = this.props;

        if(voucherHideElements()){
            return null;
        }

        return (
            <React.Fragment>
                <hr className="confirm-page__dotted-separator"/>
                <div className="row confirm-page__overview-item confirm-page__overview-item--total">
                    <div className="col-md-4 col-sm-4 col-xs-3">{t('global.tax')}</div>
                    <div className="col-md-4 col-sm-4 col-xs-5 text-right" id="confirm-page-tax">
                        {t('global.currency')} {garage.tax}
                    </div>
                </div>
                <div className="row confirm-page__overview-item confirm-page__overview-item--total">
                    <div className="col-md-4 col-sm-4 col-xs-3">{t('global.total_with_vat')}</div>
                    {garage.discount ?
                        <div className="booking-total-price">
                            <div className="booking-total-price__has-discount">{t('global.currency')} {garage.price}</div>
                            <span className="booking-total-price__discount">
                                                {t('global.currency')} {garage.totalPrice}
                                <span className="booking-total-price__discount--discount-value"> ({garage.discountValue})</span>
                                        </span>
                        </div>
                        :
                        <div className="col-md-4 col-sm-4 col-xs-5 text-right" id="confirm-page-total">
                            {t('global.currency')} {garage.price}
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    };

    render() {
        const {car} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <div className="confirm-page">
                    <div className="confirm-page__section confirm-page__section--congrats">
                        <div className="text-center">
                            <img
                                className="confirm-page__section__img"
                                src={`${process.env.REACT_APP_BASE_URL}/web/images/check-small.gif`}
                                alt="Success icon"
                            />
                        </div>
                        <h2 className="text-center">{t('pages.confirm.title')}</h2>
                        <div className="mt-20">
                            {t('pages.confirm.description')}
                        </div>
                        <div className="mt-20">
                            <div className="text-center">{t('pages.confirm.order_number')}</div>
                            <div className="text-center">
                                <h4>
                                    <strong>#{car.code}</strong>
                                </h4>
                            </div>
                        </div>
                        <div className="mt-30">{t('pages.confirm.carhelper_team')}</div>
                    </div>
                    <div className="confirm-page__section">
                        <h5 className="mb-30">{t('pages.confirm.order_overview')}</h5>

                        {this.printSelectedServices()}

                        {this.renderTotalPrice()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        garage: applyGarageDiscount(state),
        selectedOfficialService: getSelectedOfficialServiceDefault(state),
        selectedServices: getSelectedLabourServicesOfficialDiff(state),
        garageDiscount: state.garageDiscount
    };
};

export default connect(
    mapStateToProps,
    {
        storeReset,
        changeStepData
    }
)(ConfirmPageStep(HasOfferToken(HideFooter(translate('translations')(ConfirmPageContainer)))));
