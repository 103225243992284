//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import './BookingContainer.scss';
import FormLogin from '../../components/forms/FormLogin/FormLogin';
import FormRegister from '../../components/forms/FormRegister/FormRegister';
import FormFinishBooking from '../../components/forms/FormFinishBooking/FormFinishBooking';
import { bookServiceFinish } from '../../store/actions/booking.actions';

const SIGN_UP_FORM = 'sign-up';
const LOGIN_FORM = 'login';

class BookingContainer extends Component<State> {
    constructor(props) {
        super(props);

        this.state = {
            form: SIGN_UP_FORM,
            agbAgreement: false,
            scrollToForm: false
        };
    }

    componentDidMount() {
        if (this.props.user.logged === true) {
            this.setState({
                form: LOGIN_FORM
            });
        }

        this.replaceCheckoutBtn();
    }

    componentDidUpdate(prevProps, prevState) {
        const { form } = this.state;
        const { user, bookServiceFinish, formSubmitting } = this.props;

        if (prevProps.user.logged !== user.logged && user.logged === true) {

            if(formSubmitting.signUp === true){
                bookServiceFinish();
            }
            else if(user.social_token){
                this.changeForm(LOGIN_FORM);
            }

        }
        if(prevState.form !== form || (prevProps.user.logged !== user.logged && form !== SIGN_UP_FORM)){
            this.replaceCheckoutBtn();
        }
    }

    changeForm = form => {
        this.setState({
            form,
            scrollToForm: true
        });
    };

    replaceCheckoutBtn = () => {
        try{
            let firstSection = document.querySelector('#first-checkout-section');
            let checkoutBtn = document.querySelector('#first-checkout-section > .checkout');
            if(checkoutBtn){
                checkoutBtn.remove();
            }
            firstSection.appendChild(document.querySelector('.checkout'));
        }
        catch (e) {}
    };

    updateAgbAgreement = checked => {
        this.setState({
            agbAgreement: checked
        });
    };

    renderForm = () => {
        const { form, agbAgreement } = this.state;
        const { user, showDetails } = this.props;

        if (form === SIGN_UP_FORM) {
            return (
                <FormRegister
                    changeForm={this.changeForm}
                    agbAgreement={agbAgreement}
                    updateAgbAgreement={this.updateAgbAgreement}
                    scrollToForm={this.state.scrollToForm}
                    showDetails={showDetails}
                />
            );
        } else if (form === LOGIN_FORM) {
            if (user.logged) {
                return (
                    <FormFinishBooking
                        agbAgreement={agbAgreement}
                        updateAgbAgreement={this.updateAgbAgreement}
                        scrollToForm={this.state.scrollToForm}
                        showDetails={showDetails}
                    />
                );
            } else {
                return (
                    <FormLogin
                        changeForm={this.changeForm}
                        agbAgreement={agbAgreement}
                        updateAgbAgreement={this.updateAgbAgreement}
                        scrollToForm={this.state.scrollToForm}
                        showDetails={showDetails}
                    />
                );
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="auth">{this.renderForm()}</div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        formSubmitting: state.formSubmitting
    };
};

export default connect(
    mapStateToProps,
    {
        bookServiceFinish
    }
)(translate('translations')(BookingContainer));
