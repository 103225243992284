import { ON_ZIP_RESET, ON_ZIP_SELECTED, zipSet } from "../../actions/zip.actions";
import { carUpdateField } from "../../actions/car.actions";

export const zipMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case ON_ZIP_SELECTED:
                dispatch(zipSet(action.payload.zip));
                dispatch(carUpdateField('zip_id', action.payload.zip.id));
                // dispatch(filterUpdateZip(action.payload.zip));
                break;

            case ON_ZIP_RESET:
                // dispatch(zipReset());
                break;

            default:
                break;
        }
    };
};

export default zipMiddleware;
