import {OFFER_GET, OFFER_GET_SIGNATURE} from '../../actions/offer.actions';
import {carUpdateObject} from '../../actions/car.actions';
import {API_ERROR, API_SUCCESS, apiRequest} from '../../actions/api.actions';
import {setStepDataActiveOne} from "../../actions/step.actions";
import {initRoute} from "../../actions/route.actions";
import {LOADER_INIT_OFFER} from "../../consts/loader.constants";
import {setLoader} from "../../actions/loader.actions";
import {setZipData} from "../../actions/zipData.actions";
import {addFlashMessage} from "../../actions/flashMessage.actions";
import {FLASH_MESSAGE_ERROR} from "../../consts/flashMessage.constants";
import {zipSet} from "../../actions/zip.actions";
import {carStepSet} from '../../actions/carStep.actions';
import {FORM_VEHICLE_TYPE_LICENCE_PLATE, FORM_VEHICLE_TYPE_MANUAL_1} from '../../../app/consts/vehicle';
import {userSet} from "../../actions/user.actions";
import {setLocalStorageItem} from "../../../utils/storage";
import {STORAGE_AXA_TOKEN} from "../../../app/consts/storage.consts";

export const offerMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);
        switch (action.type) {
            case OFFER_GET:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: {},
                        url: 'get-offer',
                        signature: OFFER_GET_SIGNATURE
                    })
                );
                break;

            case `${OFFER_GET_SIGNATURE} ${API_SUCCESS}`:
                const result = action.payload.data;

                dispatch(carUpdateObject(result));
                dispatch(setZipData(result.zipData));
                dispatch(zipSet(result.zipData.find(zip => zip.id === result.zip_id)));
                // dispatch(onZipSelected(result.zipData.find(zip => zip.id === result.zip_id)))
                dispatch(setStepDataActiveOne({step: result.step_current}));
                dispatch(initRoute());


                if (result.make_id === 0 && result.range_id === 0 && result.used_licence_plate === false && result.step_current === 0 && result.zip_id === null) {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_LICENCE_PLATE));
                } else {
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1));
                }

                if (action.payload.data.user) {
                    dispatch(userSet({user: {...action.payload.data.user, logged: false}}));
                    setLocalStorageItem(STORAGE_AXA_TOKEN, action.payload.data.user.token);
                }

                break;

            case `${OFFER_GET_SIGNATURE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_INIT_OFFER, true));
                if (action.payload.error.message) {
                    dispatch(addFlashMessage(
                        action.payload.error.message,
                        FLASH_MESSAGE_ERROR
                    ));
                }

                break;

            default:
                break;
        }
    };
};

export default {
    offerMiddleware
};
