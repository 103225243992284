export const CAR = '[Car]';

export const CAR_DATA_CHANGE = `${CAR} DATA_CHANGE`;
export const CAR_UPDATE_FIELD = `${CAR} UPDATE_FIELD`;
export const CAR_UPDATE_OBJECT = `${CAR} UPDATE_OBJECT`;
export const CAR_FETCH_SERVICES = `${CAR} GET_SERVICES`;
export const CAR_FETCH_DATA = `${CAR} FETCH_DATA`;
export const CAR_RESET_FIELDS_BELLOW = `${CAR} RESET_FIELDS_BELLOW`;
export const CAR_IFRAME_SUBMIT = `${CAR} IFRAME_SUBMIT`;
export const CAR_FETCH_LICENCE_PLATE = `${CAR} FETCH_LICENCE_PLATE`;
export const CAR_UPDATE_STEP = `${CAR} UPDATE_STEP`;
export const CAR_MULTIPLE_VEHICLE_CHOOSE = `${CAR} MULTIPLE_VEHICLE_CHOOSE`;

export const onCarIframeSubmit = car => ({
    type: CAR_IFRAME_SUBMIT,
    payload: {
        car
    }
});

export const carDataChange = (name, value, shouldCallAPI) => ({
    type: CAR_DATA_CHANGE,
    payload: {
        name,
        value,
        shouldCallAPI
    }
});

export const carUpdateField = (name, value) => ({
    type: CAR_UPDATE_FIELD,
    payload: {
        name,
        value
    }
});

export const carUpdateObject = (car) => ({
    type: CAR_UPDATE_OBJECT,
    payload: {
        car
    }
});

export const carFetchServices = (car) => ({
    type: CAR_FETCH_SERVICES,
    payload: {
        car
    }
});

export const fetchCarData = () => ({
    type: CAR_FETCH_DATA
});

export const carResetFieldsBellow = name => ({
    type: CAR_RESET_FIELDS_BELLOW,
    payload: {
        name
    }
});

export const carLicencePlate = car => ({
    type: CAR_FETCH_LICENCE_PLATE,
    payload: {
        car
    }
});

export const carMultipleVehicleChoose = car => ({
    type: CAR_MULTIPLE_VEHICLE_CHOOSE,
    payload: {
        car
    }
});