export function appendQueryParam(name, value, href){

    if (!href){
        href = window.location.href;
    }

    let url = new URL(href);
    let params = new URLSearchParams(url.search.slice(1));

    params.append(name, value);

    url.search = params;

    return url;
}

export default appendQueryParam;