import {BALOISE_LINK, CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL} from '../consts/global';
import {
    GLOBAL_BALOISE_LINK_SET,
    SELECT_DROPDOWN_OPENED,
    GLOBAL_CHECK_ANIMATION_SET,
    GLOBAL_SERVICE_FILTER_SET_FOCUS,
    GLOBAL_UPTO_USER_FIRST_NAME
} from '../actions/global.actions';

import global from '../models/global';

export default function(state = global, action) {
    switch (action.type) {

        case GLOBAL_BALOISE_LINK_SET:
            return {
                ...state,
                [BALOISE_LINK]: action.payload.link
            };

        case SELECT_DROPDOWN_OPENED:
            return {
                ...state,
                selectDropdownOpened: action.payload.isOpened
            };

        case GLOBAL_CHECK_ANIMATION_SET:
            return {
                ...state,
                [CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL]: {
                    id: action.payload.id,
                    isVisible: action.payload.isVisible
                }
            };

        case GLOBAL_SERVICE_FILTER_SET_FOCUS:
            return {
                ...state,
                serviceFilterFocused: action.payload.isFocused
            };

        case GLOBAL_UPTO_USER_FIRST_NAME:
            return {
                ...state,
                upto_user_first_name: action.payload.firstName
            };

        default:
            return state;
    }
}
