import car from '../models/car';
import {
    CAR_FETCH_DATA, CAR_RESET_FIELDS_BELLOW,
    CAR_UPDATE_FIELD, CAR_UPDATE_OBJECT
} from '../actions/car.actions';
import { getFieldsToReset } from "../../utils/car";
import { CAR_FORM_FIELDS } from "../consts/car.constants";

export default function(state = car, action) {
    const stateCopy = { ...state };

    switch (action.type) {

        case CAR_FETCH_DATA:
            return {
                ...state
            };

        case CAR_RESET_FIELDS_BELLOW:
            getFieldsToReset(CAR_FORM_FIELDS[action.payload.name], car, (item, value) => {
                stateCopy[item] = value;
            });

            return stateCopy;

        case CAR_UPDATE_FIELD:
            return {
                ...state,
                [action.payload.name] : action.payload.value
            };

        case CAR_UPDATE_OBJECT:
            return {
                ...state,
                ...action.payload.car
            };

        default:
            return state;
    }
}
