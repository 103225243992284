import React, { Component } from 'react';
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import { withFormik } from "formik";
import * as Yup from "yup";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import ReactSVG from "react-svg";
import checkCircle from '../../../assets/images/checkCircle.svg';
import "./DialogResetPasswordView.scss";
import axios from "../../../app/config/axios";

const RESET_PASSWORD_URL = 'reset-password'

class DialogResetPasswordView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            resetPasswordSubmitted: false
        }
    }

    reset = (e) => {
        e.preventDefault();

        const { setTouched, isValid, values, car } = this.props;

        setTouched({
            email: true,
        });

        if(isValid){
            this.setState({
                resetPasswordSubmitting: true,
            })

            axios.post(RESET_PASSWORD_URL, {email: values.email, offer_token: car.token}).then(response => {
                this.setState({
                    resetPasswordSubmitting: false,
                    resetPasswordSubmitted: true
                })
            }).catch(error => {
                this.setState({
                    resetPasswordSubmitting: false,
                    resetPasswordSubmitted: true
                })
            })
        }
    }



    renderBody = () => {
        const { resetPasswordSubmitted, resetPasswordSubmitting } = this.state;
        const { touched, errors, handleBlur, handleChange, t, values } = this.props;

        if (resetPasswordSubmitted) {
            return (<div className="animation-check-wrapper">
                <ReactSVG src={checkCircle} />
                <p>{t('form.resetPassword.success')}</p>
            </div>);
        }

        return (
            <React.Fragment>
                <h5>{t('form.resetPassword.title')}</h5>
                <p>{t('form.resetPassword.description')}</p>
                <form onSubmit={this.reset} noValidate>
                    <Input
                        id="email"
                        type="email"
                        placeholder={t('form.placeholder.email')}
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                    />
                    <Button size="lg" type="primary" disabled={resetPasswordSubmitting}>
                        {t('form.resetPassword.submit')}
                    </Button>
                </form>
            </React.Fragment>
        )
    };

    render() {
        return (
            <div className="modal__body">
                {this.renderBody()}
            </div>
        );
    }
}

const FormikSaveGarageListDialog = withFormik({

    mapPropsToValues(props) {
        return {
            email: '',
        }
    },

    validationSchema: props => {
        const { t } = props;

        return Yup.object().shape({
            email: Yup.string()
                .required()
                .email()
                .label(t('form.label.email'))
        });
    },

    handleSubmit(values, { props }) {
        props.payload.reset(values.email)
    }

})(translate('translations')(DialogResetPasswordView));

const mapStateToProps = (state) => {
    return {
        car: state.car,
    }
};

export default connect(
    mapStateToProps, {

    }
)(translate('translations')(FormikSaveGarageListDialog));
