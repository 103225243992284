import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from 'formik';
import Input from '../../../components/shared/Input/Input';
import {userLogin} from '../../../store/actions/auth.actions';
import {formSubmittingLogin} from '../../../store/actions/formSubmitting.actions';
import InputCheckout from '../../shared/InputCheckout/InputCheckout';
import {setGarageFormIsSubmitted} from '../../../store/actions/formGarage.actions';
import Button from '../../shared/Button/Button';
import {formLoginConfig} from '../../../app/config/yup';
import SocialMediaLogin from "../../shared/SocialMediaLogin/SocialMediaLogin";
import {applyGarageDiscount} from "../../../store/selectors/garage";
import {voucherHideElements} from "../../../utils/voucherHideElements";
import {scroller} from "../../../utils/scroller";
import {DIALOG_RESET_PASSWORD} from "../../../store/consts/dialog.constants";
import Dialog from "../../dialogs/Dialog/Dialog";
import {setDialog} from "../../../store/actions/dialog.actions";

class FormLogin extends Component {

    componentDidMount() {
        this.props.formSubmittingLogin(false);

        if (this.props.scrollToForm) {
            scroller('from', {
                duration: 500,
                smooth: "ease"
            })
        }
    }

    handleTextChange = (e: Event) => {
        const {handleChange} = this.props;
        const {name, value, checked} = e.target;

        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'checkbox') {
                this.setState({
                    [name]: checked
                });
            } else {
                this.setState({
                    [name]: value
                });
            }

            handleChange(e);

            if (e.target.id === 'agb_agreement') {
                this.props.updateAgbAgreement(e.target.checked);
            }
        }
    };

    nonValidElement = (errors) => {
        if (Object.keys(errors).length === 0 && errors.constructor === Object) return 'form';

        return Object.keys(errors)[0];
    };

    submitForm = e => {
        e.preventDefault();
        const {submitForm, setGarageFormIsSubmitted, formGarage} = this.props;

        if (!this.props.isValid) {
            scroller(this.nonValidElement(this.props.errors), {
                duration: 500,
                smooth: "ease"
            })
        }

        setGarageFormIsSubmitted(true);

        if (formGarage.isDatePickerValid) {
            submitForm();
        }
    };

    render() {
        const {
            t,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            values,
            garage,
            formSubmitting,
            setDialog,
        } = this.props;

        return (
            <React.Fragment>
                <div className="header-auth-text">
                    {/*<Trans i18nKey="pages.garage.auth.login">*/}
                    {/*    Bitte logge dich ein um deine Buchung abzuschliessen, oder*/}
                    {/*    <Button extraClass="btn--link" onClick={() => this.props.changeForm('sign-up')}>*/}
                    {/*        registriere dich*/}
                    {/*    </Button>.*/}
                    {/*</Trans>*/}

                    {t('pages.garage.please_login')} {' '}
                    <Button extraClass="btn--link" onClick={() => this.props.changeForm('sign-up')}>
                        {t('pages.garage.link_sign_up')}
                    </Button>{' '}
                    {t('pages.garage.register_to_finalize_booking')}
                </div>

                <SocialMediaLogin t={t} />

                <form onSubmit={handleSubmit} className="from" id="form">
                    <div className="row">
                        <div className="col-md-4">
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && errors.email}
                            />
                        </div>
                        <div className="col-md-4">
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.password')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.password && errors.password}
                            />
                            <a href="javascript:void(0)" onClick={() => setDialog(DIALOG_RESET_PASSWORD, true)}>{t('form.resetPassword.link')}</a>
                        </div>
                    </div>
                    <InputCheckout
                        id="agb_agreement"
                        name="agb_agreement"
                        onChange={this.handleTextChange}
                        onBlur={handleBlur}
                        checked={this.props.agbAgreement}
                        label={
                            <span className="label-has-link">{t('form.label.agb_agreement')} <a target="_blank" rel="noopener noreferrer"
                                                                     href={window.settings.termsAndConditionsLink}>{t('form.label.terms_and_condition')}</a></span>
                        }
                        error={touched.agb_agreement && errors.agb_agreement}
                        inputControlClass="mt-30"
                    />
                    <div className="row checkout">
                        <button
                            onClick={this.submitForm}
                            className="btn btn-primary btn-md"
                            type="submit"
                            disabled={formSubmitting.login}
                        >
                            {
                                !voucherHideElements() ?
                                t('pages.garage.btn_checkout_for', {price: `${t('global.currency')} ${garage.totalPrice}`})
                                :
                                t('pages.garage.btn_checkout')
                            }
                        </button>
                    </div>
                </form>

                <Dialog
                    type={DIALOG_RESET_PASSWORD}
                    payload={{}}
                />

            </React.Fragment>
        );
    }
}

const FormikApp = withFormik(formLoginConfig)(FormLogin);

const mapStateToProps = state => {
    return {
        garage: applyGarageDiscount(state),
        formSubmitting: state.formSubmitting,
        formGarage: state.formGarage,
        global: state.global,
        garageDiscount: state.garageDiscount,
    };
};

export default connect(
    mapStateToProps,
    {
        userLogin,
        formSubmittingLogin,
        setGarageFormIsSubmitted,
        setDialog
    }
)(translate('translations')(FormikApp));
