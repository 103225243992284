import React, { Component } from 'react';
import {translate} from "react-i18next";
import OfficialServiceHeader from "./Header";
import OfficialServiceBox from "./Box";
import "./index.scss"
import connect from "react-redux/es/connect/connect";

class OfficialService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
            autoSelect: props.autoSelect
        };
    }

    componentDidMount() {
        this.preselectOfficialService(this.props.list);
    }

    preselectOfficialService = (list) => {
        if (list && list.length && this.state.autoSelect === true) {
            this.handleOfficialServiceToggle();
        }
    };

    componentWillReceiveProps(nextProps, nextContent) {
        this.preselectOfficialService(nextProps);
    }

    handleOfficialServiceToggle = () => {
        this.setState({
            checked: !this.state.checked,
            autoSelect: false
        }, this.props.onOfficialServiceToggle(!this.state.checked));
    };

    render() {
        const { t, make, range, list, predefinedServices } = this.props;

        if (!list || !make.name || !range.name)
            return null;

        return (
            <div className="official-service-container clearfix">
                <OfficialServiceHeader
                    title={t('global.official_service.title')}
                    description={t('global.official_service.description', { carBrand: make && make.name })}
                />
                <OfficialServiceBox
                    isOpened={predefinedServices.labours.length === 0}
                    checked={this.state.checked}
                    make={make && make.name}
                    range={range && range.name}
                    name={`${make.name} ${range.name} ${t('global.service')}`}
                    list={list}
                    onToggle={this.handleOfficialServiceToggle}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        predefinedServices: state.predefinedServices
    }
};

export default connect(mapStateToProps, {})(translate('translations')(OfficialService));
