import {GARAGE_LIST_RESET, GARAGE_LIST_SET} from '../actions/garageList.actions';
import garageList from '../models/garageList';

export default function(state = garageList, action) {
    switch (action.type) {
        case GARAGE_LIST_SET:
            return action.payload.list;

        case GARAGE_LIST_RESET:
            return garageList;

        default:
            return state;
    }
}
