import pages from "../app/consts/routes";
import HomeContainer from "../views/HomeContainer/HomeContainer";
import ServicesContainer from "../views/ServicesContainer";
import GarageContainer from "../views/GarageContainer/GarageContainer";
import ConfirmPageContainer from "../views/ConfirmPageContainer/ConfirmPageContainer";
import {isFormOnly} from "../utils/form";

// <!-- TODO -->
// <!-- Continue when user reset password -->
// <!-- TODO -->

const routes = [
    {
        name: 'Home',
        path: `${pages.home}`,
        component: HomeContainer,
        exact: true,
    },
    {
        name: 'Services',
        path: `${pages.services}`,
        component: ServicesContainer,
        exact: true,
    },
    {
        name: 'Garage Item',
        path: `${pages.garage}:slug`,
        component: GarageContainer,
        exact: true,
    },
    {
        name: 'Booked',
        path: `${pages.confirm}`,
        component: ConfirmPageContainer,
        exact: true,
    }
];

if(isFormOnly()){
    routes.push({
        name: 'Default',
        path: '*',
        component: HomeContainer,
        exact: true,
    });
}

export default routes;
