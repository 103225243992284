export const LICENCE_PLATE_ATTEMPTS = '[LP Attempts]';

export const LICENCE_PLATE_ATTEMPTS_SET = `${LICENCE_PLATE_ATTEMPTS} SET`;

export const setLicencePlateAttempts = attempt => ({
    type: LICENCE_PLATE_ATTEMPTS_SET,
    payload: {
        attempt
    }
});
