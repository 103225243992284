import formErrors from '../models/formErrors';
import {
    FORM_ERRORS_SIGN_UP_SET,
    FORM_ERRORS_SIGN_UP_REMOVE,
    FORM_ERRORS_VOUCHER_REMOVE, FORM_ERRORS_VOUCHER_SET
} from '../actions/formErrors.actions';

export default function(state = formErrors, action) {
    switch (action.type) {
        case FORM_ERRORS_SIGN_UP_SET:
            return {
                ...state,
                signUp: action.payload.errors
            };

        case FORM_ERRORS_SIGN_UP_REMOVE:
            return {
                ...state,
                signUp: formErrors.signUp
            };

        case FORM_ERRORS_VOUCHER_SET:
            return {
                ...state,
                voucher: action.payload.errors
            };

        case FORM_ERRORS_VOUCHER_REMOVE:
            return {
                ...state,
                voucher: formErrors.voucher
            };

        default:
            return state;
    }
}
