import {
    FILTER_CHANGE,
    FILTER_REMOVE,
    FILTER_REPLACE_BRAND_NAME,
    FILTER_SET,
    FILTER_STATUS_CHANGE,
    FILTER_UNCHECK_ALL,
    FILTER_UPDATE_DISTANCE,
    FILTER_UPDATE_ZIP,
    FILTER_UPDATE_ZIP_STATUS,
    FILTER_RESET_ZIP,
    FILTER_UPDATE_ZIP_WITHOUT_STATUS
} from "../actions/filter.actions";

import filterInitState from '../models/filter';
import { FILTER_MODIFIED, FILTER_MODIFIED_AND_SAVED, FILTER_NOT_MODIFIED } from "../consts/filter.constants";

export default function(state = filterInitState, action) {
    const stateCopy = {...state};

    switch (action.type) {
        case FILTER_SET:
            return {
                ...action.payload.filters
            };

        case FILTER_REPLACE_BRAND_NAME:
            return {
                ...state,
                ["officialGarages"]: {
                    ...stateCopy["officialGarages"],
                    description: stateCopy["officialGarages"].description.replace(
                        '{carBrand}', action.payload.carBrand
                    )
                }
            };

        case FILTER_CHANGE:
            return {
                ...state,
                [action.payload.filter]: {
                    ...stateCopy[action.payload.filter],
                    data: action.payload.data,
                    status: (
                        action.payload.data.min === null ||
                        (action.payload.data.zipData && action.payload.data.zipData.length === 0) ||
                        action.payload.data.checked === false
                    )
                    ? FILTER_NOT_MODIFIED
                    : FILTER_MODIFIED
                }
            };

        case FILTER_UPDATE_ZIP_STATUS:
            return {
                ...state,
                zipId: {
                    ...state.zipId,
                    status: FILTER_MODIFIED
                }
            };

        case FILTER_UPDATE_ZIP:

            return {
                ...state,
                zipId: {
                    ...state.zipId,
                    status: FILTER_MODIFIED,
                    data: {
                        zipData: [action.payload.zip]
                    }
                }
            };

        case FILTER_UPDATE_ZIP_WITHOUT_STATUS:
            return {
              ...state,
              zipId: {
                    ...state.zipId,
                    status: FILTER_NOT_MODIFIED,
                    data: {
                        zipData: [action.payload.zip]
                    }
              }
            };

        case FILTER_RESET_ZIP:

            return {
                ...state,
                zipId: {
                    ...state.zipId,
                    status: FILTER_NOT_MODIFIED,
                    data: {
                        zipData: []
                    }
                }
            };

        case FILTER_UPDATE_DISTANCE:
            return {
                ...state,
                distance: {
                    ...state.distance,
                    from: action.payload.from,
                    to: action.payload.to,
                    data: {
                        min: action.payload.min,
                        max: action.payload.max
                    }
                }
            }

        case FILTER_STATUS_CHANGE:
            let filtersToUpdate = {};
            for (let key in stateCopy) {
                if (stateCopy[key].status === FILTER_MODIFIED) {
                    filtersToUpdate[key] = stateCopy[key];
                    filtersToUpdate[key].status = FILTER_MODIFIED_AND_SAVED;
                }
            }

            if (Object.keys(filtersToUpdate).length === 0)
                return { ...state };

            return {
                ...state,
                ...filtersToUpdate
            };

        case FILTER_REMOVE:
            return {
                ...state,
                [action.payload.filter]: { ...filterInitState[action.payload.filter] }
            };

        // case FILTER_UPDATE_PRICE:
        //     if (action.payload.garageList.length < 2) {
        //         return {
        //             ...state,
        //             price: {
        //                 ...state.price,
        //                 from: 230,
        //                 to: 360,
        //                 data: {
        //                     min: 230,
        //                     max: 360
        //                 }
        //             }
        //         };
        //     } else {
        //         return {
        //             ...state,
        //             price: {
        //                 ...state.price,
        //                 from: Math.round(action.payload.garageList[0].price),
        //                 to: Math.round(action.payload.garageList[action.payload.garageList.length - 1].price),
        //                 data: {
        //                     min: (state.price.status === 0)
        //                             ? Math.round(action.payload.garageList[0].price)
        //                             : state.price.data.min,
        //                     max: (state.price.status === 0)
        //                             ? Math.round(action.payload.garageList[action.payload.garageList.length - 1].price)
        //                             : state.price.data.max
        //                 }
        //             }
        //         };
        //     }


        case FILTER_UNCHECK_ALL:
            return {
                ...filterInitState,
                zipId: {
                    ...state.zipId,
                    status: 0,
                    data: {
                        zipData: []
                    }
                }
                // price: (state.price.status === 0) ? {
                //     ...filterInitState.price
                // } : {
                //     ...state.price,
                //     status: 0,
                //     data: {
                //         min: state.price.from,
                //         max: state.price.to
                //     }
                // }
            };

        default:
            return state;
    }
}
