import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import { clearZipData, setZipData, ZIP_DATA_FETCH } from '../../actions/zipData.actions';

export const ZIP_URL = 'zip';

export const zipDataMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case ZIP_DATA_FETCH:
                dispatch(
                    apiRequest({
                        method: 'GET',
                        body: {},
                        url: `${ZIP_URL}/${action.payload.zipId}`,
                        signature: ZIP_DATA_FETCH
                    })
                );
                break;

            case `${ZIP_DATA_FETCH} ${API_SUCCESS}`:
                dispatch(setZipData(action.payload.data));
                break;

            case `${ZIP_DATA_FETCH} ${API_ERROR}`:
                dispatch(clearZipData());
                break;

            default:
                break;
        }
    };
};
