//@flow
import React, { Component } from 'react';
import './FlashMessageList.scss';
import FlashMessage from '../../components/shared/FlashMessage/FlashMessage';
import { connect } from 'react-redux';
import { getFlashMessages, removeFlashMessage } from '../../store/actions/flashMessage.actions';
import { translate } from 'react-i18next';

type Props = {};

type State = {};

class FlashMessageList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.props.getFlashMessages();
    }

    handleMessageRemoval = itemId => {
        this.props.removeFlashMessage(itemId);
    };

    render() {
        const { flashMessages } = this.props;

        if(flashMessages.length === 0){
            return null;
        }

        return (
            <div className="flash-messages">
                {flashMessages.length > 0 &&
                    flashMessages.map(message => (
                        <FlashMessage
                            key={message.id}
                            message={message}
                            onRemove={this.handleMessageRemoval}
                        />
                    ))}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        flashMessages: state.flashMessages
    };
};

export default connect(
    mapStateToProps,
    {
        getFlashMessages,
        removeFlashMessage
    }
)(translate('translations')(FlashMessageList));
