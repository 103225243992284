import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import { USER_LOGIN, USER_SIGN_UP } from '../../actions/auth.actions';
import {
    USER_FETCH,
    USER_LOGOUT,
    USER_UPDATE,
    userFetch,
    userRemove,
    userSet
} from '../../actions/user.actions';
import { STORAGE_USER_TOKEN } from '../../../app/consts/storage.consts';
import {
    removeLocalStorageItem,
    setLocalStorageItem,
} from '../../../utils/storage';
import {
    formSubmittingBooking,
    formSubmittingLogin,
    formSubmittingSignUp
} from '../../actions/formSubmitting.actions';
import { formErrorsSignUpSet } from '../../actions/formErrors.actions';
import { HTTP_STATUS_UNPROCESSABLE_ENTITY } from '../../consts/api.constants';
import { bookServiceSend } from '../../actions/booking.actions';
import store from '../../store';
import { FLASH_MESSAGE_ERROR } from "../../consts/flashMessage.constants";
import { addFlashMessage } from "../../actions/flashMessage.actions";

const USER_SIGN_UP_URL = 'register';
const USER_LOGIN_URL = 'login';
const USER_LOGOUT_URL = 'logout';
const USER_FETCH_URL = 'user';
const USER_UPDATE_URL = 'user-update';

export const authMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case USER_SIGN_UP:
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: USER_SIGN_UP_URL,
                        signature: USER_SIGN_UP
                    })
                );
                break;

            case `${USER_SIGN_UP} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_USER_TOKEN, action.payload.data.token);
                dispatch(userFetch());

                break;

            case `${USER_SIGN_UP} ${API_ERROR}`:
                dispatch(formSubmittingSignUp(false));

                if (action.payload.status === HTTP_STATUS_UNPROCESSABLE_ENTITY) {
                    dispatch(formErrorsSignUpSet(action.payload.error.errors));
                } else {
                    dispatch(addFlashMessage(
                        action.payload.error.message,
                        FLASH_MESSAGE_ERROR
                    ));
                }

                break;

            case USER_LOGIN:
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: USER_LOGIN_URL,
                        signature: USER_LOGIN
                    })
                );
                break;

            case `${USER_LOGIN} ${API_SUCCESS}`:
                setLocalStorageItem(STORAGE_USER_TOKEN, action.payload.data.token);
                dispatch(userFetch());

                break;

            case `${USER_LOGIN} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false));
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));

                break;

            case USER_FETCH:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: USER_FETCH_URL,
                        signature: USER_FETCH
                    })
                );
                break;

            case `${USER_FETCH} ${API_SUCCESS}`:
                dispatch(userSet(action.payload.data));

                break;

            case `${USER_FETCH} ${API_ERROR}`:
                dispatch(formSubmittingLogin(false));
                dispatch(formSubmittingSignUp(false));
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));

                break;

            case USER_UPDATE:
                dispatch(
                    apiRequest({
                        body: action.payload.data,
                        method: 'POST',
                        url: USER_UPDATE_URL,
                        signature: USER_UPDATE
                    })
                );
                break;

            case `${USER_UPDATE} ${API_SUCCESS}`:
                dispatch(
                    bookServiceSend({
                        bookingDates: store.getState().termin,
                        repairShopToken: store.getState().garage.id,
                        voucher: store.getState().voucher ? store.getState().voucher.name : null,
                    })
                );
                break;

            case `${USER_UPDATE} ${API_ERROR}`:
                dispatch(formSubmittingBooking(false));
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));
                break;

            case USER_LOGOUT:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        url: USER_LOGOUT_URL,
                        signature: USER_LOGOUT
                    })
                );

                break;

            case `${USER_LOGOUT} ${API_SUCCESS}`:
                removeLocalStorageItem(STORAGE_USER_TOKEN);
                dispatch(userRemove());

                break;

            case `${USER_LOGOUT} ${API_ERROR}`:
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));

                break;

            default:
                break;
        }
    };
};

export default {
    authMiddleware
};
