import Yup from "./yup";

export default {
    mapPropsToValues: props => {
        return {
            email: '',
            password: '',
            agb_agreement: props.agbAgreement
        };
    },
    validationSchema: ({ t }) => {
        return Yup.object().shape({
            email: Yup.string()
                .email()
                .required()
                .label(t('validations.label.email')),
            password: Yup.string()
                .required()
                .label(t('validations.label.password')),
            agb_agreement: Yup.bool()
                .test('consent', t('validations.default.boolean.required'), value => value === true)
                .label(t('validations.label.agb_agreement'))
        });
    },
    handleSubmit(values, formikBag) {
        const { userLogin, formSubmittingLogin } = formikBag.props;

        formSubmittingLogin(true);
        userLogin(values);
    }
}
