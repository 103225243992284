import carReducer from "./car.reducer";
import stepReducer from "./step.reducer";
import zipDataReducer from "./zipData.reducer";
import loaderReducer from "./loader.reducer";
import { isFormOnly } from "../../utils/form";
import flashMessageReducer from "./flashMessage.reducer";
import errorReducer from "./error.reducer";
import garageReducer from "./garage.reducer";
import garageListReducer from "./garageList.reducer";
import userReducer from "./user.reducer";
import formSubmittingReducer from "./formSubmitting.reducer";
import formErrorsReducer from "./formErrors.reducer";
import terminReducer from "./terminPicker.reducer";
import dialogReducer from "./dialog.reducer";
import formGarageReducer from "./formGarage.reducer";
import globalReducer from "./global.reducer";
import servicesReducer from "./services.reducer";
import testimonialsReducer from "./testimonials.reducer";
import predefinedServicesReducer from "./predefinedServices.reducer";
import predefinedGarage from "./predefinedGarage.reducer";
import predefinedGarageToken from "./predefinedGarageToken.reducer";
import cantonListReducer from "./cantonList.reducer";
import carStep from "./carStep.reducer";
import licencePlateAttemptsReducer from "./licencePlateAttempts.reducer";
import garageDiscount from "./garageDiscount.reducer";
import filterReducer from "./filter.reducer";
import zipReducer from "./zip.reducer";
import voucher from "./voucher.reducer";
import sortReducer from "./sort.reducer";

const formReducers = {
    car: carReducer,
    step: stepReducer,
    zipData: zipDataReducer,
    global: globalReducer,
    loader: loaderReducer,
    zip: zipReducer,
    cantonList: cantonListReducer,
    predefinedGarageToken: predefinedGarageToken,
    carStep: carStep,
    licencePlateAttempts: licencePlateAttemptsReducer,
};

const appReducers = !isFormOnly() ? {
    flashMessages: flashMessageReducer,
    error: errorReducer,
    garage: garageReducer,
    garageList: garageListReducer,
    user: userReducer,
    formSubmitting: formSubmittingReducer,
    formErrors: formErrorsReducer,
    termin: terminReducer,
    dialog: dialogReducer,
    formGarage: formGarageReducer,
    services: servicesReducer,
    testimonials: testimonialsReducer,
    predefinedServices: predefinedServicesReducer,
    predefinedGarage: predefinedGarage,
    garageDiscount: garageDiscount,
    filters: filterReducer,
    voucher: voucher,
    sort: sortReducer,
} : {};

export default {
    ...formReducers,
    ...appReducers
}
