import {PREDEFINED_GARAGE_TOKEN_RESET, PREDEFINED_GARAGE_TOKEN_SET} from "../actions/predefinedGarageToken.actions";
import predefinedGarageToken from "../models/predefinedGarageToken";

export default function(state = predefinedGarageToken, action) {
    switch (action.type) {

        case PREDEFINED_GARAGE_TOKEN_SET:
            return action.payload.garageToken;

        case PREDEFINED_GARAGE_TOKEN_RESET:
            return predefinedGarageToken;

        default:
            return state;
    }
}
