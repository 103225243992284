import {VOUCHER_SET} from "../actions/voucher.actions";
import voucher from "../models/voucher";

export default function(state = voucher, action) {
    switch (action.type) {

        case VOUCHER_SET:
            return action.payload.voucher;

        default:
            return state;
    }
}
