export function setSessionStorageItem(key, item) {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(key, item);
    }
}

export function removeSessionStorageItem(key) {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem(key);
    }
}

export function setLocalStorageItem(key, item) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem(key, item);
    }
}

export function removeLocalStorageItem(key) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(key);
    }
}

export function existInLocalStorage(key) {
    if (typeof localStorage !== 'undefined') {
        return localStorage.getItem(key) !== null;
    }
}

export default {
    setLocalStorageItem,
    removeLocalStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
    existInLocalStorage
};
