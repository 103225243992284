import i18n from 'i18next';
import resources from '../lang';

i18n.init({
    resources,
    lng: window.base.language,
    fallbackLng: process.env.REACT_APP_FALLBACK_LANG,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
        formatSeparator: ','
    },
    react: {
        wait: true
    }
});

export default i18n;
