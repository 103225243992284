import car from '../models/car';
import formErrors from '../models/formErrors';
import formSubmitting from '../models/formSubmitting';
import services from '../models/services';
import user from '../models/user';
import formGarage from '../models/formGarage';
import dialog from "./dialog";
import flashMessages from "./flashMessages";
import garage from "./garage";
import garageList from "./garageList";
import global from "./global";
import loader from "./loader";
import step from "./step";
import termin from "./termin";
import zipData from "./zipData";

export default {
    car,
    formErrors,
    formSubmitting,
    services,
    user,
    formGarage,
    dialog,
    flashMessages,
    garage,
    garageList,
    global,
    loader,
    step,
    termin,
    zipData
}