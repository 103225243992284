//@flow

import React, {Component} from 'react';
import FormVehicle from '../../components/forms/FormVehicle/FormVehicle';
import {connect} from 'react-redux';
import {changeStepData} from '../../store/actions/step.actions';
import {translate} from 'react-i18next';
import {fetchCarData} from '../../store/actions/car.actions';
import Step from '../../components/shared/Step/Step';
import {FORM_VEHICLE_TYPE_FORM_STEP_1, FORM_VEHICLE_TYPE_FORM_STEP_2} from "../../app/consts/vehicle";
import {isFormOnly} from "../../utils/form";
import {appendQueryParam} from "../../utils/appendQueryParam";
import classNames from "classnames";
import {ShowFooter} from "../../routes/middleware/ShowFooter";
import imagesPath from "../../utils/imagesPath";
import {VoucherCheckStep} from "../../routes/middleware/home/VoucherCheckStep";

type State = {
    changeStepData: Function
};

class HomeContainer extends Component<State> {
    constructor(props) {
        super(props);
        this.state = {
            formVehicleType: isFormOnly() ? FORM_VEHICLE_TYPE_FORM_STEP_1 : null,
            car: this.props.car
        };
    }

    componentDidMount() {
        if (!isFormOnly()) {
            document.title = this.props.t('titles.home')
        }

        this.props.changeStepData(1);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.car.token !== prevState.car.token) {
            return {car: nextProps.car};
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.car.token !== this.state.car.token) {
            for (let link of Array.from(document.querySelectorAll('.lang-link'))) {
                link.setAttribute('href', appendQueryParam('token', this.state.car.token, link.getAttribute('href')))
            }
        }
    }

    render() {
        const {formVehicleType, car} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                {!isFormOnly() &&
                <div className="col-lg-4 col-md-4 form-sidebar-offset">
                    <div className="form-sidebar">
                        <img src={imagesPath('funnel-sidebar.jpg')} alt="funnel-sidebar"/>
                        <div className="form-sidebar__labels">
                            <div className="form-sidebar__label"><i
                                className="icon-check1"/> {t('pages.home.form_sidebar_label_1')}</div>
                            <div className="form-sidebar__label"><i
                                className="icon-check1"/> {t('pages.home.form_sidebar_label_2')}</div>
                            <div className="form-sidebar__label"><i
                                className="icon-check1"/> {t('pages.home.form_sidebar_label_3')}</div>
                        </div>
                    </div>
                </div>
                }

                <div className={classNames('col-sm-4', {
                    'iframe-container': isFormOnly(),
                    'col-sm-push-2 col-md-push-0 col-md-8 p-20': isFormOnly(),
                    'col-sm-push-2 col-md-push-0 col-md-4 col-lg-3 p-20': !isFormOnly(),
                })}>

                    {/*<div className="iframe-container col-sm-4 col-sm-push-2 col-md-push-0 col-md-8 p-20">*/}
                    {!isFormOnly() && (<div className="form-title"><Step/></div>)}

                    <FormVehicle
                        car={car}
                        type={formVehicleType}
                        onGetNextFormStep={() => {
                            this.setState({
                                formVehicleType: FORM_VEHICLE_TYPE_FORM_STEP_2
                            })
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        car: state.car
    };
};

export default connect(
    mapStateToProps,
    {
        fetchCarData,
        changeStepData
    }
)(VoucherCheckStep(ShowFooter(translate('translations')(HomeContainer))));
