import formSubmitting from '../models/formSubmitting';
import {
    FORM_SUBMITTING_BOOKING,
    FORM_SUBMITTING_VEHICLE,
    FORM_SUBMITTING_GARAGE_LIST,
    FORM_SUBMITTING_LOGIN,
    FORM_SUBMITTING_SIGN_UP, FORM_SUBMITTING_SAVE_GARAGE_LIST, FORM_SUBMITTING_VOUCHER
} from '../actions/formSubmitting.actions';

export default function(state = formSubmitting, action) {
    switch (action.type) {
        case FORM_SUBMITTING_VEHICLE:
            return {
                ...state,
                vehicle: action.payload.submitted
            };
        case FORM_SUBMITTING_GARAGE_LIST:
            return {
                ...state,
                garageList: action.payload.submitted
            };
        case FORM_SUBMITTING_LOGIN:
            return {
                ...state,
                login: action.payload.submitted
            };

        case FORM_SUBMITTING_SIGN_UP:
            return {
                ...state,
                signUp: action.payload.submitted
            };

        case FORM_SUBMITTING_BOOKING:
            return {
                ...state,
                booking: action.payload.submitted
            };

        case FORM_SUBMITTING_SAVE_GARAGE_LIST:
            return {
                ...state,
                saveGarageList: action.payload.submitted
            };

        case FORM_SUBMITTING_VOUCHER:
            return {
                ...state,
                voucher: action.payload.submitted
            };

        default:
            return state;
    }
}
