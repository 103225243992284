import { USER_SET, USER_REMOVE } from '../actions/user.actions';
import user from '../models/user';

export default function(state = user, action) {
    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                logged: true,
                ...action.payload.user
            };

        case USER_REMOVE:
            return user;

        default:
            return state;
    }
}
