// @flow

import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import {translate} from 'react-i18next';
import {connect} from 'react-redux';
import routes from './routes';
import {userFetch} from "./store/actions/user.actions";
import {
    removeLocalStorageItem,
    removeSessionStorageItem,
    setLocalStorageItem,
    setSessionStorageItem
} from "./utils/storage";
import {STORAGE_OFFER_TOKEN, STORAGE_USER_TOKEN} from "./app/consts/storage.consts";
import ScrollToTop from './components/hoc/ScrollToTop';
import FlashMessageList from './containers/FlashMessageList/FlashMessageList';
import {getOffer} from "./store/actions/offer.actions";
import {LOADER_INIT_OFFER} from "./store/consts/loader.constants";
import {setLoader} from "./store/actions/loader.actions";
import fieldExists from "./utils/fieldExists";
import {isFormOnly} from "./utils/form";
import Loader from "./components/shared/Loader/Loader";
import {setPredefinedServices} from "./store/actions/predefinedServices.actions";
import {setPredefinedGarageToken} from "./store/actions/predefinedGarageToken.actions";
import {uptoUserFirstName} from "./store/actions/global.actions";
import Dialog from "./components/dialogs/Dialog/Dialog";
import {DIALOG_NOTIFICATION_FROM_BACKEND} from "./store/consts/dialog.constants";
import {setDialog} from "./store/actions/dialog.actions";

class App extends React.Component<{}> {
    componentDidMount() {
        App.setOfferToken();
        this.setUser();
        this.appendCode();
        this.setPredefinedServices();
        this.setPredefinedGarage();
        this.setUserFirstName();
        this.setNotification();
    }

    static setOfferToken() {
        if (fieldExists(window.settings, 'offerToken')) {
            setSessionStorageItem(STORAGE_OFFER_TOKEN, window.settings.offerToken);
        } else {
            removeSessionStorageItem(STORAGE_OFFER_TOKEN);
        }
    }

    setUser() {
        if (fieldExists(window.settings, 'userToken')) {
            setLocalStorageItem(STORAGE_USER_TOKEN, window.settings.userToken);
            this.props.userFetch();
        } else {
            removeLocalStorageItem(STORAGE_USER_TOKEN);
        }
    }

    setUserFirstName() {
        let urlParams = new URLSearchParams(window.location.search);
        let clientName = atob(urlParams.get('_n'));
        if (clientName) {
            clientName = clientName.replace(/[^a-zA-Z]\p{L}+/g, " ");
            clientName = decodeURIComponent(clientName);
            this.props.uptoUserFirstName(clientName);
        }
    }

    appendCode() {
        if (fieldExists(window.settings, 'offerToken')) {
            this.props.getOffer();
        } else {
            this.props.setLoader(LOADER_INIT_OFFER, true);
        }
    }

    setPredefinedServices() {
        if (fieldExists(window.settings, 'predefinedServices')) {
            this.props.setPredefinedServices(window.settings.predefinedServices);
        }
    }

    setPredefinedGarage() {
        if (fieldExists(window.settings, 'repairShopToken')) {
            this.props.setPredefinedGarageToken(window.settings.repairShopToken);
        }
    }

    static existNotification() {
        return fieldExists(window.notification, 'type') && window.notification.type !== '';
    }

    setNotification() {
        if (App.existNotification()) {
            this.props.setDialog(DIALOG_NOTIFICATION_FROM_BACKEND, true);
        }
    }


    static renderRoutes() {
        return routes.map((item, index) => {
            return <Route key={index} exact={item.exact} path={item.path} component={item.component}/>
        })
    }

    render() {
        return (
            <React.Fragment>
                {!isFormOnly() && <FlashMessageList/>}

                <ScrollToTop>
                    <Switch>
                        {this.props.loader[LOADER_INIT_OFFER] ? App.renderRoutes() :
                            <Loader isLoading={true} type={LOADER_INIT_OFFER}/>}
                    </Switch>
                </ScrollToTop>
                {App.existNotification() &&
                <Dialog
                    type={DIALOG_NOTIFICATION_FROM_BACKEND}
                    payload={{
                        title: window.notification.type,
                        description: window.notification.message
                    }}
                    autoClose={true}
                />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader
    };
};

export default connect(
    mapStateToProps,
    {
        userFetch,
        getOffer,
        setLoader,
        setPredefinedServices,
        setPredefinedGarageToken,
        uptoUserFirstName,
        setDialog
    }
)(translate('translations')(App));
