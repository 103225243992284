import {matchPath} from 'react-router-dom';
import store from '../store/store';
import pages from '../app/consts/routes';
import history from '../routes/history';
import {setLocalStorageItem} from './storage';
import {STORAGE_OFFER_TOKEN} from '../app/consts/storage.consts';

const onLeaveConfirmDialog = (e) => {
    e.preventDefault();
    e.returnValue = ``;
};

const sendBeaconData = (URL) => {
    const pathName = window.location.hash.replace('#', '');

    if (pathName === pages.services || pathName === pages.garage) {

        let data = new FormData();
        data.append('offer_token', sessionStorage.getItem(STORAGE_OFFER_TOKEN));
        data.append('garage_list', JSON.stringify(store.getState().garageList));

        navigator.sendBeacon(URL, data);
    }
};

const setLocalStorage = () => {
    if (sessionStorage.getItem(STORAGE_OFFER_TOKEN)) {
        setLocalStorageItem(STORAGE_OFFER_TOKEN, sessionStorage.getItem(STORAGE_OFFER_TOKEN));
    }
};

window.addEventListener('beforeunload', (e) => {
    onLeaveConfirmDialog(e);
});

window.onunload = function(e) {
    // sendBeaconData(`${process.env.REACT_APP_BASE_URL_API}/pixel-offers`);
    setLocalStorage();
};

history.listen((location) => {
    if (matchPath(location.pathname, pages.confirm)) {
        window.removeEventListener('beforeunload', onLeaveConfirmDialog);
    }
});
