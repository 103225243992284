//@flow
import React, { Component } from 'react';
import './Label.scss';

type Props = {};

type State = {};

class Label extends Component<Props, State> {
    render() {
        const { children, size, type, icon } = this.props;

        return (
            <div className={`label label--${size} label--${type}`}>
                {icon && (
                    <div className="label__icon">
                        <span className={icon} />
                    </div>
                )}
                {children}
            </div>
        );
    }
}

export default Label;
