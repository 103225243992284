export const BOOKING = '[Booking]';

export const BOOKING_SERVICE_FINISH = `${BOOKING} SERVICE FINISH`;
export const BOOKING_SERVICE_SEND = `${BOOKING} SERVICE SEND`;
export const BOOKING_SERVICE_PAGE = `${BOOKING} SERVICE NEXT PAGE`;

export const bookServiceFinish = () => ({
    type: BOOKING_SERVICE_FINISH
});

export const bookServiceSend = ({ bookingDates, repairShopToken, voucher }) => ({
    type: BOOKING_SERVICE_SEND,
    payload: {
        booking_dates: bookingDates,
        repair_shop_token: repairShopToken,
        voucher: voucher
    }
});

export const bookServicePage = () => ({
    type: BOOKING_SERVICE_PAGE
});
