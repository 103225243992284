import React, { Component } from 'react';
import InputCheckout from "../../InputCheckout/InputCheckout";
import {translate} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

class OfficialServiceBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened : props.isOpened
        }
    }

    toggle = () => {
        this.setState({
            opened: !this.state.opened
        })
    };

    render() {
        const { t, list, name, onToggle, checked } = this.props;
        const { opened } = this.state;

        const header = (
            <div className="official-service__name">
                <InputCheckout
                    id="official-service-checkbox"
                    name="official-service-checkbox"
                    checked={checked}
                    inputControlClass="m-0"
                    label={name}
                    onChange={onToggle}
                />

                <span className={classNames("icon", {
                        "icon-chevron-down": !opened,
                        "icon-chevron-up": opened,
                      })}
                      onClick={this.toggle}
                ></span>
            </div>
        );

        const includesListItem = ({ name, nameAlternative }, index) => (
            <li key={index}>
                {nameAlternative ? nameAlternative : name}
            </li>
        );

        const includesList = (
            <ul data-label={t('global.official_service.includes')} className="official-service__includes">
                {list.map(includesListItem)}
            </ul>
        );

        return (
            <div className={classNames("official-service", {
                "official-service--checked": checked
            })}>
                {header}
                {opened && includesList}
            </div>
        );
    }
}

export default translate('translations')(OfficialServiceBox);
