//@flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeStepData} from '../../store/actions/step.actions';
import {translate} from 'react-i18next';
import {fetchCarData} from '../../store/actions/car.actions';
import {getOffer} from '../../store/actions/offer.actions';

import {compare} from '../../utils/common';
import './GarageContainer.scss';
import CheckoutDetail from '../../components/shared/Checkout/CheckoutDetail';
import BookingContainer from '../../containers/BookingContainer/BookingContainer';
import {
    setGarageFormIsSubmitted,
    setGarageIsFormDatePickerValid,
    formGarageReset
} from '../../store/actions/formGarage.actions';
import {addFlashMessage} from '../../store/actions/flashMessage.actions';
import {removeError, setError} from '../../store/actions/error.actions';
import {
    getSelectedLabourServicesOfficialDiff,
    getSelectedOfficialServiceDefault
} from '../../store/selectors/services';
import {garageClick} from "../../store/actions/garage.actions";
import {GarageCheckStep} from "../../routes/middleware/garage/GarageCheckStep";
import {HasOfferToken} from "../../routes/middleware/HasOfferToken";
import SliderTestimonials from "../../components/sliders/testimonials/Slider";
import CheckoutPayment from "../../components/shared/CheckoutPayment/CheckoutPayment";
import GarageItem from "../../components/shared/GarageItem/GarageItem";
import {getValueFromArray} from "../../utils/car";
import {applyGarageDiscount} from "../../store/selectors/garage";
import TerminPicker from "../../components/shared/TerminPicker/TerminPicker";
import {HideFooter} from "../../routes/middleware/HideFooter";
import {scroller} from "../../utils/scroller";

class GarageContainer extends Component<{}> {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            showOrderSummery: true
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (compare(this.props.car.garages, nextProps.car.garages)) {
            // todo
        }
    }

    componentDidMount() {
        document.title = this.props.t('titles.garage', {garageName: this.getSelectedGarage().name});
        this.props.changeStepData(3);
        this.props.garageClick(this.props.garage.id);
    }

    componentWillUnmount() {
        this.props.formGarageReset();
    }

    getSelectedGarage = () => {
        const {
            car: {garages},
            garage,
            match
        } = this.props;

        // if selected garage exist in store
        if (garage) {
            return garage;
        }

        // if not exist, fetch selected garage from store's list
        // TODO if not exist redirect to previous url (garages list0
        return garages ? garages.find(garage => garage.slug === match.params.slug) : undefined;
    };

    static scrollToCheckoutDetails() {
        scroller('checkout-detail', {
            duration: 500,
            smooth: "ease"
        })
    }

    hideShowOrderSummery = () => {
        this.setState({
            showOrderSummery: !this.state.showOrderSummery
        });
    };

    render() {
        const {
            t,
            car,
            formGarage,
            selectedServices,
            selectedOfficialService,
            testimonials,
            garageDiscount,
            voucher
        } = this.props;

        const {showOrderSummery} = this.state;

        const garage = this.getSelectedGarage();
        const make = getValueFromArray(car.makes, 'id', car.make_id);

        return (
            <React.Fragment>
                <div className="ior-token">{car.token}</div>

                <div className="main main--no-padding main--center main--garage">

                    <div className="garage-container">

                        <div className="row">

                            <div className="col-md-8 col-sm-6 col-xs-8 col-sm-push-1 col-md-push-0 hidden-desktop">
                                <div className="show-order-summery">
                                    <div className="col-sm-5 col-xs-5">
                                        <a href="javascript:void(0)" onClick={this.hideShowOrderSummery}
                                           className="active">
                                            <i className="icon-shop"/> {t('pages.garage.show_summary')}{' '}
                                            {showOrderSummery === true ?
                                                <i className="icon-chevron-up"/> : <i className="icon-chevron-down"/>
                                            }
                                        </a>
                                    </div>
                                </div>

                                {showOrderSummery === true &&
                                <div className="w-box">
                                    <div className="w-box__body">
                                        <CheckoutDetail
                                            car={car}
                                            list={[...selectedOfficialService, ...selectedServices]}
                                            garage={garage}
                                            garageDiscount={garageDiscount}
                                            voucher={voucher}
                                        />
                                    </div>
                                </div>
                                }
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-8 col-sm-push-1 col-md-push-0"
                                 id="first-checkout-section">
                                <div className="w-box">
                                    <div className="w-box-body">
                                        <GarageItem
                                            item={garage}
                                            make={make && make.name}
                                            hideOptions={true}
                                            hideAxaPartner={voucher && voucher.hide_voucher_partner}
                                        />
                                    </div>

                                </div>

                                <div className="w-box">
                                    <div className="w-box-header"><h3
                                        className="w-box-header__title">{t('pages.garage.termin')}</h3></div>
                                    <div className="w-box__body">
                                        <div className="col-lg-7 col-md-7 col-sm-8 col-xs-8 mb-30">
                                            <TerminPicker
                                                averageWaitingTime={garage.average_waiting}
                                                shouldValidate={formGarage.isFormSubmitted}
                                                hideButton={true}
                                                horizontal={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-box">
                                    <div className="w-box-header"><h3
                                        className="w-box-header__title">{t('pages.garage.customer_data')}</h3></div>
                                    <div className="w-box__body">
                                        <BookingContainer
                                            showDetails={GarageContainer.scrollToCheckoutDetails}
                                        />
                                    </div>
                                </div>

                                <CheckoutPayment t={t} extraClass="garage-container"/>
                            </div>
                            <div className="col-md-2 col-sm-6 col-xs-8 col-sm-push-1 col-md-push-0">
                                <div className="w-box hidden-mobile">
                                    <div className="w-box__body">
                                        <CheckoutDetail
                                            car={car}
                                            list={[...selectedOfficialService, ...selectedServices]}
                                            garage={garage}
                                            garageDiscount={garageDiscount}
                                            voucher={voucher}
                                        />
                                    </div>
                                </div>
                                <div className="w-box">
                                    <div className="w-box-header"><h3
                                        className="w-box-header__title">{t('pages.garage.testimonials_title')}</h3>
                                    </div>
                                    <div className="w-box__body">
                                        <SliderTestimonials slides={testimonials}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        car: state.car,
        garage: applyGarageDiscount(state),
        selectedOfficialService: getSelectedOfficialServiceDefault(state),
        selectedServices: getSelectedLabourServicesOfficialDiff(state),
        finishBookingSubmitting: state.finishBookingSubmitting,
        formGarage: state.formGarage,
        testimonials: state.testimonials,
        garageDiscount: state.garageDiscount,
        voucher: state.voucher,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchCarData,
        getOffer,
        setGarageFormIsSubmitted,
        setGarageIsFormDatePickerValid,
        addFlashMessage,
        setError,
        removeError,
        garageClick,
        changeStepData,
        formGarageReset
    }
)(GarageCheckStep(HasOfferToken(HideFooter(translate('translations')(GarageContainer)))));
