import {
    TERMIN_RESET,
    TERMIN_SET_DATE,
    TERMIN_SET_TIME
} from "../actions/terminPicker.actions";
import terminModel from '../models/termin';

export default function(state = terminModel, action) {

    switch (action.type) {
        case TERMIN_SET_DATE:
            return state.map(termin => {
                return {
                    ...termin,
                    date: action.payload.date
                }
            });

        case TERMIN_SET_TIME:
            return state.map((termin, index) => {
                if (index === action.payload.period) {
                    return {
                        ...termin,
                        time: (termin.time) ? null : action.payload.time /* toggling effect */
                    }
                }

                return termin;
            });

        case TERMIN_RESET:
            return [
                ...terminModel
            ];

        default:
            return state;
    }
}
