/* eslint-disable */
import './app/config/polyfills';

import React from 'react';
import {Router} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './assets/styles/app.scss';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import historyRoute from './routes/history';
import {I18nextProvider} from 'react-i18next';
import i18n from './app/config/i18n';
import {dsn} from './app/config/sentry';
import * as Sentry from '@sentry/browser';
import {isFormOnly} from "./utils/form";

if (!isFormOnly()) {
    require('./assets/js/nav/nav');
    require('./utils/beforeUnload');
}

Sentry.init({
    dsn,
    beforeSend(event) {
        if (event.hasOwnProperty('extra')) {
            event.extra.state = {
                car: store.getState().car,
                services: store.getState().services,
                garage: store.getState().garage,
                garageList: store.getState().garageList,
            };
        }

        return event;
    }
});

const render = (Component, id) => {
    ReactDOM.render(
        <Router history={historyRoute}>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <Component/>
                </I18nextProvider>
            </Provider>
        </Router>,
        document.getElementById(id)
    );
};

render(App, 'root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
