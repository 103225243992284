import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import {formSubmittingBooking, formSubmittingVoucher} from '../../actions/formSubmitting.actions';
import {VOUCHER_CHECK, voucherSet} from "../../actions/voucher.actions";
import {formErrorsVoucherSet} from "../../actions/formErrors.actions";

export const VOUCHER_CHECK_URL = 'voucher-check';

export const voucherMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case VOUCHER_CHECK:

                dispatch(formSubmittingBooking(true));
                dispatch(formSubmittingVoucher(true));

                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: VOUCHER_CHECK_URL,
                        signature: VOUCHER_CHECK
                    })
                );
                break;

            case `${VOUCHER_CHECK} ${API_SUCCESS}`:
                dispatch(voucherSet(action.payload.data));
                dispatch(formSubmittingBooking(false));
                dispatch(formSubmittingVoucher(false));

                break;

            case `${VOUCHER_CHECK} ${API_ERROR}`:
                dispatch(formErrorsVoucherSet(action.payload.error.error));
                dispatch(formSubmittingBooking(false));
                dispatch(formSubmittingVoucher(false));

                break;

            default:
                break;
        }
    };
};
