import React, {Component} from 'react';
import './ItemExtraDetail.scss';
import {translate} from "react-i18next";

class ItemExtraDetail extends Component {
    render() {
        const {value, extra, icon, color, extraClass} = this.props;

        return (
            <div className={`item-extra-detail ${extraClass ? extraClass : ''}`}>
                {icon && (
                    <div className={`item-extra-detail__square`}>
                        <span className={`${icon} ${color}`}/>
                    </div>
                )}
                <div className="item-extra-detail__label">
                    {value} {extra}
                </div>
            </div>
        );
    }
}

export default translate('translations')(ItemExtraDetail);
