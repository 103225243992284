import { RectShape} from 'react-placeholder/lib/placeholders';
import * as React from 'react';
import './garageList.placeholder.scss';



const garageListOptionsPlaceholder = (
    <div className='placeholder-garage-list-container'>
        <div className='placeholder-container placeholder-garage-list-options'>
            <RectShape />
            <RectShape />
            <RectShape />
        </div>
    </div>
);

export default garageListOptionsPlaceholder;
