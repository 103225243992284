import { ERROR_REMOVE, ERROR_SET } from '../actions/error.actions';

export default function(state = [], action) {
    const stateCopy = [ ...state ];

    switch (action.type) {
        case ERROR_SET:

            if (state.find(item => item.id === action.payload.id)) {
                return [...state]
            }

            return [
                ...state,
                {
                    id: action.payload.id,
                    isVisible: true,
                    message: action.payload.message
                }
            ];

        case ERROR_REMOVE:

            stateCopy.splice(stateCopy.findIndex(item => item.id === action.payload.id), 1);
            return stateCopy;

        default:
            return state;
    }
}
