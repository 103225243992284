export const FILTER = '[FILTER]';

export const FILTER_CHANGE = `${FILTER} CHANGE`;
export const FILTER_REMOVE = `${FILTER} REMOVE`;
export const FILTER_UNCHECK_ALL = `${FILTER} UNCHECK ALL`;
export const FILTER_STATUS_CHANGE = `${FILTER} STATUS CHANGE`;
// export const FILTER_UPDATE_PRICE = `${FILTER} UPDATE PRICE`;
export const FILTER_SET_PRICE = `${FILTER} SET PRICE`;
export const FILTER_SET = `${FILTER} SET`;
export const FILTER_REPLACE_BRAND_NAME = `${FILTER} REPLACE BRAND NAME`;
export const FILTER_UPDATE_DISTANCE = `${FILTER} UPDATE DISTANCE`;
export const FILTER_UPDATE_ZIP = `${FILTER} UPDATE ZIP`;
export const FILTER_UPDATE_ZIP_STATUS = `${FILTER} UPDATE ZIP STATUS`;
export const FILTER_RESET_ZIP = `${FILTER} RESET ZIP`;
export const FILTER_UPDATE_ZIP_WITHOUT_STATUS = `${FILTER} UPDATE ZIP WITHOUT STATUS`;

export const filterSet = (filters) => ({
   type: FILTER_SET,
   payload: {
       filters
   }
});

export const filterReplaceBrandName = carBrand => ({
    type: FILTER_REPLACE_BRAND_NAME,
    payload: {
        carBrand
    }
});

export const filterStatusChange = () => ({
    type: FILTER_STATUS_CHANGE
});

export const filterResetZip = () => ({
    type: FILTER_RESET_ZIP
});

// export const filterUpdatePriceRange = (garageList) => ({
//     type: FILTER_UPDATE_PRICE,
//     payload: {
//         garageList
//     }
// });

export const filterUpdateDistance = (from, to, min, max) => ({
    type: FILTER_UPDATE_DISTANCE,
    payload: {
        from, to, min, max
    }
});

export const filterUpdateZip = zip => ({
    type: FILTER_UPDATE_ZIP,
    payload: {
        zip
    }
});

export const filterUpdateZipWithoutStatus = zip => ({
    type: FILTER_UPDATE_ZIP_WITHOUT_STATUS,
    payload: {
        zip
    }
})

export const filterUpdateZipStatus = status => ({
    type: FILTER_UPDATE_ZIP_STATUS,
    payload: {
        status
    }
});

export const filterSetPriceRange = (min, max) => ({
    type: FILTER_SET_PRICE,
    payload: {
        min,
        max
    }
});

export const filterChange = (filter, data, label) => ({
    type: FILTER_CHANGE,
    payload: {
        filter,
        data,
        label
    }
});

export const uncheckFilters = () => ({
    type: FILTER_UNCHECK_ALL
});

export const filterRemove = (filter) => ({
    type: FILTER_REMOVE,
    payload: {
        filter
    }
});
