export const LOGIN = 'LOGIN';
export const LOGIN_FACEBOOK = `${LOGIN} FACEBOOK`;
export const LOGIN_GOOGLE = `${LOGIN} GOOGLE`;

export const login = (type, data) => ({
    type,
    payload: {
        data
    }
});
