document.addEventListener("DOMContentLoaded", function (event) {
  if (!!document.getElementsByTagName('header').length) {
    // utility functions
    var getNodeList = function getNodeList(className) {
      var first = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (first) {
        return document.querySelectorAll(".".concat(className))[0];
      }

      return document.querySelectorAll(".".concat(className));
    };

    var testClassName = function testClassName(baseClass, extraClass, onClassFound, onClassNotFound) {
      if (typeof baseClass === 'object') return;

      if (baseClass.includes(extraClass) && typeof onClassFound === 'function') {
        onClassFound(baseClass);
      } else if (!baseClass.includes(extraClass) && typeof onClassNotFound === 'function') {
        onClassNotFound(baseClass);
      }
    };

    var setBodyOverflowY = function setBodyOverflowY(value) {
      document.body.style.overflowY = value;
    }; // other functions


    var navMobileOpen = function navMobileOpen() {
      hamburger.className = hamburger.className.replace(MENU_IS_CLOSED_CLASS, MENU_IS_OPEN_CLASS);
      setBodyOverflowY('hidden');
      navigation.className += " ".concat(NAVIGATION_IS_OPEN_CLASS);
      navigationItems.className += " ".concat(NAVIGATION_IS_OPEN_CLASS);
    };

    var navMobileClose = function navMobileClose(resetNavClassNameToDefault) {
      hamburger.className = hamburger.className.replace(MENU_IS_OPEN_CLASS, MENU_IS_CLOSED_CLASS);
      setBodyOverflowY('auto');

      if (resetNavClassNameToDefault) {
        navigation.className = navClassNameDefault;
      }

      navigationItems.className = navItemsClassNameDefault;
    };

    var dropdownToggle = function dropdownToggle(link) {
      dropdownCloseAll();
      var dropdownToToggle = link.parentNode.querySelector('.navigation-item__dropdown');
      if (!dropdownToToggle) return;
      testClassName(dropdownToToggle.className, DROPDOWN_IS_OPEN_CLASS, function (className) {
        dropdownToToggle.className = className.replace(DROPDOWN_IS_OPEN_CLASS, '');
      }, function () {
        dropdownToToggle.className += " ".concat(DROPDOWN_IS_OPEN_CLASS);
      });
    };

    var dropdownCloseAll = function dropdownCloseAll() {
      dropDowns.forEach(function (dropdown) {
        dropdown.className = dropdown.className.replace(DROPDOWN_IS_OPEN_CLASS, '');
      });
    };

    var linkIconsResetToDefault = function linkIconsResetToDefault() {
      var icons = document.querySelectorAll('.navigation-item--icon');
      icons.forEach(function (icon) {
        testClassName(icon.className, ICON_UP, function (className) {
          icon.className = className.replace(ICON_UP, ICON_DOWN);
        });
      });
    };

    var linkIconToggle = function linkIconToggle(link) {
      var iconToRotate = link.querySelector('.navigation-item--icon');
      testClassName(iconToRotate.className, ICON_DOWN, function (className) {
        iconToRotate.className = className.replace(ICON_DOWN, ICON_UP);
        iconToRotate.style.opacity = 1;
      }, function (className) {
        iconToRotate.className = className.replace(ICON_UP, ICON_DOWN);
        iconToRotate.style.opacity = 1;
      });
    };

    var onNavToggle = function onNavToggle() {
      testClassName(hamburger.className, MENU_IS_CLOSED_CLASS, function () {
        navMobileOpen();
      }, function () {
        navMobileClose(true);
      });
    }; // handlers


    var onDropdownShouldOpen = function onDropdownShouldOpen(e, link) {
      e.preventDefault();
      linkIconsResetToDefault();
      linkIconToggle(link);
      dropdownToggle(link);
    };

    var onClickAnywhere = function onClickAnywhere(e) {
      testClassName(e.target.className, LINK_CLASS, function () {
        dropdownToggle(e.target);
      }, function () {
        dropdownCloseAll();
        linkIconsResetToDefault();
      });
    };

    var onResize = function onResize() {
      navHeightDefault = navigation.clientHeight + 5;

      if (window.innerWidth > SCREEN_LG_MIN) {
        navMobileClose(false);
      }
    };

    var onScroll = function onScroll() {
      var scrollYOffsetCurrent = window.pageYOffset || document.documentElement.scrollTop;
      var scrolledBellowNavHeight = scrollYOffsetCurrent >= 60;
      var scrolledAboveNavHeight = scrollYOffsetCurrent <= 60;
      var isScrollingDown = scrollYOffsetLast < scrollYOffsetCurrent;
      var scrolledToTop = scrollYOffsetCurrent === 0;

      if (window.innerWidth < SCREEN_LG_MIN) {
        navigation.style.marginTop = 0;

        if (isScrollingDown) {
          if (scrolledBellowNavHeight) {
            navigation.className = NAVIGATION_FIXED_CLASS;
          }
        } else {
          if (scrolledToTop || scrolledAboveNavHeight) {
            if (navigation.getAttribute('id') !== NAV_FUNNEL_ID) {
              navigation.className = navClassNameDefault;
            }
          }
        }

        scrollYOffsetLast = scrollYOffsetCurrent;
        return;
      }

      var slideUpEffect = function slideUpEffect() {
        dropdownCloseAll();

        if (navigation.getAttribute('id') !== NAV_FUNNEL_ID) {
          navigation.style.marginTop = "-".concat(navHeightDefault, "px");
        }
      };

      var slideDownEffect = function slideDownEffect() {
        navigation.style.marginTop = 0;
      };

      var slideEffectReset = function slideEffectReset() {
        navigation.style.marginTop = 0;
      };

      if (isScrollingDown) {
        if (scrolledBellowNavHeight) {
          navigation.className = NAVIGATION_FIXED_CLASS;
        }
        /* hide navbar after 600px */


        if (scrollYOffsetCurrent > 600) {
          slideUpEffect();
        }
      } else {
        if (scrolledBellowNavHeight) slideUpEffect();

        if (scrolledToTop || scrolledAboveNavHeight) {
          slideEffectReset();

          if (navigation.getAttribute('id') !== NAV_FUNNEL_ID) {
            navigation.className = navClassNameDefault;
          }
        } else if (!scrolledToTop && scrolledBellowNavHeight) {
          slideDownEffect();
          navigation.className = NAVIGATION_FIXED_CLASS;
        }
      }

      scrollYOffsetLast = scrollYOffsetCurrent;
    };

    var SCREEN_LG_MIN = 1140;
    var ICON_DOWN = 'icon-chevron-down';
    var ICON_UP = 'icon-chevron-up';
    var DROPDOWN_IS_OPEN_CLASS = 'navigation-item__dropdown--open';
    var MENU_IS_OPEN_CLASS = 'icon-cross';
    var MENU_IS_CLOSED_CLASS = 'icon-menu';
    var NAVIGATION_IS_OPEN_CLASS = 'opened';
    var LINK_CLASS = 'navigation-item--link';
    var NAVIGATION_FIXED_CLASS = 'navigation navigation--fixed';
    var linksWithDropdown = getNodeList("".concat(LINK_CLASS, ".has-children"));
    var dropDowns = getNodeList('navigation-item__dropdown');
    var hamburger = getNodeList('navigation-header__icon', true);
    var navigation = getNodeList('navigation', true);
    var navigationItems = getNodeList('navigation-items', true);
    var navClassNameDefault = navigation.className;
    var navItemsClassNameDefault = navigationItems.className;
    var navHeightDefault = navigation.clientHeight + 10;
    var NAV_FUNNEL_ID = 'nav-funnel';
    var scrollYOffsetLast = 0;

    if (window.innerWidth < SCREEN_LG_MIN) {
      navigation.style.marginTop = 0;
    } // listeners


    linksWithDropdown.forEach(function (link) {
      link.addEventListener('click', function (e) {
        onDropdownShouldOpen(e, link);
      });
    });
    document.body.addEventListener('click', onClickAnywhere);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
    hamburger.addEventListener('click', onNavToggle);
  }
});