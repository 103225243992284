import React, {Component} from 'react';
import { translate } from "react-i18next";
import AdditionalServiceSelectedListItem from "../SelectedListItem";
import { connect } from "react-redux";
import { serviceUncheck } from "../../../../store/actions/services.actions";
import { ServiceCheckStep } from "../../../../routes/middleware/service/ServiceCheckStep";
import { SERVICE_TYPE_LABOUR_CUSTOM, SERVICE_TYPE_LABOUR_SEARCH } from "../../../../store/consts/services.constants";
import classNames from 'classnames';
import "./index.scss";

class AdditionalServiceSelectedList extends Component {

    getServicesList = () => {
        const { services: { labour }  } = this.props;

        return [
            ...labour.search,
            ...labour.custom
        ].filter(service =>
            service.exist === true &&
            service.selected === true &&
            service.is_official_selected === false
        );
    };

    handleServiceUncheck = (serviceId) => {
        const { serviceUncheck } = this.props;

        serviceUncheck(SERVICE_TYPE_LABOUR_CUSTOM, serviceId);
        serviceUncheck(SERVICE_TYPE_LABOUR_SEARCH, serviceId);
    };

    render() {
        const listLength = this.getServicesList().length;

        if (listLength === 0)
            return null;

        return (
            <div className="selected-service-list-container">
                <div className={classNames("selected-service-list", {
                    "selected-service-list--padding-bottom": listLength > 2
                })}>
                    {this.getServicesList()
                        .map(service => <AdditionalServiceSelectedListItem
                            service={service}
                            key={service.id}
                            onRemove={this.handleServiceUncheck}
                        />)}
                </div>
                {listLength > 2 && (
                    <div className="selected-service-shadow"></div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        services: state.services
    }
};

export default connect(
    mapStateToProps,
    {
        serviceUncheck
    }
)(ServiceCheckStep(translate('translations')(AdditionalServiceSelectedList)));
