export default {
    logged: false,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    comment: '',
    address: '',
    social_token: null,
    avatar: null
};
