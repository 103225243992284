import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';
import * as React from 'react';
import './officialService.placeholder.scss';

const officialServicePlaceholder = (
    <div className='placeholder-container'>
        <div className="placeholder-row">
            <RectShape className='placeholder-official-service-checkbox'/>
            <RectShape className='placeholder-official-service-title'/>
        </div>
        <div className="placeholder-row placeholder-separator"></div>
        <div className="placeholder-row">
            <TextBlock rows={5} color=''/>
        </div>
    </div>
);

export default officialServicePlaceholder;
