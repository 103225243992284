export default {
    official: {
        prev: [],
        next: [],
        selected: ''
    },
    labour: {
        search: [],
        custom: []
    }
};