import {setStepDataActiveOne, STEP, STEP_CHANGE, STEP_IFRAME_CHANGE} from '../../actions/step.actions';
import {API_SUCCESS, apiRequest} from '../../actions/api.actions';
import store from "../../store";

export const STEP_URL = 'steps';

export const stepMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case STEP_CHANGE:
                dispatch(setStepDataActiveOne(action.payload));
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: STEP_URL,
                        signature: STEP
                    })
                );
                break;

            case STEP_IFRAME_CHANGE:
                dispatch(
                    apiRequest({
                        body: action.payload,
                        method: 'POST',
                        url: STEP_URL,
                        signature: STEP_IFRAME_CHANGE
                    })
                );
                break;

            case `${STEP_IFRAME_CHANGE} ${API_SUCCESS}`:
                let url = `${process.env.REACT_APP_BASE_URL_FUNNEL}?token=${store.getState().car.token}`;

                if (store.getState().predefinedGarageToken) {
                    url += `&repair_shop_token=${store.getState().predefinedGarageToken}`;
                }

                if(window.settings.predefinedServicesList){
                    url += `&services=${btoa(JSON.stringify(window.settings.predefinedServicesList))}`;
                }

                if(window.settings.voucher){
                    url += `&voucher=${window.settings.voucher.name}`;
                }

                window.location = url;
                break;

            default:
                break;
        }
    };
};
