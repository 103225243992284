import React, {Component} from 'react';
import './index.scss';
import {translate} from "react-i18next";
// import discount from "../../../utils/discount";

class Price extends Component {
    render() {
        const { garage, currency, children, hidePrice } = this.props;

        return (
            <React.Fragment>
                {garage.discount ? (
                    <p className="price">
                        {!hidePrice &&
                            <span>
                                <span className="discount">
                                    {garage.discountValue}
                                </span>
                                <span className="old">
                                    {currency} {garage.price}
                                </span>
                                <span className="new">
                                     {currency} {garage.totalPrice}
                                </span>
                            </span>
                        }
                        {children}
                    </p>
                ) : (
                    <p className="price">
                        {currency} {garage.price}
                        {children}
                    </p>
                )}
            </React.Fragment>
        );
    }
}

export default translate('translations')(Price);
