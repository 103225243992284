import { RectShape } from 'react-placeholder/lib/placeholders';
import * as React from 'react';
import './index.scss';

const preselectedServiceListPlaceholder = (
    <div className='placeholder-container'>
        <div className="placeholder-row">
            <RectShape className='ph-preselected-service-title'/>
        </div>
        <div className="placeholder-row">
            <RectShape className='ph-preselected-service-body' />
        </div>
    </div>
);

export default preselectedServiceListPlaceholder;
