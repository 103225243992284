import Yup from "./yup";

export default {
    mapPropsToValues: ({ user, agbAgreement }) => {
        let validate = {
            phone: user.phone,
            agb_agreement: agbAgreement
        };

        if (!user.first_name) {
            validate.first_name = '';
        }

        if (!user.last_name) {
            validate.last_name = '';
        }

        return validate;
    },
    validationSchema: ({ user, t }) => {
        const validate = {
            phone: Yup.string()
                .required()
                .matches(/^(\+)?\d+$/)
                .min(6)
                .label(t('validations.label.phone')),

            agb_agreement: Yup.bool()
                .test('consent', t('validations.default.boolean.required'), value => value === true)
                .label(t('validations.label.agb_agreement'))
        };

        if (!user.first_name) {
            validate.first_name = Yup.string()
                .required()
                .nullable()
                .label(t('validations.label.firstname'));
        }

        if (!user.last_name) {
            validate.last_name = Yup.string()
                .required()
                .nullable()
                .label(t('validations.label.lastname'));
        }

        return Yup.object().shape(validate);
    },
    handleSubmit(values, formikBag) {
        const { userUpdate, formSubmittingBooking } = formikBag.props;

        formSubmittingBooking(true);
        userUpdate(values);
    }
}
