import React, { Component } from 'react';
import './Alert.scss';
import { connect } from "react-redux";
import { removeError } from "../../../store/actions/error.actions";

class Alert extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: props.id,
            isVisible: props.isVisible,
            message: props.message,
            type: props.type
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            message: nextProps.message,
            isVisible: nextProps.isVisible,
            type: nextProps.type
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.isGlobal === true && this.state.isVisible === true) {
            setTimeout(() => {
                this.setState({
                    isVisible: false,
                    message: ''
                });
            }, 2500);
        }
    }

    handleClick = () => {
        this.setState({
            isVisible: !this.state.isVisible,
            message: ''
        }, () => {
            this.props.removeError(this.state.id);
        });
    };

    render() {
        const { isGlobal, type, disableCloseBtn } = this.props;
        const { isVisible, message } = this.state;

        if (!isVisible) return null;

        return (
            this.state.isVisible && (
                <div
                    className={`alert alert--${type.toLowerCase()} ${
                        isGlobal ? 'alert--fixed' : ''
                        }`}
                >
                    <p>{message}</p>
                    {!disableCloseBtn && (
                        <i className="alert__close-btn icon-cross" onClick={this.handleClick} />
                    )}
                </div>
            )
        );
    }
}

export default connect(
    null, {
        removeError
    }
)(Alert);

