export const GLOBAL = '[Global]';

export const GLOBAL_BALOISE_LINK_SET = `${GLOBAL} BALOISE_LINK_SET`;
export const SELECT_DROPDOWN_OPENED = `${GLOBAL} SELECT_DROPDOWN_OPENED`;
export const GLOBAL_CHECK_ANIMATION_SET = `${GLOBAL} CHECK_ANIMATION_SET`;
export const GLOBAL_SERVICE_FILTER_SET_FOCUS = `${GLOBAL} SERVICE_FILTER_SET_FOCUS`;
export const GLOBAL_UPTO_USER_FIRST_NAME = `${GLOBAL} GLOBAL_UPTO_USER_FIRST_NAME`;

export const setBaloiseLink = link => ({
    type: GLOBAL_BALOISE_LINK_SET,
    payload: {
        link
    }
});

export const selectDropdownOpened = isOpened => ({
    type: SELECT_DROPDOWN_OPENED,
    payload: {
        isOpened
    }
});

export const setCheckAnimation = (id, isVisible) => ({
    type: GLOBAL_CHECK_ANIMATION_SET,
    payload: {
        id,
        isVisible
    }
});

export const serviceFilterFocused = isFocused => ({
    type: `${GLOBAL_SERVICE_FILTER_SET_FOCUS}`,
    payload: {
        isFocused
    }
});

export const uptoUserFirstName = firstName => ({
    type: `${GLOBAL_UPTO_USER_FIRST_NAME}`,
    payload: {
        firstName
    }
});
