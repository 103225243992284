import React, {Component} from 'react';
import GarageItem from '../../components/shared/GarageItem/GarageItem';
import './GarageListContainer.scss';
import {selectGarage} from '../../store/actions/garage.actions';
import {connect} from 'react-redux';
import Button from '../../components/shared/Button/Button';
import {getValueFromArray} from '../../utils/car';
import {garageListViews, getGarageList} from "../../store/actions/garageList.actions";
import {setDialog} from "../../store/actions/dialog.actions";

import {translate} from "react-i18next";
import Alert from "../../components/shared/Alert/Alert";
import {NUMBER_OF_GARAGES_PER_VIEW} from "../../store/consts/garages.constants";
import classNames from "classnames";
import GarageItemDisabled from "../../components/shared/GarageItem/GarageItemDisabled";
import {DIALOG_SAVE_GARAGE_LIST} from "../../store/consts/dialog.constants";
import Dialog from "../../components/dialogs/Dialog/Dialog";
import {setCheckAnimation} from "../../store/actions/global.actions";
import {CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL} from "../../store/consts/global";
import imagesPath from "../../utils/imagesPath";
import IllustrationCard from "../../components/shared/IllustrationCard";
import {applyPredefinedGarageDiscount} from "../../store/selectors/predefinedGarage";
import {voucherHideElements} from "../../utils/voucherHideElements";
import BaloiseLink from "../../components/shared/BaloiseLink";
import {onSortChange} from "../../store/actions/sort.actions";
import {existInLocalStorage} from "../../utils/storage";
import {STORAGE_AXA_TOKEN} from "../../app/consts/storage.consts";


const NUMBER_OF_GARAGE_ITEMS_TO_SHOW = 4;
const GARAGE_LIST_START_POSITION = 0;

class GarageListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedItem: {
                index: null,
                isExpanded: null
            },
            list: [],
            indexPositionList: GARAGE_LIST_START_POSITION
        };
    }

    componentDidMount() {
        if (this.props.predefinedGarage && this.props.predefinedGarage.price) {
            this.setState({
                predefinedGarageExpanded: !!(this.props.predefinedGarage.price)
            });
        }

        this.setInitialListItems(this.props.list);
        window.addEventListener('scroll', this.loadMoreGaragesOnScroll);
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (JSON.stringify(this.props.list) !== JSON.stringify(nextProps.list)) {
            this.setInitialListItems(nextProps.list)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMoreGaragesOnScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.list < this.state.list) {
            this.props.garageListViews(this.state.list.map(item => item['id']));
        }

        if (JSON.stringify(prevState.list) !== JSON.stringify(this.state.list)) {
            this.props.onSortChange(this.props.sort.find(option => option.active === true));
        }
    }

    loadMoreGaragesOnScroll = () => {
        let body = document.body, docElement = document.documentElement;
        let scrollHeight = Math.max(body.scrollHeight, body.offsetHeight, docElement.clientHeight, docElement.scrollHeight, docElement.offsetHeight);

        if (window.pageYOffset + body.offsetHeight > scrollHeight - scrollHeight * 0.1) {
            this.handleLoadMore();
        }
    };

    setInitialListItems = list => {
        this.setState({
            list: list.slice(GARAGE_LIST_START_POSITION, NUMBER_OF_GARAGE_ITEMS_TO_SHOW)
        });
    };

    getNextIndexPosition = index => {
        return index + NUMBER_OF_GARAGE_ITEMS_TO_SHOW;
    };

    handleLoadMore = () => {
        const {list} = this.props;

        this.setState(
            {
                indexPositionList: this.getNextIndexPosition(this.state.indexPositionList)
            },
            () => {
                this.setState({
                    list: list.slice(0, this.getNextIndexPosition(this.state.indexPositionList))
                });
            }
        );
    };

    shouldRenderAd = index => {
        const listLength = this.state.list.length;

        return (index === NUMBER_OF_GARAGES_PER_VIEW) || (listLength <= NUMBER_OF_GARAGES_PER_VIEW && (index === listLength));
    };


    renderLoadButton = () => {
        if ({...this.state}.list.length === this.props.list.length) return null;

        const {t} = this.props;

        return (
            <div className="garage-list__load-more">
                <Button
                    size="md"
                    type="garage-load-more"
                    icon="icon-add"
                    onClick={this.handleLoadMore}
                >
                    {t('pages.services.garages_load_more')}
                </Button>
            </div>
        );
    };

    renderEmptyList = () => {
        const {t} = this.props;

        return (
            <div className="garage-list">
                <IllustrationCard
                    title={t('global.illustration_card.no_garages_nearby.title')}
                    description={t('global.illustration_card.no_garages_nearby.description')}
                    imagePath={imagesPath('payment.png')}
                />
            </div>
        );
    };

    renderPredefinedGarage = (make) => {
        const {predefinedGarage, global, selectGarage} = this.props;
        const {list} = this.state;

        if (predefinedGarage) {
            return (
                <React.Fragment>
                    <div className="garage-list-container garage-list-container--has-predefined-garage">
                        {!predefinedGarage.disabled ?
                            <GarageItem
                                item={predefinedGarage}
                                onItemSelect={item => selectGarage(item)}
                                make={make && make.name}
                                isExpanded={this.state.predefinedGarageExpanded}
                                hideAxaPartner={this.hideAxaPartner()}
                                onExpandItem={() => {
                                    this.setState({
                                        predefinedGarageExpanded: !this.state.predefinedGarageExpanded
                                    })
                                }
                                }
                            /> :
                            <GarageItemDisabled
                                item={predefinedGarage}
                                make={make && make.name}
                            />
                        }
                        {list.length === 0 ? (
                            <BaloiseLink link={global.baloiseLink}/>
                        ) : null}
                    </div>
                </React.Fragment>
            )

        }
    };

    handleExpandItem = (index, isExpanded) => {
        this.setState({
            expandedItem: {
                index,
                isExpanded
            }
        });
    };

    hideAxaPartner = () => {
        const {voucher} = this.props;

        return voucher && voucher.hide_voucher_partner;
    }

    render() {
        const {list} = this.state;
        const {t, selectGarage, car, global, error, predefinedGarage, dialog, setCheckAnimation, user} = this.props;
        const make = getValueFromArray(car.makes, 'id', car.make_id);

        if (!!error)
            return <Alert id={error && error.id} message={error && error.message} type='danger' isVisible={true}
                          disableCloseBtn/>;

        if (list.length === 0 && !predefinedGarage)
            return this.renderEmptyList();

        return (
            <React.Fragment>


                {dialog.saveGarageList ? (
                    <Dialog
                        type={DIALOG_SAVE_GARAGE_LIST}
                        payload={{
                            email: user.logged ? user.email : '',
                            t
                        }}
                        onCloseAction={() => {
                            setCheckAnimation(
                                CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL,
                                false
                            )
                        }
                        }/>
                ) : null}
                <div className="garage-list">

                    {this.renderPredefinedGarage(make)}

                    <div className={classNames("garage-list-container", {
                        'garage-list-container--has-predefined-garage': predefinedGarage
                    })}>
                        {list &&
                        list.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <GarageItem
                                        index={index}
                                        item={item}
                                        onItemSelect={item => selectGarage(item)}
                                        make={make && make.name}
                                        isExpanded={(this.state.expandedItem.index === index && this.state.expandedItem.isExpanded)}
                                        onExpandItem={this.handleExpandItem}
                                        hideAxaPartner={this.hideAxaPartner()}
                                    />
                                    {!voucherHideElements() && this.shouldRenderAd(index + 1) && !existInLocalStorage(STORAGE_AXA_TOKEN) ? (
                                        <BaloiseLink link={global.baloiseLink}/>
                                    ) : null}
                                </React.Fragment>
                            );
                        })
                        }
                    </div>

                    {this.renderLoadButton()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        garage: state.garage,
        car: state.car,
        global: state.global,
        predefinedGarage: applyPredefinedGarageDiscount(state),
        garageDiscount: state.garageDiscount,
        dialog: state.dialog,
        user: state.user,
        services: state.services,
        filters: state.filters,
        voucher: state.voucher,
        sort: state.sort,
    };
};

export default connect(
    mapStateToProps,
    {
        selectGarage,
        garageListViews,
        setDialog,
        setCheckAnimation,
        getGarageList,
        onSortChange
    }
)(translate('translations')(GarageListContainer));
