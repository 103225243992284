import React, {Component} from 'react';
import './Filter.scss';
import {translate} from "react-i18next";
import onClickOutside from "react-onclickoutside";
import SelectedFilterList from "./SelectedFilterList";

class FilterBar extends Component {

    handleClickOutside = () => {
    };

    render() {
        const {onDropdownOpen, t} = this.props;

        return (
            <div className="filter-bar clearfix">
                <div
                    className="filter-bar__title"
                    onClick={onDropdownOpen}
                >
                    <span className="icon icon-filter"/>
                    <p>{t('global.filter.title')}</p>
                </div>
                <SelectedFilterList/>
                <div className="filter-bar__open" onClick={onDropdownOpen}>
                    <span className="icon icon-chevron-down"/>
                </div>
            </div>
        )
    }
}

export default translate('translations')(onClickOutside(FilterBar));
