export const TESTIMONIALS = '[Testimonials]';

export const TESTIMONIALS_SET = `${TESTIMONIALS} SET`;

export const setTestimonials = list => ({
    type: TESTIMONIALS_SET,
    payload: {
        list
    }
});
