import routes from '../../index';
import history from '../../../routes/history';
import React from "react";
import {voucherHideElements} from "../../../utils/voucherHideElements";

export const VoucherCheckStep = (Component) => {
    return class extends React.Component {

        constructor(props) {
            super(props);

            this.shouldRender = true;

            if(voucherHideElements()){
                this.shouldRender = false;
                history.replace(routes[1].path);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default VoucherCheckStep;
