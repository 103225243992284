export const PREDEFINED_GARAGE_TOKEN = '[Predefined Garage Token]';

export const PREDEFINED_GARAGE_TOKEN_SET = `${PREDEFINED_GARAGE_TOKEN} SET`;
export const PREDEFINED_GARAGE_TOKEN_RESET = `${PREDEFINED_GARAGE_TOKEN} RESET`;

export const setPredefinedGarageToken = garageToken => ({
    type: PREDEFINED_GARAGE_TOKEN_SET,
    payload: {
        garageToken
    }
});

export const resetPredefinedGarageToken = () => ({
    type: PREDEFINED_GARAGE_TOKEN_RESET,
});
