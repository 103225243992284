import {isFormOnly} from "../utils/form";

let history = require('history');
let historyRoute;

if (isFormOnly()) {
    historyRoute = history.createBrowserHistory();
} else {
    historyRoute = history.createHashHistory();
}

export default historyRoute;
