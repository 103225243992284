import { API_ERROR, API_SUCCESS, apiRequest } from '../../actions/api.actions';
import {
    CAR_DATA_CHANGE, CAR_FETCH_LICENCE_PLATE,
    CAR_FETCH_SERVICES, CAR_IFRAME_SUBMIT, CAR_MULTIPLE_VEHICLE_CHOOSE, carResetFieldsBellow, carUpdateField,
    carUpdateObject
} from '../../actions/car.actions';
import {setLoader, setLoaders} from '../../actions/loader.actions';
import { LOADER_API_GET_CAR, LOADER_FORM_VEHICLE, LOADER_LICENCE_PLATE } from '../../consts/loader.constants';
import { STORAGE_OFFER_TOKEN } from '../../../app/consts/storage.consts';
import { setSessionStorageItem } from '../../../utils/storage';
import history from '../../../routes/history';
import { resetService } from '../../actions/services.actions';
import pages from '../../../app/consts/routes';
import { resetGarageList } from "../../actions/garageList.actions";
import { addFlashMessage } from "../../actions/flashMessage.actions";
import { FLASH_MESSAGE_ERROR } from "../../consts/flashMessage.constants";
import store from '../../store';
import { formSubmittingVehicle } from "../../actions/formSubmitting.actions";
import {changeStepDataIframe} from "../../actions/step.actions";
import {carStepSet, carStepUpdate} from "../../actions/carStep.actions";
import {FORM_VEHICLE_TYPE_MANUAL_1} from "../../../app/consts/vehicle";
import {setLicencePlateAttempts} from "../../actions/licencePlateAttempts";
import {updateUrl, urlSearchParams} from "../../../utils/common";

export const CAR_URL = 'get-car';
export const CAR_MULTIPLE_VEHICLE_CHOOSE_URL = 'multiple-vehicle-choose';
export const CAR_LICENCE_PLATE_URL = 'get-licence-plate';

export const carMiddleware = ({ dispatch }) => next => {
    return action => {
        next(action);

        switch (action.type) {

            case CAR_DATA_CHANGE:
                dispatch(carUpdateField(action.payload.name, action.payload.value));

                if (action.payload.shouldCallAPI === true) {
                    dispatch(setLoader(LOADER_API_GET_CAR, true));
                    dispatch(carResetFieldsBellow(action.payload.name));
                    dispatch(
                        apiRequest({
                            body: store.getState().car,
                            method: 'POST',
                            url: CAR_URL,
                            signature: CAR_DATA_CHANGE
                        })
                    );
                }

                break;

            case `${CAR_DATA_CHANGE} ${API_SUCCESS}`:
                setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token);
                if(urlSearchParams('token')){
                    window.history.replaceState(null, null, updateUrl('token', action.payload.data.car.token))
                }

                dispatch(carUpdateObject(action.payload.data.car));
                dispatch(setLoader(LOADER_API_GET_CAR, false));
                dispatch(setLoaders(LOADER_FORM_VEHICLE, false));
                break;

            case `${CAR_DATA_CHANGE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_API_GET_CAR, false));
                break;

            case CAR_FETCH_SERVICES:
                dispatch(formSubmittingVehicle(true));
                dispatch(
                    apiRequest({
                        body: action.payload.car,
                        method: 'POST',
                        url: CAR_URL,
                        signature: CAR_FETCH_SERVICES
                    })
                );
                break;

            case `${CAR_FETCH_SERVICES} ${API_SUCCESS}`:
                dispatch(resetService());
                dispatch(resetGarageList());
                history.push(pages.services);
                dispatch(formSubmittingVehicle(false));
                break;

            case `${CAR_FETCH_SERVICES} ${API_ERROR}`:
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));
                dispatch(formSubmittingVehicle(false));
                break;

            case CAR_IFRAME_SUBMIT:
                dispatch(
                    apiRequest({
                        body: action.payload.car,
                        method: 'POST',
                        url: CAR_URL,
                        signature: CAR_IFRAME_SUBMIT
                    })
                );
                break;

            case `${CAR_IFRAME_SUBMIT} ${API_SUCCESS}`:
                dispatch(changeStepDataIframe(2));
                break;

            case `${CAR_IFRAME_SUBMIT} ${API_ERROR}`:
                // TODO
                break;

            case CAR_FETCH_LICENCE_PLATE:
                dispatch(setLoader(LOADER_LICENCE_PLATE, true));

                dispatch(
                    apiRequest({
                        body: store.getState().car,
                        method: 'POST',
                        url: CAR_LICENCE_PLATE_URL,
                        signature: CAR_FETCH_LICENCE_PLATE
                    })
                );
                break;

            case `${CAR_FETCH_LICENCE_PLATE} ${API_SUCCESS}`:

                if(action.payload.data.status === -1){
                    dispatch(carUpdateObject(action.payload.data.car));
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.data.message));
                }
                else{
                    if(action.payload.data.car.color_list.length === 0){
                        setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token);
                        if(urlSearchParams('token')){
                            window.history.replaceState(null, null, updateUrl('token', action.payload.data.car.token))
                        }
                    }
                    dispatch(carUpdateObject(action.payload.data.car));
                    dispatch(carStepUpdate(action.payload.data.car, action.payload.data.message));
                }

                dispatch(setLicencePlateAttempts(action.payload.data.attempts));

                dispatch(setLoader(LOADER_LICENCE_PLATE, false));
                break;

            case `${CAR_FETCH_LICENCE_PLATE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false));
                break;

            case CAR_MULTIPLE_VEHICLE_CHOOSE:
                dispatch(setLoader(LOADER_LICENCE_PLATE, true));
                dispatch(
                    apiRequest({
                        body: store.getState().car,
                        method: 'POST',
                        url: CAR_MULTIPLE_VEHICLE_CHOOSE_URL,
                        signature: CAR_MULTIPLE_VEHICLE_CHOOSE
                    })
                );
                break;

            case `${CAR_MULTIPLE_VEHICLE_CHOOSE} ${API_SUCCESS}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false));

                if(action.payload.data.status === -1){
                    dispatch(carUpdateObject(action.payload.data.car));
                    dispatch(carStepSet(FORM_VEHICLE_TYPE_MANUAL_1, true, action.payload.data.message));
                }
                else{
                    setSessionStorageItem(STORAGE_OFFER_TOKEN, action.payload.data.car.token);
                    if(urlSearchParams('token')){
                        window.history.replaceState(null, null, updateUrl('token', action.payload.data.car.token))
                    }

                    dispatch(carUpdateObject(action.payload.data.car));
                    dispatch(carStepUpdate(action.payload.data.car, action.payload.data.message));
                }

                break;

            case `${CAR_MULTIPLE_VEHICLE_CHOOSE} ${API_ERROR}`:
                dispatch(setLoader(LOADER_LICENCE_PLATE, false));
                break;

            default:
                break;
        }
    };
};
