//@flow
import React, { Component } from 'react';
import './CheckoutPayment.scss';
import {translate} from "react-i18next";
import imagesPath from "../../../utils/imagesPath";
import {voucherHideElements} from "../../../utils/voucherHideElements";

class CheckoutPayment extends Component<{}, {}> {

    render() {
        const { t } = this.props;

        const extraClass = (this.props.extraClass) ? `checkout-payment--${this.props.extraClass}` : '';

        if(voucherHideElements()){
            return null;
        }

        return (
            <div className={`w-box ${extraClass}`}>
                <div className="w-box-header"><h3 className="w-box-header__title">{t('pages.garage.checkoutPaymentTitle')}</h3></div>
                <div className="w-box__body">
                  <div className="checkout-payment__text">
                      <span dangerouslySetInnerHTML={{__html: t('pages.garage.checkoutPaymentDescription')}}></span>
                  </div>
                  <div className="checkout-payment__animation">
                      <img src={imagesPath('payment.svg')} alt="Payment info"/>
                  </div>
              </div>
            </div>
        );
    }
}

export default translate('translations')(CheckoutPayment);
