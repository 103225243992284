import {
    FORM_GARAGE_RESET,
    FORM_GARAGE_SET_IS_DATE_PICKER_VALID,
    FORM_GARAGE_SET_IS_SUBMITTED
} from '../actions/formGarage.actions';
import formGarage from '../models/formGarage';

export default function(state = formGarage, action) {
    switch (action.type) {
        case FORM_GARAGE_SET_IS_SUBMITTED:
            return {
                ...state,
                isFormSubmitted: action.payload.isFormSubmitted
            };

        case FORM_GARAGE_SET_IS_DATE_PICKER_VALID:
            return {
                ...state,
                isDatePickerValid: action.payload.isDatePickerValid
            };

        case FORM_GARAGE_RESET:
            return formGarage;

        default:
            return state;
    }
}
