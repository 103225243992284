//@flow

import React, {Component} from 'react';
import './ZipPicker.scss';
import onClickOutside from 'react-onclickoutside';
import Input from '../Input/Input';
import {compare} from '../../../utils/common';
import {withFormik} from "formik";
import {ZIP_NUMBER_LENGTH} from "../../../store/consts/zipPicker.constants";
import * as Yup from "yup";
import {translate} from "react-i18next";
import Select from "../Select/Select";
import connect from "react-redux/es/connect/connect";
import {clearZipData, fetchZipData} from "../../../store/actions/zipData.actions";
import {makeInputNumericOnly} from "../../../utils/common";

class ZipPicker extends Component<{}, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpened: false
        };
    }

    componentDidMount(): void {
        if (typeof this.props.selected !== 'undefined') {
            this.handleZipSelect(this.props.selected);
        }
        makeInputNumericOnly(document.getElementById(this.props.id), value => {
            return /^\d*$/.test(value);
        });
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        // const prevPropsFilter = this.props.values.filter;
        // const nextPropsFilter = nextProps.values.filter;
        if (this.props.shouldClearZipInputText !== nextProps.shouldClearZipInputText && nextProps.shouldClearZipInputText === true) {
            // this.props.clearZipData();
            this.props.setValues({
                selected: null,
                filter: ''
            })
        }

        if (this.props.forceValidate !== nextProps.forceValidate)
            this.setState({
                error: this.getError()
            });

        if (nextProps.values.filter.length !== ZIP_NUMBER_LENGTH)
            this.props.onZipDataClear();

        if (this.props.values.filter !== nextProps.values.filter && nextProps.values.filter.length === ZIP_NUMBER_LENGTH)
            this.props.onReadyForFetchData(nextProps.values.filter);

        else if (nextProps.values.filter !== 'undefined' && this.props.values.filter !== nextProps.values.filter && nextProps.values.filter.length !== ZIP_NUMBER_LENGTH)
            this.props.onReadyForFetchData(null);

        if (compare(this.props.list, nextProps.list) && this.props.values.selected === null && nextProps.list.length > 1)
            this.setState({
                isOpened: false,
                error: ''
            });

        /* automatically select option if there is only 1 item in list */
        if (compare(this.props.list, nextProps.list) && nextProps.list.length === 1) {
            this.setState({
                isOpened: false,
                error: ''
            }, () => {
                // const selected = nextProps.list[0];
                // this.handleZipSelect(selected);
            });
        }

        if (typeof nextProps.selected !== 'undefined' && nextProps.values.selected === null) {
            this.handleZipSelect(nextProps.selected);
        }
    }

    handleClickOutside = () => {
        setTimeout(() => {
            this.setState({
                isOpened: false,
                error: this.getError()
            });
        }, 0);
    };

    handleZipSelect = zip => {
        const {onZipSelect, setValues, values} = this.props;

        this.setState({
            isOpened: false,
            error: ''
        }, () => {
            setValues({
                selected: zip,
                filter: values.filter
            });
            if (Object.keys(zip).length) {
                onZipSelect(zip);
            }

        });
    };

    handleTextChange = e => {
        const {setValues, onChange} = this.props;

        if (e.target instanceof HTMLInputElement) {
            e.persist();
            this.setState({
                error: ''
            });
            setValues({
                filter: e.target.value,
                selected: null
            });
            if (onChange) {
                onChange(e);
            }
        }
    };

    handleTextClear = () => {
        const {onZipClear, setValues} = this.props;

        this.setState({
            isOpened: false,
            error: ''
        }, () => {
            clearZipData();
            onZipClear();
            setValues({
                filter: '',
                selected: null
            });
        });
    };

    handleInputClick = () => {
        const {id, list} = this.props;

        document.getElementById(id).select();

        if (list.length > 0) {
            this.setState({
                isOpened: true
            });
        }
    };

    resultNotFound = () => {
        const {list, values: {filter}} = this.props;
        return filter.length === ZIP_NUMBER_LENGTH && list.length === 0;
    };

    getError = () => {
        const {t, touched, values, list, error, errors, isRequired} = this.props;

        /* check for schema errors */
        if (touched.filter && errors.filter)
            return errors.filter;

        /* if searched but didn't get any result in the list */
        else if (values.filter !== '' && values.selected === null && list.length === 0 && values.filter.length >= 4)
            return t('validations.label.zip_not_found', {name: values.filter});

        /* if searched but didn't select any zip code from the list */
        else if (touched.filter && values.filter !== '' && values.selected === null && values.filter.length <= 4)
            return t('validations.label.zip_not_valid');

        /* if touched and value didn't change (string is empty) */
        else if (touched.filter && values.filter === '' && isRequired === true)
            return t('validations.label.zip_required');

        /* if there is an error from the parent component */
        else if (error)
            return error;

        return false;
    };

    render() {
        const {error: zipPickerError} = this.state;
        const {t, id, label, values, list, handleBlur, hide, isZipDataListLoading} = this.props;

        if (hide === true)
            return null;

        return (
            <div className="zp-container">
                <div className="zp-header">
                    <Input
                        type="tel"
                        placeholder={t('form.placeholder.zip')}
                        id={id}
                        name='filter'
                        label={label}
                        value={(values.selected && values.selected.zip) || values.filter}
                        iconRightClassName="icon-cross-circle"
                        onChange={e => this.handleTextChange(e)}
                        onBlur={handleBlur}
                        onClick={this.handleInputClick}
                        onTextClear={this.handleTextClear}
                        error={zipPickerError}
                        hideErrorMessage
                        maxLength={4}
                    />
                    <Select
                        id="city"
                        list={list}
                        label={t('form.label.city')}
                        onChange={(id, option) => this.handleZipSelect(option)}
                        onHasOneOption={(fieldId, option) => this.handleZipSelect(option)}
                        error={zipPickerError}
                        disabled={!list.length}
                        isLoading={isZipDataListLoading}
                        selected={values.selected && values.selected.id}
                        hideErrorMessage
                        openListOnMultipleOptions
                    >
                        {t('form.placeholder.city')}
                    </Select>
                </div>

                <div className="zp-error clearfix">
                    <p>{zipPickerError}</p>
                </div>
            </div>
        );
    }
}

const FormikZipPicker = withFormik({

    mapPropsToValues(props) {
        return {
            filter: props.value || '',
            selected: props.value || null
        }
    },

    validationSchema() {
        return Yup.object().shape({
            filter: Yup.number()
                .typeError(this.props.t('validations.label.zip_must_be_number'))
        });
    }

})(translate('translations')(onClickOutside(ZipPicker)));

const mapStateToProps = state => {
    return {
        zipData: state.zipData,
        zip: state.zip
    }
};

export default connect(mapStateToProps, {
    clearZipData,
    fetchZipData
})(FormikZipPicker);
