import {
    SERVICES_FETCH_ALL, SERVICES_RESET,
    SERVICES_SET,
    SERVICES_TOGGLE_OFFICIAL_SELECTION, SERVICES_UNCHECK, SERVICES_UNCHECK_ALL,
    SERVICES_UPDATE_LABOUR
} from '../actions/services.actions';
import services from '../models/services';

export default function(state = services, action) {
    const stateCopy = { ...state };

    switch (action.type) {
        case SERVICES_FETCH_ALL:
            return state;

        case SERVICES_SET:
            return {
                ...state,
                ...action.payload.data.services
            };

        case SERVICES_TOGGLE_OFFICIAL_SELECTION:
            stateCopy.official[stateCopy.official.selected].forEach(service => {
                service.selected = action.payload.selected;
            });

            return stateCopy;

        case SERVICES_UPDATE_LABOUR:
            stateCopy.labour[action.payload.serviceType] = stateCopy.labour[
                action.payload.serviceType
            ].map(service => {
                if (service.id === action.payload.service.id || (action.payload.service.original_id && service.id === action.payload.service.original_id)) service = action.payload.service;

                return service;
            });

            return stateCopy;

        case SERVICES_UNCHECK:
            stateCopy.labour[action.payload.serviceType] = stateCopy.labour[
                action.payload.serviceType
            ].map(service => {
                if (service.id === action.payload.serviceId){
                    service.selected = false;
                    service.is_official_selected = false;

                    service.siblings.forEach(sibling =>{
                        sibling.selected = false;
                    })
                }

                return service;
            });

            return stateCopy;

        case SERVICES_UNCHECK_ALL:
            return {
                ...state,
                labour: {
                    search: [
                        ...state.labour.search.map(service => {
                            return (service.is_official_selected) ? service : {
                                ...service,
                                selected: false
                            };
                        })
                    ],
                    custom: [
                        ...state.labour.custom.map(service => {
                            return (service.is_official_selected) ? service : {
                                ...service,
                                selected: false
                            };
                        })
                    ]
                }
            };

        case SERVICES_RESET:
            return services;

        default:
            return state;
    }
}
