export const PREDEFINED_GARAGE = '[Predefined Garage]';

export const PREDEFINED_GARAGE_SET = `${PREDEFINED_GARAGE} SET`;

export const setPredefinedGarage = garage => ({
    type: PREDEFINED_GARAGE_SET,
    payload: {
        garage
    }
});
