//@flow
import React, { Component } from 'react';
import './DialogServiceDetailView.scss';

class DialogServiceDetailView extends Component<{}, {}> {
    render() {
        const { payload } = this.props;

        return (
            <React.Fragment>
                <div className="modal__header">
                    <h5>{payload.title}</h5>
                </div>
                <div className="modal__body modal-service">
                    {payload.description && (
                        <p className="modal-service__description">{payload.description}</p>
                    )}

                    {payload.list && payload.list.length > 0 ? (
                        <div className="modal-service__list">
                            {payload.list.map(item => (
                                <p className="modal-service__sibling-item" key={item.id}>
                                    - {item.name}
                                </p>
                            ))}
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default DialogServiceDetailView;
