import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';
import middleware from './middleware';
import { STORE_RESET } from './actions/store.actions';
import models from './models/models';
import persistReset from './models/persistReset';

const enhancer = composeWithDevTools(applyMiddleware(...middleware.feature, ...middleware.core));
const store = combineReducers(reducer);

const rootReducer = ( state, action ) => {
    if (action.type === STORE_RESET) {
        state = {...models, ...persistReset(state)};
    }

    return store(state, action)
};

export default createStore(rootReducer, {}, enhancer);
