//@flow
import React, {Component} from 'react';
import './CheckoutDetail.scss';
import {translate} from "react-i18next";
// import discount from "../../../utils/discount";
import {getValueFromArray} from "../../../utils/car";
import VoucherInput from "../VoucherInput/VoucherInput";
import {voucherHideElements} from "../../../utils/voucherHideElements";
import roundCeilPrice from "../../../utils/roundCeilPrice";

type Props = {};

type State = {};

class CheckoutDetail extends Component<Props, State> {
    shouldRender = () => {
        const {list} = this.props;
        return list && list.length > 0;
    };

    render() {
        const {t, list, garage, garageDiscount, car, voucher} = this.props;

        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);

        return (
            <div className="checkout-detail">

                <div className="checkout-vehicle">
                    <div className="row">
                        <div className="col-md-5 col-sm-5 col-xs-5 checkout-vehicle__make">
                            {make.name} {range.name}
                        </div>
                        {!voucherHideElements() &&
                        <div className="col-md-3 col-sm-3 col-xs-3 checkout-vehicle__total-price">
                            {t('global.currency')} {garage.totalPrice}
                        </div>
                        }
                    </div>
                </div>

                <div className="checkout-item-title">
                    <p>{t('pages.garage.includes')}:</p>
                </div>
                {this.shouldRender
                    ? list.map((item, index) => (
                        <div className="checkout-item" key={index}>
                            <p>{item.nameAlternative ? item.nameAlternative : item.name}</p>
                            {item.hasOwnProperty('siblings') &&
                            item.siblings.map(sibling => {
                                return (
                                    <div className="checkout-item--sub" key={sibling.id}>
                                        - {sibling.name}
                                    </div>
                                );
                            })}
                        </div>
                    ))
                    : null}

                {!voucherHideElements() &&
                <React.Fragment>

                    {garageDiscount ?
                        <div className="checkout-item checkout-item--border-and-margin">
                            <div className="garage-list-discount garage-list-discount--checkout">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-5">
                                        <div
                                            className="garage-list-discount__headline garage-list-discount--checkout__headline">
                                            <i className="icon-check-circle-full"/> {t('pages.garage.ab_test_discount_checkout.title')}
                                        </div>
                                        <div
                                            className="garage-list-discount__label garage-list-discount--checkout__label">{t('pages.garage.ab_test_discount_checkout.value', {discount: garageDiscount})}</div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-xs-3">
                                        <div
                                            className="garage-list-discount__value garage-list-discount--checkout__value">-{garageDiscount}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        (voucher && voucher.hide_input) ? null :
                            <div className="checkout-item checkout-item--border-and-margin">
                                <VoucherInput/>
                            </div>
                    }

                    {voucher && garage.discount ?
                        <React.Fragment>
                            <div className="checkout-item checkout-item--border-and-margin">
                                <div className="checkout-item__name checkout-item--border-and-margin__name">
                                    <p>{t('global.total')}</p>
                                </div>
                                <div className="checkout-item__price checkout-item--border-and-margin__price">
                                    <p>{t('global.currency')} {garage.price}</p>
                                </div>
                            </div>

                            <div className="checkout-item">
                                <div className="checkout-item__name checkout-item--border-and-margin__name">
                                    <p>{t('global.discount')}</p>
                                </div>
                                <div className="checkout-item__price checkout-item--border-and-margin__price">
                                    <p>- {t('global.currency')} {roundCeilPrice(garage.discount)}</p>
                                </div>
                            </div>
                        </React.Fragment>
                        : null
                    }

                    {garageDiscount &&
                    <React.Fragment>
                        <div className="checkout-item checkout-item--border-and-margin">
                            <div className="checkout-item__name checkout-item--border-and-margin__name">
                                <p>{t('global.total')}</p>
                            </div>
                            <div className="checkout-item__price checkout-item--border-and-margin__price">
                                <p>{t('global.currency')} {garage.price}</p>
                            </div>
                        </div>

                        <div className="checkout-item">
                            <div className="checkout-item__name checkout-item--border-and-margin__name">
                                <p>{t('global.discount')}</p>
                            </div>
                            <div
                                className="checkout-item__price checkout-item__price--garage-discount checkout-item--border-and-margin__price">
                                <p>- {t('global.currency')} {roundCeilPrice(garage.discount)}</p>
                            </div>
                        </div>
                    </React.Fragment>
                    }

                    <div className="checkout-item checkout-item--border-and-margin">
                        <div className="checkout-item__name checkout-item--border-and-margin__name">
                            <p>{t('global.tax')}</p>
                        </div>
                        <div className="checkout-item__price checkout-item--border-and-margin__price">
                            <p>{t('global.currency')} {garage.tax}</p>
                        </div>
                    </div>

                    <div className="checkout-item checkout-item--sum">
                        <div className="checkout-item__name checkout-item--sum__name">
                            <p>
                                <strong>{t('global.total_with_vat')}</strong>
                            </p>
                        </div>
                        <div className="checkout-item__price checkout-item--sum__price">
                            <p>{t('global.currency')} {garage.totalPrice}</p>
                        </div>
                    </div>
                </React.Fragment>
                }
            </div>
        );
    }
}

export default translate('translations')(CheckoutDetail);
