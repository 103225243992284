import React, {Component} from 'react';
import './GarageItem.scss';
import {translate} from "react-i18next";
import imagesPath from "../../../utils/imagesPath";

class GarageItemDisabled extends Component {

    getDisabledMessage = () => {
        const {t, item, make} = this.props;

        if(item.disabled === 'out-of-make')
            return t('pages.garage.predefined.out_of_make', {make: make});

        return t('pages.garage.predefined.inactive', {garageName: item.name});
    };

    render() {
        const { item } = this.props;

        if (!item) return null;

        return (
            <div className="col-xs-8 pgid-container">
                <div className="col-xs-6 col-sm-7 col-md-6 pgid-details">
                    <div className="pgid-avatar">
                        <img src={item.logo} alt={item.name}/>
                    </div>
                    <div className="pgid-name">
                        <h5>{item.name}</h5>
                        <p>{this.getDisabledMessage()}</p>
                    </div>
                </div>
                <div className="col-xs-2 col-sm-1 col-md-2 pgid-image">
                    <img src={imagesPath('preselected-garage-illsutration.png')} alt=""/>
                </div>
            </div>
        );
    }
}

export default translate('translations')(GarageItemDisabled);
