//@flow
import React, {Component} from 'react';
import {getValueFromArray} from '../../../utils/car';
import './CarDetail.scss';
import {getServiceImgPath} from '../../../utils/service';
import pages from "../../../app/consts/routes";
import history from '../../../routes/history';
import {translate} from "react-i18next";
import {voucherHideElements} from "../../../utils/voucherHideElements";

type Props = {
    car: Array
};

class CarDetail extends Component<Props, {}> {

    goBack() {
        history.push(pages.home);
    }

    renderTextItem = (key, value) => {
        return (
            <div className="car-detail__item">
                <h6 className="car-detail__item car-detail__item--key title-service">{key}</h6>
                <p className="car-detail__item car-detail__item--value text-service">{value}</p>
            </div>
        )
    };

    render() {
        const { t, car } = this.props;
        const make = getValueFromArray(car.makes, 'id', car.make_id);
        const range = getValueFromArray(car.ranges, 'id', car.range_id);
        const type = getValueFromArray(car.types, 'id', car.type_id);

        if (!car) {
            return null;
        }

        return (
           <React.Fragment>
               <div className="car-detail">
                   {make && make.name && (
                       <div className="car-detail__item">
                           <img
                               className="car-detail__item-logo"
                               src={getServiceImgPath(make.name.toLowerCase())}
                               alt={make.name}
                           />
                       </div>
                   )}

                   {range && range.name && this.renderTextItem(t('global.car.range'), range.name)}
                   {!voucherHideElements() && type && type.name && this.renderTextItem(t('global.car.type'), type.name)}

                   {!voucherHideElements() &&
                       <div className="car-detail__item car-detail__item--link">
                           <a href="#" className="link active">
                               {t('global.car.update_info')}
                           </a>
                       </div>
                   }
               </div>
           </React.Fragment>
        )
    }
}

export default translate('translations')(CarDetail);
