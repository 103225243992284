//@flow
import React, { Component } from 'react';
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import { withFormik } from "formik";
import * as Yup from "yup";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { saveGarageList } from "../../../store/actions/garageList.actions";
import ReactSVG from "react-svg";
import checkCircle from '../../../assets/images/checkCircle.svg';
import "./DialogSaveGarageListView.scss";

class DialogSaveGarageListView extends Component<{}, {}> {


    renderBody = () => {
        const { global: { checkAnimationSaveGarageListModal }, touched, errors, handleBlur, handleChange, t, values, handleSubmit, formSubmitting } = this.props;

        if (checkAnimationSaveGarageListModal && checkAnimationSaveGarageListModal.isVisible) {
            return (<div className="animation-check-wrapper">
                        <ReactSVG src={checkCircle} />
                        <p>{t('form.saveGarageList.success')}</p>
                    </div>);
        }

        return (
            <React.Fragment>
                <h5>{t('form.saveGarageList.title')}</h5>
                <p>{t('form.saveGarageList.description')}</p>
                <form onSubmit={handleSubmit} noValidate>
                    <Input
                        id="email"
                        type="email"
                        placeholder={t('form.placeholder.email')}
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                    />
                    <Button size="lg" type="primary" disabled={formSubmitting.saveGarageList}>
                        {t('form.saveGarageList.submit')}
                    </Button>
                </form>
            </React.Fragment>
        )
    };

    render() {
        return (
            <div className="modal__body">
                {this.renderBody()}
            </div>
        );
    }
}

const FormikSaveGarageListDialog = withFormik({

    mapPropsToValues(props) {
        return {
            email: props.payload.email || '',
        }
    },

    validationSchema: props => {
        const { t } = props.payload;

        return Yup.object().shape({
            email: Yup.string()
                .required()
                .email()
                .label(t('form.label.email'))
        });
    },

    handleSubmit(values, { props }) {
       props.saveGarageList(values.email);
    }

})(translate('translations')(DialogSaveGarageListView));

const mapStateToProps = (state) => {
    return {
        global: state.global,
        dialog: state.dialog,
        formSubmitting: state.formSubmitting
    }
};

export default connect(
    mapStateToProps, {
        saveGarageList,
    }
)(translate('translations')(FormikSaveGarageListDialog));
