import { createSelector } from 'reselect';
import {
    checkByChildId,
    checkByChildName,
    checkById,
    checkByName,
    preparedLabourSearchServices
} from '../../utils/officialService';

const labourSearchServices = state => state.services.labour.search;
const labourCustomServices = state => state.services.labour.custom;
const officialService = (state) => {
    if(state.services.official.selected){
        return state.services.official[state.services.official.selected]
    }

    return [];
};
const state = state => state;

export const getSelectedServices = createSelector(
    state,
    state => {
        const servicesOfficial = getSelectedOfficialService(state);
        const servicesSearch = getSearchServicesOfficialServicesDiff(state);
        const servicesCustom = getSelectedLabourCustomService(state);

        return [...servicesOfficial, ...servicesSearch, ...servicesCustom];
    }
);

export const getSelectedLabourServicesOfficialDiff = createSelector(
    state,
    state => {
        const servicesSearch = getSearchServicesOfficialServicesDiff(state);
        const servicesCustom = getSelectedLabourCustomService(state);

        return [...servicesSearch, ...servicesCustom];
    }
);

export const getSelectedLabourServices = createSelector(
    state,
    state => {
        const servicesSearch = getSelectedLabourSearchService(state);
        const servicesCustom = getSelectedLabourCustomService(state);

        return [...servicesSearch, ...servicesCustom];
    }
);

export const getSelectedLabourSearchService = createSelector(
    labourSearchServices,
    services => {
        return services.filter(search => {
            if (search.selected === true) {
                return search;
            }
            return null;
        });
    }
);

export const getSelectedLabourCustomService = createSelector(
    labourCustomServices,
    services => {
        return services.filter(custom => {
            if (custom.selected === true) {
                return custom;
            }
            return null;
        });
    }
);

export const preparedLabourSearchServicesFilter = createSelector(
    labourSearchServices,
    services => {
        const selectedServices = [];

        services.map(service => {
            preparedLabourSearchServices.map(preparedService => {
                if (
                    service.name.toLowerCase().includes(preparedService.name) ||
                    service.itemMpId === preparedService.id
                ) {
                    selectedServices.push({ ...service, prepared: preparedService });
                }

                return preparedService;
            });

            return service;
        });

        return selectedServices;
    }
);

export const getOfficialService = createSelector(
    state,
    state => {
        const preparedLabourSearchServices = preparedLabourSearchServicesFilter(state);
        const officialServices = officialService(state);
        const selectedServices = [];

        preparedLabourSearchServices.map(service => {
            officialServices.map(officialService => {
                if (checkByName(officialService, service) || checkById(officialService, service)) {
                    selectedServices.push(service);
                } else {
                    officialService.childs.map(child => {
                        if (checkByChildName(child, service) || checkByChildId(child, service)) {
                            selectedServices.push(service);
                        }

                        return child;
                    });
                }

                return officialService;
            });

            return service;
        });

        return selectedServices;
    }
);

export const getSelectedOfficialService = createSelector(
    state,
    state => {
        const preparedLabourSearchServices = preparedLabourSearchServicesFilter(state);
        const officialServices = officialService(state);
        const selectedServices = [];

        preparedLabourSearchServices.map(service => {
            officialServices.map(officialService => {
                if (checkByName(officialService, service) || checkById(officialService, service)) {
                    if (officialService.selected === true) {
                        selectedServices.push(service);
                    }
                } else {
                    officialService.childs.map(child => {
                        if (checkByChildName(child, service) || checkByChildId(child, service)) {
                            if (officialService.selected === true) {
                                selectedServices.push(service);
                            }
                        }

                        return child;
                    });
                }

                return officialService;
            });

            return service;
        });

        return selectedServices;
    }
);

export const getSearchServicesOfficialServicesDiff = createSelector(
    state,
    state => {
        let selectedOfficialService = getSelectedOfficialService(state);
        let selectedSearchServices = getSelectedLabourSearchService(state);

        for(let officialService of selectedOfficialService){

            selectedSearchServices = selectedSearchServices.filter( searchService => {
                if (searchService.id === officialService.id) {
                    return null;
                }

                return searchService;
            });

        }

        return selectedSearchServices;
    }
);

export const getSelectedOfficialServiceDefault = createSelector(
    state,
    state => {
        const officialServices = officialService(state);

        return officialServices.filter(officialService => officialService.selected === true);
    }
);

export const isOfficialWithNoLabour = createSelector(
    state,
    state => {
        return getOfficialService(state).length === 0 && getSelectedOfficialServiceDefault(state).length > 0;
    }
);

export const getOfficialServiceLabourDiff = createSelector(
    state,
    state => {
        const officialServices = getOfficialService(state);
        const labour = [...labourSearchServices(state), ...labourCustomServices(state)];

        officialServices.map(officialService => {
            labour.map((service, i) => {
                if (service.id === officialService.id) {
                    labour.splice(i, 1);
                }
                return null;
            });

            return officialService;
        });

        return labour;
    }
);

export const filterPredefinedServices = createSelector(
    state,
    state => {

        const labourDiff = getOfficialServiceLabourDiff(state);
        const predefinedServices = state.predefinedServices;
        const services = [...labourSearchServices(state), ...labourCustomServices(state)];
        const preselect = [];

        if(predefinedServices.official === true){
            for(let service of labourDiff){
                if(predefinedServices.labours.indexOf(service.id) !== -1){
                    preselect.push(service.id);
                }
            }
        }
        else{
            for(let service of services){
                if(predefinedServices.labours.indexOf(service.id) !== -1){
                    preselect.push(service.id);
                }
            }
        }

        return preselect;
    }
);
