import React, { Component } from 'react';
import {translate} from 'react-i18next';
import Button from "../Button/Button";
import "./index.scss";
import { DIALOG_ADDITIONAL_SERVICE } from "../../../store/consts/dialog.constants";
import Dialog from "../../dialogs/Dialog/Dialog";
import { connect } from "react-redux";
import { uncheckAllServices } from "../../../store/actions/services.actions";
import { setDialog } from "../../../store/actions/dialog.actions";
import { ServiceCheckStep } from "../../../routes/middleware/service/ServiceCheckStep";
import AdditionalServiceList from './List';
import AdditionalServiceSelectedList from "./SelectedList";
import classNames from "classnames";

class AdditionalService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isListOpened : false,
            filterValue: '',
            windowWidth: window.innerWidth
        };
        window.addEventListener('resize', this.handleWindowWidthChange)
    }

    handleWindowWidthChange = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    };

    showListView = (shouldShow = true) => {
        this.props.setDialog(DIALOG_ADDITIONAL_SERVICE, shouldShow);
    };

    render() {
        const { t, global } = this.props;
        const { windowWidth } = this.state;

        return (
            <div className={classNames("additional-service", {
                "additional-service-active": global.serviceFilterFocused
            })}>
                <Button
                    extraClass="additional-service-button"
                    onClick={() => this.showListView()}
                >
                    {t('global.additional_service.open')}
                </Button>
                <AdditionalServiceSelectedList />
                <Dialog
                    type={DIALOG_ADDITIONAL_SERVICE}
                    payload={{
                        title: t('global.additional_service.title'),
                        description: t('global.additional_service.description')
                    }}
                />
                <AdditionalServiceList
                    isHidden={windowWidth < 992}
                    showUncheckButton
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dialog: state.dialog,
        global: state.global,
    }
};

export default connect(
    mapStateToProps,
    {
        uncheckAllServices,
        setDialog
    }
)(ServiceCheckStep(translate('translations')(AdditionalService)));
