//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Step.scss';
import classNames from 'classnames';

type State = {
    step: {
        numberOfSteps: number,
        activeStep: number
    }
};

class Step extends Component<State> {
    renderStepItems = () => {
        const { numberOfSteps } = this.props.step;

        let items = [];

        for (let i = 1; i < numberOfSteps + 1; i++) {
            items.push(
                <div
                    key={i}
                    className={classNames('step-indicator__item', {
                        active: i === this.props.step.activeStep
                    })}
                />
            );
        }

        return items;
    };

    render() {
        return <div className="step-indicator">{this.renderStepItems()}</div>;
    }
}

let mapStateToProps = state => {
    return {
        step: state.step
    };
};

export default connect(
    mapStateToProps,
    {}
)(Step);
