import {fieldExists} from "./fieldExists";

export const isCarFieldValid = (object, key, array, nullable) => {

    if(fieldExists(object, key)){
        return true;
    }

    if(fieldExists(object, key, nullable) && fieldExists(object, array) && object[array].length === 0){
        return true;
    }

    return false;
};

export default isCarFieldValid;