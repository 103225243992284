import {
    PREDEFINED_SERVICES_REMOVE_ID_FROM_LIST,
    PREDEFINED_SERVICES_SET
} from "../actions/predefinedServices.actions";
import predefinedServices from "../models/predefinedServices";

export default function(state = predefinedServices, action) {
    switch (action.type) {
        case PREDEFINED_SERVICES_SET:
            return {
                official: action.payload.services.hasOwnProperty('official') ? action.payload.services.official : state.official,
                labours: action.payload.services.hasOwnProperty('labours') ? action.payload.services.labours : state.labours
            };

        case PREDEFINED_SERVICES_REMOVE_ID_FROM_LIST:
            return {
                ...state,
                labours: [
                    ...state.labours.filter(id => id !== action.payload.id)
                ]
            };

        default:
            return state;
    }
}
