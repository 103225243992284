import Yup from "./yup";

export default {
    mapPropsToValues: props => {
        return {
            voucher: props.voucher !== null ? props.voucher.name : '',
        };
    },
    validationSchema: ({ t }) => {
        return Yup.object().shape({
            voucher: Yup.string()
                .required()
                .label(t('validations.label.voucher')),
        });
    },
    handleSubmit(values, formikBag) {
        const { voucherCheck } = formikBag.props;

        voucherCheck(values.voucher, formikBag.props.garage);
    }
}
