import {
    GARAGE_LIST_FETCH,
    GARAGE_LIST_SAVE,
    GARAGE_LIST_VIEWS,
    setGarageList
} from '../../actions/garageList.actions';
import {API_ERROR, API_SUCCESS, apiRequest} from '../../actions/api.actions';
import {setBaloiseLink, setCheckAnimation} from '../../actions/global.actions';
import {setLoader} from "../../actions/loader.actions";
import {LOADER_PLACEHOLDER_GARAGE_LIST} from "../../consts/loader.constants";
import {formSubmittingGarageList, formSubmittingSaveGarageList} from "../../actions/formSubmitting.actions";
import {setTestimonials} from "../../actions/testimonials.actions";
import {removeError, setError} from "../../actions/error.actions";
import {ERROR_API_GARAGE_LIST} from "../../consts/error.constants";
import i18n from 'i18next';
import {setPredefinedGarage} from "../../actions/predefinedGarage.actions";
import store from "../../store";
import {setGarageDiscount} from "../../actions/garageDiscount.actions";
import {CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL} from "../../consts/global";
import {addFlashMessage} from "../../actions/flashMessage.actions";
import {FLASH_MESSAGE_ERROR} from "../../consts/flashMessage.constants";
import {
    filterSet,
    filterUpdateDistance
} from "../../actions/filter.actions";

export const LIST_GARAGES_URL = 'list-garages';
export const LIST_GARAGE_VIEWS_URL = 'repair-shop-views';
export const SAVE_LIST_GARAGE_URL = 'save-garage-list';

export const garageListMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);
        switch (action.type) {
            case GARAGE_LIST_FETCH:
                dispatch(setLoader(
                    LOADER_PLACEHOLDER_GARAGE_LIST,
                    true
                ));
                dispatch(formSubmittingGarageList(true));
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: {
                            official: action.payload.official,
                            labour: action.payload.labour,
                            filters: action.payload.filters,
                            sort: action.payload.sort,
                            repair_shop_token: (store.getState().predefinedGarageToken)
                                ? store.getState().predefinedGarageToken
                                : null,
                            voucher: store.getState().voucher ? store.getState().voucher.name : null
                        },
                        url: LIST_GARAGES_URL,
                        signature: GARAGE_LIST_FETCH
                    })
                );
                break;

            case `${GARAGE_LIST_FETCH} ${API_SUCCESS}`:
                dispatch(setGarageList(action.payload.data.garages));
                dispatch(setBaloiseLink(action.payload.data.baloise_link));
                dispatch(setPredefinedGarage(action.payload.data.predefinedGarage));
                dispatch(setTestimonials(action.payload.data.testimonials));
                dispatch(setGarageDiscount(action.payload.data.discount));
                dispatch(setLoader(LOADER_PLACEHOLDER_GARAGE_LIST, false));
                dispatch(formSubmittingGarageList(false));
                dispatch(removeError(ERROR_API_GARAGE_LIST));
                dispatch(filterUpdateDistance(0, 50, 0, action.payload.data.radius));

                // // TODO Refactor?
                // if (action.payload.data.garages.filter(garage => garage.distance <= 15)) {
                //     dispatch(filterUpdateDistance(0, 50, 0, 15))
                // } else if (action.payload.data.garages.filter(garage => garage.distance <= 20)) {
                //     dispatch(filterUpdateDistance(0, 50, 0, 20))
                // } else if (action.payload.data.garages.filter(garage => garage.distance <= 40)) {
                //     dispatch(filterUpdateDistance(0, 50, 0, 40))
                // }

                // let garages = Object.assign([], action.payload.data.garages);
                // dispatch(filterUpdatePriceRange(garages.sort((a, b) => sortListByKey(a, b, 'price', true))));

                if (action.payload.data.filters) {
                    dispatch(filterSet(action.payload.data.filters));
                }

                break;

            case `${GARAGE_LIST_FETCH} ${API_ERROR}`:
                dispatch(formSubmittingGarageList(false));
                dispatch(setError(ERROR_API_GARAGE_LIST,
                    i18n.getResource(window.base.language, 'translations', 'pages.services.garage_api_error'),
                ));
                break;

            case GARAGE_LIST_VIEWS:
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: action.payload,
                        url: LIST_GARAGE_VIEWS_URL,
                        signature: GARAGE_LIST_VIEWS
                    })
                );
                break;

            case `${GARAGE_LIST_VIEWS} ${API_SUCCESS}`:
                break;

            case `${GARAGE_LIST_VIEWS} ${API_ERROR}`:
                break;

            case `${GARAGE_LIST_SAVE}`:
                dispatch(formSubmittingSaveGarageList(true));
                dispatch(
                    apiRequest({
                        method: 'POST',
                        body: action.payload,
                        url: SAVE_LIST_GARAGE_URL,
                        signature: GARAGE_LIST_SAVE
                    })
                );
                break;

            case `${GARAGE_LIST_SAVE} ${API_SUCCESS}`:
                dispatch(formSubmittingSaveGarageList(false));
                dispatch(setCheckAnimation(
                    CHECK_ANIMATION_SAVE_GARAGE_LIST_MODAL,
                    true
                ));
                break;

            case `${GARAGE_LIST_SAVE} ${API_ERROR}`:
                dispatch(formSubmittingSaveGarageList(false));
                dispatch(addFlashMessage(
                    action.payload.error.message,
                    FLASH_MESSAGE_ERROR
                ));
                break;


            default:
                break;
        }
    };
};

export default {
    garageListMiddleware
};
