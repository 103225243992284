//@flow
import React, { Component } from 'react';
import classNames from 'classnames';
import './FlashMessage.scss';
import { FLASH_MESSAGE_ERROR, FLASH_MESSAGE_SUCCESS } from "../../../store/consts/flashMessage.constants";

type Props = {};

type State = {};

class FlashMessage extends Component<Props, State> {
    render() {
        const { message : { id, message, type }, onRemove } = this.props;

        return (
            <div className={classNames('flash-message', {
                'flash-message--error': type === FLASH_MESSAGE_ERROR,
                'flash-message--success': type === FLASH_MESSAGE_SUCCESS,
            })} onClick={() => onRemove(id)}>
                <p className="flash-message__body">
                    {message}
                </p>
            </div>
        );
    }
}

export default FlashMessage;
