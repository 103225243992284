import React, {Component} from 'react';
import './Filter.scss';
import { translate } from "react-i18next";

class SelectedFilter extends Component {
    render() {
        const { filter, onRemove } = this.props;

        return (
            <div
                className="selected-filter"
                onClick={() => onRemove(filter.id)}
            >
                {filter.label}
                <span className="icon icon-cross"></span>
            </div>
        )
    }
}

export default translate('translations')(SelectedFilter);
