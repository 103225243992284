export const FORM_GARAGE = '[Form Garage]';

export const FORM_GARAGE_SET_IS_SUBMITTED = `${FORM_GARAGE} SET_IS_SUBMITTED`;
export const FORM_GARAGE_SET_IS_DATE_PICKER_VALID = `${FORM_GARAGE} SET_IS_DATE_PICKER_VALID`;
export const FORM_GARAGE_RESET = `${FORM_GARAGE} RESET`;

export const setGarageFormIsSubmitted = isFormSubmitted => ({
    type: FORM_GARAGE_SET_IS_SUBMITTED,
    payload: {
        isFormSubmitted
    }
});

export const setGarageIsFormDatePickerValid = isDatePickerValid => ({
    type: FORM_GARAGE_SET_IS_DATE_PICKER_VALID,
    payload: {
        isDatePickerValid
    }
});

export const formGarageReset = () => ({
    type: FORM_GARAGE_RESET
});
