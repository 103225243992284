//@flow
import React, { Component } from 'react';
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { saveGarageList } from "../../../store/actions/garageList.actions";
import "./DialogAdditionalService.scss";
import AdditionalServiceList from "../../shared/AdditionalService/List";

class DialogAdditionalService extends Component<{}, {}> {

    render() {
        const { title, description } = this.props.payload;
        const { global } = this.props;

        return (
            <div className="dialog-additional-service-wrapper">
                {!global.serviceFilterFocused && (
                    <div className="modal__header modal__header--additional-service">
                        <h5>{title}</h5>
                        <p>{description}</p>
                    </div>
                )}
                <div className="modal__body modal__body--additional-service">
                    <AdditionalServiceList hideTitle />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global: state.global,
        dialog: state.dialog,
        services: state.services,
        formSubmitting: state.formSubmitting,
    }
};

export default connect(
    mapStateToProps, {
        saveGarageList,
    }
)(translate('translations')(DialogAdditionalService));
