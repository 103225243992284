import React from 'react';

export const OfficialServiceHeader = (props) => {
  return (
      <div className="official-service-header">
          <h6 className="title-service">{props.title}</h6>
          <p className="text-service">{props.description}</p>
      </div>
  )
};

export default OfficialServiceHeader;
