import {INIT_ROUTE_CHECK} from "../../actions/route.actions";
import {matchPath} from "react-router-dom";

import store from "../../store";
import history from "../../../routes/history";
import routes from "../../../routes";
import {LOADER_INIT_OFFER} from "../../consts/loader.constants";
import {setLoader} from "../../actions/loader.actions";
import {voucherHideElements} from "../../../utils/voucherHideElements";

export const routeMiddleware = ({dispatch}) => next => {
    return action => {
        next(action);

        switch (action.type) {
            case INIT_ROUTE_CHECK:

                let routeIndex = 0;
                for (let route in routes) {
                    if (matchPath(history.location.pathname, routes[route])) {
                        routeIndex = parseInt(route) + 1;
                    }
                }

                if (store.getState().step.activeStep > routeIndex && !voucherHideElements()) {
                    history.push(routes[store.getState().step.activeStep - 1].path);
                } else {
                    let myRoute = routes[store.getState().step.activeStep - 1];
                    if (!myRoute) myRoute = routes[store.getState().step.activeStep];

                    history.replace(myRoute.path);
                }

                dispatch(setLoader(LOADER_INIT_OFFER, true));

                break;

            default:
                break;
        }
    };
};
