import React, {Component} from 'react';
import {translate} from "react-i18next";
import {filterPredefinedServices, getSelectedServices} from "../../../store/selectors/services";
import {connect} from "react-redux";
import {serviceCheck, serviceUncheck} from "../../../store/actions/services.actions";
import ServiceToggler from "../ServiceToggler";
import "./index.scss";
import {SERVICE_TYPE_LABOUR_CUSTOM, SERVICE_TYPE_LABOUR_SEARCH} from "../../../store/consts/services.constants";
import {removeIdFromPredefinedList} from "../../../store/actions/predefinedServices.actions";
import {getPredefinedServices} from "../../../utils/common";
import {LOADER_SERVICE_CHECK} from "../../../store/consts/loader.constants";
import {voucherHideElements} from "../../../utils/voucherHideElements";

class PreselectedServiceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preselectedList: []
        }
    }

    componentDidMount() {
        if (this.props.filteredPredefinedServices.length) {
            this.preselectServices();
            this.setPreselectedList();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            JSON.stringify(this.props.list) !== JSON.stringify(nextProps.list) &&
            nextProps.filteredPredefinedServices.length
        ) {
            this.setState({
                preselectedList: []
            }, () => {
                this.setPreselectedList();
            })
        }
    }

    setPreselectedList = () => {
        const {filteredPredefinedServices, list} = this.props;
        const preselectedList = [...this.state.preselectedList];

        getPredefinedServices(list, filteredPredefinedServices).map(service => {
            preselectedList.push(service);
            this.setState({
                preselectedList
            })
        });
    };

    preselectServices = () => {
        const {filteredPredefinedServices, serviceCheck, list} = this.props;

        for (let serviceId of filteredPredefinedServices) {
            const tmpService = list.find(service => service.id === serviceId);

            if (!tmpService.selected) {
                serviceCheck(tmpService.id);
            }
        }
    };

    handleServiceToggle = (e, service) => {
        const {serviceCheck, serviceUncheck} = this.props;

        if (service.is_official_selected || voucherHideElements()) {
            return null;
        }

        if (service.selected) {
            serviceUncheck(SERVICE_TYPE_LABOUR_CUSTOM, service.id);
            serviceUncheck(SERVICE_TYPE_LABOUR_SEARCH, service.id);
        } else if (!service.selected) {
            serviceCheck(service.id);
        }

    };

    render() {
        const {loader, t} = this.props;
        const {preselectedList} = this.state;

        if (!preselectedList.length)
            return null;

        return (
            <div className="preselected-service-list-container clearfix">
                {!voucherHideElements() ?
                    <p className="title-service"> {t('global.preselected_service.title')}</p> : null}
                <div className="preselected-service-list clearfix">
                    {preselectedList.map((service, index) => {
                        return <ServiceToggler
                            key={service.id}
                            index={service.id}
                            service={service}
                            onToggle={this.handleServiceToggle}
                            isLoading={loader[`${LOADER_SERVICE_CHECK}-${service.id}`] === true}
                            isPreselected
                        />
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        services: state.services,
        loader: state.loader,
        filteredPredefinedServices: filterPredefinedServices(state),
        selectedServices: getSelectedServices(state),
    };
};

export default connect(
    mapStateToProps,
    {
        serviceCheck,
        serviceUncheck,
        removeIdFromPredefinedList
    }
)(translate('translations')(PreselectedServiceList));
