import licencePlateAttempts from '../models/licencePlateAttempts';
import {LICENCE_PLATE_ATTEMPTS_SET} from "../actions/licencePlateAttempts";

export default function(state = licencePlateAttempts, action) {
    switch (action.type) {
        case LICENCE_PLATE_ATTEMPTS_SET:
           return action.payload.attempt;

        default:
            return state;
    }
}
