import store from '../../../store/store';
import routes from '../../index';
import history from '../../../routes/history';
import React from "react";
import {fieldExists} from "../../../utils/fieldExists";

export const ServiceCheckStep = (Component) => {
    return class extends React.Component {

        constructor(props) {
            super(props);

            const car = store.getState().car;
            this.shouldRender = true;

            if(!(!!car && fieldExists(car,'make_id') && fieldExists(car,'range_id') && fieldExists(car,'type_id') && fieldExists(car,'first_registration') && fieldExists(car,'mileage') && fieldExists(car,'zip_id'))){
                this.shouldRender = false;
                history.replace(routes[0].path);
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }
    }
};

export default ServiceCheckStep;
