import React, {Component} from 'react';
import { translate } from "react-i18next";
import "./index.scss";

class AdditionalServiceSelectedListItem extends Component {
    render() {
        const { service, onRemove } = this.props;

        return (
            <div
                className="selected-service"
                onClick={() => onRemove(service.id)}
            >
                {service.name}
                <span className="icon icon-cross"></span>
            </div>
        )
    }
}

export default translate('translations')(AdditionalServiceSelectedListItem);
