//@flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {withFormik} from 'formik';
import Input from '../../../components/shared/Input/Input';
import {userSignUp} from '../../../store/actions/auth.actions';
import {formSubmittingSignUp} from '../../../store/actions/formSubmitting.actions';
import InputCheckout from '../../shared/InputCheckout/InputCheckout';
import {formErrorsSignUpRemove} from '../../../store/actions/formErrors.actions';
import {setGarageFormIsSubmitted} from '../../../store/actions/formGarage.actions';
import Button from '../../shared/Button/Button';
import {formRegisterConfig} from '../../../app/config/yup';
import SocialMediaLogin from "../../shared/SocialMediaLogin/SocialMediaLogin";
import {applyGarageDiscount} from "../../../store/selectors/garage";
import {voucherHideElements} from "../../../utils/voucherHideElements";
import {scroller} from "../../../utils/scroller";
import {makeInputNumericOnly} from "../../../utils/common";

class FormRegister extends Component<State> {
    componentDidMount() {
        if (this.props.scrollToForm) {
            scroller('from', {
                duration: 500,
                smooth: "ease"
            })
        }

        makeInputNumericOnly(document.getElementById('phone'), value => {
            return /^\d*$/.test(value);
        });
    }

    componentDidUpdate() {
        const {formErrors, setFieldError, formErrorsSignUpRemove} = this.props;

        if (formErrors.signUp) {
            Object.keys(formErrors.signUp).map(field => {
                return setFieldError(field, formErrors.signUp[field]);
            });

            formErrorsSignUpRemove();
        }
    }

    handleTextChange = (e: Event) => {
        const {handleChange} = this.props;
        const {name, value, checked} = e.target;

        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'checkbox') {
                this.setState({
                    [name]: checked
                });
            } else {
                this.setState({
                    [name]: value
                });
            }

            handleChange(e);

            if (e.target.id === 'agb_agreement') {
                this.props.updateAgbAgreement(e.target.checked);
            }
        }
    };
    nonValidElement = (errors) => {
        if (Object.keys(errors).length === 0 && errors.constructor === Object) return 'form';

        return Object.keys(errors)[0];
    };

    submitForm = e => {

        e.preventDefault();
        const {submitForm, setGarageFormIsSubmitted, formGarage} = this.props;

        if (!this.props.isValid) {
            scroller(this.nonValidElement(this.props.errors), {
                duration: 500,
                smooth: "ease"
            })
        }

        setGarageFormIsSubmitted(true);
        if (formGarage.isDatePickerValid) {
            submitForm();
        }
    };

    render() {
        const {
            t,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            values,
            formSubmitting,
            garage,
            global: {selectDropdownOpened},
        } = this.props;

        return (
            <React.Fragment>
                <div className="header-auth-text">
                    {/*<Trans i18nKey="pages.garage.auth.register">*/}
                    {/*    Bitte registriere dich um deine Buchung abzuschliessen, oder*/}
                    {/*    <Button extraClass="btn--link" onClick={() => this.props.changeForm('login')}>*/}
                    {/*        logge dich ein*/}
                    {/*    </Button>.*/}
                    {/*</Trans>*/}

                    {t('pages.garage.please')} {' '}
                    <Button extraClass="btn--link" onClick={() => this.props.changeForm('login')}>
                        {t('pages.garage.btn_login')}
                    </Button>{' '}
                    {t('pages.garage.login_to_finalize_booking')}
                </div>

                <SocialMediaLogin t={t}/>

                <form onSubmit={e => {
                    if (!selectDropdownOpened) {
                        handleSubmit(e);
                    }
                }} className="from" id="form">
                    <div className="row">
                        <div className="col-md-2">
                            <Input
                                type="text"
                                id="first_name"
                                name="first_name"
                                label={t('form.label.first_name')}
                                placeholder={t('form.placeholder.first_name')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                                error={touched.first_name && errors.first_name}
                            />
                        </div>
                        <div className="col-md-2">
                            <Input
                                type="text"
                                id="last_name"
                                name="last_name"
                                label={t('form.label.last_name')}
                                placeholder={t('form.placeholder.last_name')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                                error={touched.last_name && errors.last_name}
                            />
                        </div>
                        <div className="col-md-4">
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                label={t('form.label.email')}
                                placeholder={t('form.placeholder.email')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && errors.email}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Input
                                type="tel"
                                id="phone"
                                name="phone"
                                label={t('form.label.phone')}
                                placeholder={t('form.placeholder.phone')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                error={touched.phone && errors.phone}
                            />
                        </div>
                        <div className="col-md-4">
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                label={t('form.label.password')}
                                placeholder={t('form.placeholder.password')}
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.password && errors.password}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <InputCheckout
                                id="agb_agreement"
                                name="agb_agreement"
                                onChange={this.handleTextChange}
                                onBlur={handleBlur}
                                checked={this.props.agbAgreement}
                                label={
                                    <span className="label-has-link">{t('form.label.agb_agreement')}
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href={window.settings.termsAndConditionsLink}>{t('form.label.terms_and_condition')}</a></span>
                                }
                                error={touched.agb_agreement && errors.agb_agreement}
                                inputControlClass="mt-30"
                            />
                        </div>
                    </div>

                    <div className="row checkout">
                        <button
                            onClick={this.submitForm}
                            className="btn btn-primary btn-md"
                            type="submit"
                            disabled={formSubmitting.signUp}
                        >
                            {
                                !voucherHideElements() ?
                                    t('pages.garage.btn_checkout_for', {price: `${t('global.currency')} ${garage.totalPrice}`})
                                    :
                                    t('pages.garage.btn_checkout')
                            }
                        </button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const FormikApp = withFormik(formRegisterConfig)(FormRegister);

const mapStateToProps = state => {
    return {
        garage: applyGarageDiscount(state),
        formErrors: state.formErrors,
        formSubmitting: state.formSubmitting,
        formGarage: state.formGarage,
        global: state.global,
        garageDiscount: state.garageDiscount,
        user: state.user,
    };
};

export default connect(
    mapStateToProps,
    {
        userSignUp,
        formSubmittingSignUp,
        formErrorsSignUpRemove,
        setGarageFormIsSubmitted
    }
)(translate('translations')(FormikApp));
