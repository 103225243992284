import React from "react";

export const ShowFooter = (Component) => {

    return class extends React.Component {

        constructor(props) {
            super(props);

            this.shouldRender = true;

            let footer = document.querySelector('footer.footer');
            if(footer){
                document.querySelector('footer.footer').className = document.querySelector('footer.footer').className.replace(/hide/gi, '');
            }
        }

        render() {
            if(this.shouldRender){
                return <Component {...this.props} />;
            }

            return <div/>;
        }

    }

};

export default ShowFooter;