import axios from 'axios';
import {STORAGE_AXA_TOKEN, STORAGE_OFFER_TOKEN, STORAGE_USER_TOKEN} from '../consts/storage.consts';
import persistSingleUrls from '../../routes/persistSingleUrls';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    persistSingleUrls: []
});

instance.interceptors.request.use(config => {
    const OfferToken = sessionStorage.getItem(STORAGE_OFFER_TOKEN);

    if (OfferToken) {
        config.headers.common.OfferToken = OfferToken;
    }

    const UserToken = localStorage.getItem(STORAGE_USER_TOKEN);
    if (UserToken) {
        config.headers.common.Authorization = `Bearer ${UserToken}`;
    }

    const AToken = localStorage.getItem(STORAGE_AXA_TOKEN);
    if (AToken) {
        config.headers.common.AToken = `${AToken}`;
    }

    config.headers.common.Language = window.base.language;

    if(persistSingleUrls.indexOf(config.url) !== -1){

        config.cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = config.cancelTokenSource.token;

        config.persistSingleUrls.map((data, i) => {
            if(data.url === config.url){
                data.cancel();
                config.persistSingleUrls.splice(i, 1);
            }

            return data;
        });

        config.persistSingleUrls.push({cancel: config.cancelTokenSource.cancel, url: config.url});
    }

    return config;
});

export default instance;
