export const FORM_ERRORS = '[Form Errors]';

export const FORM_ERRORS_SIGN_UP_SET = `${FORM_ERRORS} SIGN UP SET`;
export const FORM_ERRORS_SIGN_UP_REMOVE = `${FORM_ERRORS} SIGN UP REMOVE`;
export const FORM_ERRORS_VOUCHER_SET = `${FORM_ERRORS} VOUCHER SET`;
export const FORM_ERRORS_VOUCHER_REMOVE = `${FORM_ERRORS} VOUCHER REMOVE`;

export const formErrorsSignUpSet = errors => ({
    type: FORM_ERRORS_SIGN_UP_SET,
    payload: {
        errors
    }
});

export const formErrorsSignUpRemove = () => ({
    type: FORM_ERRORS_SIGN_UP_REMOVE
});

export const formErrorsVoucherSet = error => ({
    type: FORM_ERRORS_VOUCHER_SET,
    payload: {
        errors: {voucher: error}
    }
});

export const formErrorsVoucherRemove = () => ({
    type: FORM_ERRORS_VOUCHER_REMOVE
});
