import React, {Component} from 'react';
import './Filter.scss';
import { translate } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import Button from "../Button/Button";
import { clearZipData, fetchZipData, setZipData } from "../../../store/actions/zipData.actions";
import ZipPicker from "../ZipPicker/ZipPicker";
import { withFormik } from "formik";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { carDataChange } from "../../../store/actions/car.actions";
import {
    filterChange, filterReplaceBrandName, filterResetZip, filterStatusChange,
    filterUpdateZip, filterUpdateZipStatus, filterUpdateZipWithoutStatus,
    uncheckFilters
} from "../../../store/actions/filter.actions";
import InputCheckout from "../InputCheckout/InputCheckout";
import "react-input-range/lib/css/index.css";
import InputRangeWrapper from "../InputRangeWrapper/InputRangeWrapper";
import { getGarageList } from "../../../store/actions/garageList.actions";
import { onZipReset, onZipSelected, zipSet } from "../../../store/actions/zip.actions";
import { getValueFromArray } from "../../../utils/car";
import { LOADER_API_GET_CAR, LOADER_ZIP_DATA_LIST } from "../../../store/consts/loader.constants";
import { setLoader } from "../../../store/actions/loader.actions";
import voucherHideElements from "../../../utils/voucherHideElements";

const BODY_DROP_SHADOW_CLASS = 'drop-shadow';

class FilterListDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldClearZipInputText: false,
            zipLoaded: false,
            zipChangedManually: false,
            selectedZip: {
                id: '',
                zip: '',
                name: ''
            }
        };
        window.addEventListener('resize', this.handleResize);
    }

    componentWillMount() {
        if (window.innerWidth < 992) {
            document.body.className = BODY_DROP_SHADOW_CLASS;
        }
    }

    componentDidMount() {
        const { car, filterReplaceBrandName } = this.props;
        const make = getValueFromArray(car.makes, 'id', car.make_id);
        this._ismounted = true;
        filterReplaceBrandName(make.name);

        if (!this.props.filters.zipId.data.zipData[0] && this.props.zipData.length !== 0 && this.props.car.zip_id) {
            this.props.filterUpdateZipWithoutStatus(this.props.zipData.find(zip => zip.id === this.props.car.zip_id));
        }

        this.autoSelectZip();
    }

    componentWillUnmount() {
        this._ismounted = false;
        document.body.className = document.body.className.replace(BODY_DROP_SHADOW_CLASS, '');
    }

    componentWillReceiveProps(nextProps) {
        if (
            JSON.stringify(this.props.filters.zipId.data.zipData) !== JSON.stringify(nextProps.filters.zipId.data.zipData) &&
            nextProps.filters.zipId.data.zipData.length === 0
        ) {
            this.setState({ shouldClearZipInputText: true })
        }
    }

    handleResize = () => {
        if (!this._ismounted || window.innerWidth >= 992) {
            document.body.className = document.body.className.replace(BODY_DROP_SHADOW_CLASS, '');
        } else {
            document.body.className = BODY_DROP_SHADOW_CLASS;
        }
    };

    autoSelectZip = () => {
        if (this.props.filters.zipId.data.zipData[0]) {
            this.updateFormField('zip_id', this.props.car.zip_id);
        } else {
            this.updateFormField('zip_id', this.props.zip.id);
        }
    };

    handleCheckboxChange = (e: Event) => {
        this.props.filterChange(e.target.id, {
            checked: e.target.checked
        });
    };

    handleClickOutside = () => {
        this.props.onClose();
    };

    updateFormField = (name, value) => {
        const { values, setValues } = this.props;

        values[name] = value;
        setValues(values);
    };

    onFormFieldChange = (name, value, shouldCallAPI = false) => {
        this.updateFormField(name, value);
        // this.props.carDataChange(name, value, shouldCallAPI); /* update redux store and eventually call api */
    };

    handleZipChange = () => {
        this.onFormFieldChange('zip_id', '');
    };

    handleZipClear = () => {
        this.onFormFieldChange('zip_id', '');
        this.props.clearZipData();
        this.props.setLoader(LOADER_API_GET_CAR, false);
    };

    handleZipSelect = zip => {
        const { zipLoaded } = this.state;
        // const { filterUpdateZipStatus } = this.props;

        if (zipLoaded) {
            this.setState({
                zipChangedManually: true,
            }, () => {

                if (this.state.zipChangedManually) {
                    this.props.filterUpdateZip(zip);
                    this.props.carDataChange('zip_id', zip.id, false);
                }
            })
        }
        this.onFormFieldChange('zip_id', zip.id, (typeof window.predefined === 'object'));
        this.setState({ selectedZip: zip, zipLoaded: true });
    };

    handleZipFetchData = (zipCode) => {
        this.props.clearZipData();

        if (zipCode) {
            this.props.fetchZipData(zipCode);
        }
    };

    getSelectedZipCode = () => {
        // if (window.predefined) {
        //     return this.hasPredefinedZipData();
        // }

        return this.props.zipData.find(zip => zip.id === this.props.values.zip_id);
    };

    hasPredefinedZipData = () => {
        if (window.predefined) {
            return window.predefined.zipData.find(zip => zip.id === this.props.values.zip_id);
        }
    };


    render() {
        const { filters, touched, errors, handleBlur, t, car, zip,
            getGarageList, filterChange, uncheckFilters, onClose, filterResetZip, filterStatusChange, zipData, loader, setZipData,
            isSubmitting, services: { official, labour }
        } = this.props;
        const { selectedZip } = this.state;
        const isZipValid = zipData.find(zip => zip.id === selectedZip.id);

        const btnReset = (
            <Button size="lg" type="grayed-out"
                    onClick={() => {
                        uncheckFilters();
                        this.props.setValues({ zip_id : zip.id });
                        onClose();
                        getGarageList(official, labour, filters, null, true);
                        setTimeout(() => {
                            // setZipData(car.zipData);
                            this.handleZipFetchData(zip.zip);
                        }, 300);
                    }}>
                {t('global.filter.reset')}
            </Button>
        );

        const btnSave = (
            <Button size="lg" type="primary" onClick={() => {
                filterStatusChange();
                // if (this.state.zipChangedManually) {
                //     filterUpdateZip(selectedZip);
                // }

                onClose();

                if (!isZipValid) {
                    filterResetZip();
                    setZipData(car.zipData);
                    this.handleZipFetchData(zip.zip);
                }

                setTimeout(() => {
                    getGarageList(official, labour, filters, null, true);
                }, 10)
            }}>
                {t('global.filter.save')}
            </Button>
        );

        const buttons = (
            <div className="filter-list-dropdown-buttons">
                <div className="filter-list-dropdown-buttons__item">
                    {btnReset}
                </div>
                <div className="filter-list-dropdown-buttons__item">
                    {btnSave}
                </div>
            </div>
        );

        const header = (
            <div className="filter-list-dropdown-header">
                <div className="filter-list-dropdown-header__title">
                    <p>{t('global.filter.title')}</p>
                </div>
                <div className="filter-list-dropdown-header__close filter-list-dropdown-header__close--mobile">
                    <span
                        className="icon icon-cross"
                        onClick={onClose}
                    ></span>
                </div>
                <div className="filter-list-dropdown-header__close filter-list-dropdown-header__close--desktop">
                    <p onClick={onClose}>
                        {t('global.filter.hide')}
                        <span
                            className="icon icon-chevron-up"
                        ></span>
                    </p>
                </div>
            </div>
        );


        const body = (
            <div className="container-fluid">

                <div className="col-md-4 filter-section">
                    <ZipPicker
                        id="zip_id"
                        name="zip_id"
                        label={t('form.label.zip')}
                        list={zipData}
                        onChange={value => this.handleZipChange(value)}
                        onZipSelect={zip => this.handleZipSelect(zip)}
                        onReadyForFetchData={(zipCode) => this.handleZipFetchData(zipCode)}
                        onZipClear={this.handleZipClear}
                        onZipDataClear={() => false}
                        onBlur={handleBlur}
                        error={touched.zip_id && errors.zip_id}
                        selected={this.getSelectedZipCode()}
                        forceValidate={isSubmitting}
                        hide={!!this.hasPredefinedZipData()}
                        isZipDataListLoading={loader[LOADER_ZIP_DATA_LIST]}
                    />

                    <InputRangeWrapper
                        from={filters.distance.from}
                        to={filters.distance.to}
                        label={filters.distance.label}
                        unit={filters.distance.unit}
                        onChange={value => filterChange('distance', value, `${value.min} ${filters.distance.unit} - ${value.max} ${filters.distance.unit}`)}
                        value={filters.distance.data}
                    />

                    {/*{!voucherHideElements() &&*/}
                    {/*    <InputRangeWrapper*/}
                    {/*        from={filters.price.from}*/}
                    {/*        to={filters.price.to}*/}
                    {/*        label={filters.price.label}*/}
                    {/*        unit={filters.price.unit}*/}
                    {/*        onChange={value => filterChange('price', value, `${value.min} ${filters.price.unit} - ${value.max} ${filters.price.unit}`)}*/}
                    {/*        value={filters.price.data}*/}
                    {/*        isDisabled={garageList.length < 2}*/}
                    {/*    />*/}
                    {/*}*/}

                </div>
                {!voucherHideElements() &&
                <div className="col-md-4 filter-section">
                    <h5>{t('global.filter.else')}</h5>

                    <InputCheckout
                        id="ratings"
                        name="ratings"
                        onChange={this.handleCheckboxChange}
                        onBlur={handleBlur}
                        checked={filters.ratings.data.checked}
                        label={filters.ratings.description}
                    />

                    {/*<InputCheckout
                            id="instantBooking"
                            name="instantBooking"
                            onChange={this.handleCheckboxChange}
                            onBlur={handleBlur}
                            checked={filters.instantBooking.data.checked}
                            label={filters.instantBooking.description}
                        />*/}

                    <InputCheckout
                        id="officialGarages"
                        name="officialGarages"
                        onChange={this.handleCheckboxChange}
                        onBlur={handleBlur}
                        checked={filters.officialGarages.data.checked}
                        label={filters.officialGarages.description}
                    />

                </div>
                }
            </div>
        );

        return (
            <div className="filter-list-dropdown">
                {header}
                {body}
                {buttons}
            </div>
        )
    }
}

const FormikFilter = withFormik({

    mapPropsToValues(props) {
        return {
            zip_id: ''
        }
    },

    validationSchema() {
        return Yup.object().shape({
            zip_id: Yup.number()
                .required()
                .nullable()
                .label('label')
        });
    }

})(translate('translations')(onClickOutside(FilterListDropdown)));

const mapStateToProps = state => {
    return {
        error: state.error,
        zipData: state.zipData,
        filters: state.filters,
        garageList: state.garageList,
        car: state.car,
        services: state.services,
        zip: state.zip,
        loader: state.loader
    };
};

export default connect(mapStateToProps, {
    fetchZipData,
    clearZipData,
    carDataChange,
    filterChange,
    uncheckFilters,
    getGarageList,
    // filterUpdatePriceRange,
    filterUpdateZipWithoutStatus,
    onZipReset,
    onZipSelected,
    filterReplaceBrandName,
    filterStatusChange,
    filterUpdateZip,
    zipSet,
    setZipData,
    setLoader,
    filterResetZip,
    filterUpdateZipStatus
})(FormikFilter);
