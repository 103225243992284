import {RectShape} from 'react-placeholder/lib/placeholders';
import * as React from 'react';
import './garageList.placeholder.scss';
import { GARAGE_LIST_NUMBER_OF_ITEMS } from "../../../store/consts/placeholder.constants";
import GarageLoadProgressBar from "../../shared/GarageLoadProgressBar/GarageLoadProgressBar";

const renderItems = () => {
    let items = [];

    for (let i = 0 ; i < GARAGE_LIST_NUMBER_OF_ITEMS; i++) {
      items.push(
          <div key={i} className='placeholder-container placeholder-container--white-bg'>
              <div className="placeholder-row placeholder-row--paddings placeholder-pos-relative-garage-item">
                  <div className="placeholder-row placeholder-garage-list-item-avatar">
                      <RectShape color='' />
                  </div>
                  <div className="placeholder-row placeholder-garage-list-item-detail">
                      <RectShape className="placeholder-garage-list-item-detail__title"/>
                      <RectShape className="placeholder-garage-list-item-detail__location"/>
                      <RectShape className="placeholder-garage-list-item-detail__stars"/>
                  </div>
                  <div className="placeholder-row placeholder-garage-list-item-ctas">
                      <RectShape />
                      <RectShape className="placeholder-garage-list-item-ctas__button"/>
                  </div>
              </div>
          </div>
      )
    }

  return items;
};

const garageListPlaceholder = (
    <div className='placeholder-garage-list-container'>
        <GarageLoadProgressBar />
        {renderItems()}
    </div>
);

export default garageListPlaceholder;
