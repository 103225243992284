import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './Dialog.scss';
import {translate} from 'react-i18next';
import {connect} from 'react-redux';
import DialogServiceDetailView from '../DialogServiceDetailView/DialogServiceDetailView';
import DialogServiceErrorView from '../DialogServiceErrorView/DialogServiceErrorView';
import {
    DIALOG_ADDITIONAL_SERVICE, DIALOG_NOTIFICATION_FROM_BACKEND, DIALOG_RESET_PASSWORD,
    DIALOG_SAVE_GARAGE_LIST,
    DIALOG_SELECTED_SERVICE_DETAIL,
    DIALOG_SERVICE_DETAIL,
    DIALOG_SERVICE_ERROR
} from '../../../store/consts/dialog.constants';
import {setDialog} from '../../../store/actions/dialog.actions';
import DialogSaveGarageListView from "../DialogSaveGarageListView/DialogSaveGarageListView";
import {CSSTransition} from 'react-transition-group';
import DialogAdditionalService from "../DialogAdditionalService/DialogAdditionalService";
import DialogAdditionalServiceFooter from "../DialogAdditionalService/DialogAdditionalServiceFooter";
import {uncheckAllServices} from "../../../store/actions/services.actions";
import classNames from 'classnames';
import {serviceFilterFocused} from "../../../store/actions/global.actions";
import DialogResetPasswordView from "../DialogResetPasswordView/DialogResetPasswordView";

const modalRoot = document.getElementById('modalRoot');

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.state = {
            cssTransition: {
                appear: true,
                classNames: 'modal-fade'
            }
        };
        this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        modalRoot.appendChild(this.el);
    };

    componentWillUnmount = () => {
        modalRoot.removeChild(this.el);
    };

    handleClose = () => {
        const {setDialog, type, onCloseAction} = this.props;

        this.setState({
            cssTransition: {
                classNames: 'modal-fade-close'
            }
        }, () => {
            setTimeout(() => {
                setDialog(type, false);

                if (onCloseAction) {
                    onCloseAction();
                }
            }, 250);
        });
    };

    handleClickOutside = (e) => {
        if (e.target.className === this.modalRef.current.className)
            this.handleClose();
    };


    render() {
        const {children, dialog, payload, autoClose, type, hideCloseBtn, uncheckAllServices, global: {serviceFilterFocused: filterFocused}, serviceFilterFocused} = this.props;
        const {cssTransition} = this.state;

        if (!dialog[type]) return null;

        if (autoClose) {
            setTimeout(() => {
                setDialog(type, false);

                this.handleClose();
            }, 3500);
        }

        return ReactDOM.createPortal(
            <CSSTransition in={cssTransition.appear} appear={true} timeout={600} classNames={cssTransition.classNames}>
                <>
                    {type !== DIALOG_NOTIFICATION_FROM_BACKEND &&
                    <div className={classNames('modal-container', {
                        'modal-container--additionalService': type === DIALOG_ADDITIONAL_SERVICE
                    })}
                         ref={this.modalRef}
                         onClick={this.handleClickOutside}
                    >
                        <div className={classNames('modal', {
                            'modal--additionalService': type === DIALOG_ADDITIONAL_SERVICE,
                            'modal--fullscreen': (type === DIALOG_ADDITIONAL_SERVICE) && filterFocused
                        })}>
                            {hideCloseBtn !== true ? (
                                <div className="modal__close">
                                <span className="modal__close-icon icon-cross" onClick={() => {
                                    if (type === DIALOG_ADDITIONAL_SERVICE && filterFocused === true) {
                                        serviceFilterFocused(false);
                                    } else {
                                        serviceFilterFocused(false);
                                        this.handleClose();
                                    }
                                }}/>
                                </div>
                            ) : null}

                            {type.includes(DIALOG_SERVICE_DETAIL) && (
                                <DialogServiceDetailView payload={payload}/>
                            )}

                            {type.includes(DIALOG_SELECTED_SERVICE_DETAIL) && (
                                <DialogServiceDetailView payload={payload}/>
                            )}

                            {type.includes(DIALOG_SAVE_GARAGE_LIST) && (
                                <DialogSaveGarageListView
                                    payload={payload}
                                    onClose={this.handleClose}
                                />
                            )}

                            {type.includes(DIALOG_RESET_PASSWORD) && (
                                <DialogResetPasswordView
                                    payload={payload}
                                    onClose={this.handleClose}
                                />
                            )}

                            {type === DIALOG_SERVICE_ERROR && <DialogServiceErrorView payload={payload}/>}

                            {type === DIALOG_ADDITIONAL_SERVICE && <DialogAdditionalService
                                payload={payload}
                            />}

                            {/* Buttons will be loaded here */}
                            {children}
                        </div>
                        {type === DIALOG_ADDITIONAL_SERVICE &&
                        <DialogAdditionalServiceFooter
                            onCloseDialog={this.handleClose}
                            onUncheckAllServices={uncheckAllServices}
                        />
                        }
                    </div>}
                    {type === DIALOG_NOTIFICATION_FROM_BACKEND &&
                    <div className={classNames('notification-backend', {
                        'notification-backend__error': payload.title === 'error',
                        'notification-backend__warning': payload.title === 'warning',
                    })}
                         ref={this.modalRef}
                         onClick={this.handleClickOutside}>
                        {payload.description}
                    </div>}
                </>
            </CSSTransition>,
            this.el
        );
    }
}

let mapStateToProps = state => {
    return {
        dialog: state.dialog,
        global: state.global
    };
};

export default connect(
    mapStateToProps,
    {
        setDialog,
        uncheckAllServices,
        serviceFilterFocused
    }
)(translate('translations')(Dialog));
