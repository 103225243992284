import { TESTIMONIALS_SET } from "../actions/testimonials.actions";

export default function(state = [], action) {
    switch (action.type) {

        case TESTIMONIALS_SET:
            return action.payload.list;

        default:
            return state;
    }
}
