export const FORM_SUBMITTING = '[Form Submitting]';

export const FORM_SUBMITTING_VEHICLE = `${FORM_SUBMITTING} VEHICLE`;
export const FORM_SUBMITTING_GARAGE_LIST = `${FORM_SUBMITTING} GARAGE_LIST`;
export const FORM_SUBMITTING_LOGIN = `${FORM_SUBMITTING} LOGIN`;
export const FORM_SUBMITTING_SIGN_UP = `${FORM_SUBMITTING} SIGN_UP`;
export const FORM_SUBMITTING_BOOKING = `${FORM_SUBMITTING} BOOKING`;
export const FORM_SUBMITTING_SAVE_GARAGE_LIST = `${FORM_SUBMITTING} SAVE GARAGE LIST`;
export const FORM_SUBMITTING_VOUCHER = `${FORM_SUBMITTING} VOUCHER`;

export const formSubmittingVehicle = submitted => ({
    type: FORM_SUBMITTING_VEHICLE,
    payload: {
        submitted
    }
});

export const formSubmittingGarageList = submitted => ({
    type: FORM_SUBMITTING_GARAGE_LIST,
    payload: {
        submitted
    }
});

export const formSubmittingLogin = submitted => ({
    type: FORM_SUBMITTING_LOGIN,
    payload: {
        submitted
    }
});

export const formSubmittingSignUp = submitted => ({
    type: FORM_SUBMITTING_SIGN_UP,
    payload: {
        submitted
    }
});

export const formSubmittingBooking = submitted => ({
    type: FORM_SUBMITTING_BOOKING,
    payload: {
        submitted
    }
});

export const formSubmittingSaveGarageList = submitted => ({
    type: FORM_SUBMITTING_SAVE_GARAGE_LIST,
    payload: {
        submitted
    }
});

export const formSubmittingVoucher = submitted => ({
    type: FORM_SUBMITTING_VOUCHER,
    payload: {
        submitted
    }
});
