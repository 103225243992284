import carStep from '../models/carStep';
import {CAR_STEP_POP, CAR_STEP_SET} from "../actions/carStep.actions";
import {ALLOWED_IN_CAR_STEP_POP} from "../../app/consts/vehicle";

export default function(state = carStep, action) {

    switch (action.type) {

        case CAR_STEP_SET:

            if(action.payload.step === state.step){
                return state;
            }

            let prevSteps = state.prevSteps;

            if(ALLOWED_IN_CAR_STEP_POP.indexOf(state.step) !== -1){
                prevSteps.push(state.step);
            }

            return {...action.payload, prevSteps: prevSteps};

        case CAR_STEP_POP:

            return {...state, step: state.prevSteps[state.prevSteps.length - 1], prevSteps: state.prevSteps.slice(0, state.prevSteps.length - 1)};

        default:
            return state;
    }
}
