import React, {Component} from 'react';
import './index.scss';
import Button from '../../shared/Button/Button';
import {translate} from "react-i18next";

class BaloiseLink extends Component {
    render() {
        const {t, link} = this.props;

        if (!link) return null;

        return (
            <a
                className="baloise-link-container"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="baloise-link container-fluid">
                    <div className="baloise-link-text clearfix col-lg-6">
                        <div className="baloise-link-logo">
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/web/images/baloise-logo.svg`}
                                alt="Baloise logo"
                            />
                        </div>
                        <div className="baloise-link-about">
                            <p className="sponsored">{t('global.sponsored')}</p>
                            <h5>{t('pages.services.ad_baloise.title')}</h5>
                            <p>
                                {t('pages.services.ad_baloise.description')}
                            </p>
                        </div>
                    </div>
                    <div className="baloise-link-price col-lg-2">
                        <p className="sponsored">{t('global.sponsored_post')}</p>
                        <Button size="md" type="baloise-link" extraClass="garage-item-options__cta">
                            {t('pages.services.ad_baloise.btn')}
                        </Button>
                    </div>
                </div>
            </a>
        );
    }
}

export default translate('translations')(BaloiseLink);
