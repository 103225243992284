import { stepMiddleware } from "./feature/step.middleware";
import { carMiddleware } from "./feature/car.middleware";
import { zipDataMiddleware } from "./feature/zipData.middleware";
import { zipMiddleware } from "./feature/zip.middleware";
import { isFormOnly } from "../../utils/form";
import { servicesMiddleware } from "./feature/services.middleware";
import { serviceCheckMiddleware } from "./feature/serviceCheck.middleware";
import { garageMiddleware } from "./feature/garage.middleware";
import { offerMiddleware } from "./feature/offer.middleware";
import { garageListMiddleware } from "./feature/garageList.middleware";
import { authMiddleware } from "./feature/auth.middleware";
import { bookingMiddleware } from "./feature/booking.middleware";
import apiMiddleware from "./core/api.middleware";
import {routeMiddleware} from "./feature/route.middleware";
import {carStepMiddleware} from "./feature/carStep.middleware";
import { loginMiddleware } from "./feature/login.middleware";
import { voucherMiddleware } from "./feature/voucher.middleware";
import { sortMiddleware } from "./feature/sort.middleware";

const form = [
    stepMiddleware,
    carMiddleware,
    zipDataMiddleware,
    routeMiddleware,
    zipMiddleware,
    routeMiddleware,
    carStepMiddleware
];

const app = !isFormOnly() ? [
    servicesMiddleware,
    serviceCheckMiddleware,
    garageMiddleware,
    offerMiddleware,
    garageListMiddleware,
    authMiddleware,
    bookingMiddleware,
    loginMiddleware,
    voucherMiddleware,
    sortMiddleware
] : [];

const core = [
    apiMiddleware
];

export default {
    core,
    feature: [ ...form, ...app ]
}
