import React, { Component } from 'react';
import {translate} from 'react-i18next';
import { connect } from "react-redux";
import { ServiceCheckStep } from "../../../../routes/middleware/service/ServiceCheckStep";
import { serviceCheck, serviceUncheck, uncheckAllServices } from "../../../../store/actions/services.actions";
import { SERVICE_TYPE_LABOUR_CUSTOM, SERVICE_TYPE_LABOUR_SEARCH } from "../../../../store/consts/services.constants";
import ServiceToggler from "../../ServiceToggler";
import AdditionalServiceFilter from "../Filter";
import { filterServiceList, getAdditionalService, isUncheckButtonDisabled } from "../../../../utils/common";
import Button from "../../Button/Button";
import "./index.scss";
import { filterPredefinedServices } from "../../../../store/selectors/services";
import { LOADER_SERVICE_CHECK } from "../../../../store/consts/loader.constants";
import classNames from "classnames";
import { serviceFilterFocused } from "../../../../store/actions/global.actions";

class AdditionalServiceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterValue: '',
            xButtonVisible : false
        };
    }

    handleServiceToggle = (e, service) => {
        const { checked } = e.target;
        const { serviceCheck, serviceUncheck } = this.props;

        if(service.is_official_selected) {
            return null;
        }

        if (service.selected) {
            serviceUncheck(SERVICE_TYPE_LABOUR_CUSTOM, service.id);
            serviceUncheck(SERVICE_TYPE_LABOUR_SEARCH, service.id);
        }
        else if (!service.selected) {
            serviceCheck(service.id);
        }
    };

    handleFilterChange = (filterValue) => {
        this.setState({
            filterValue
        })
    };

    render() {
        const { uncheckAllServices, t, filteredPredefinedServices, hideTitle, showUncheckButton, services: { labour }, loader, global, isHidden } = this.props;
        const { filterValue } = this.state;

        if (isHidden) {
            return null;
        }

        const filteredList = filterServiceList([
            ...labour.search,
            ...labour.custom
        ], filterValue);
        const list = getAdditionalService(filteredList, filteredPredefinedServices);

        const uncheckButton = (
            <div className="additional-service-uncheck-large">
                <Button onClick={uncheckAllServices} extraClass="btn-bluish" disabled={isUncheckButtonDisabled(filteredList)}>
                    {t('global.additional_service.uncheck_all')}
                </Button>
            </div>
        );

        const serviceItem = (service) => (
                <ServiceToggler
                    key={service.id}
                    index={service.id}
                    service={service}
                    onToggle={this.handleServiceToggle}
                    isLoading={loader[`${LOADER_SERVICE_CHECK}-${service.id}`] === true}
                    isAdditional
                />
        );

        return (
            <React.Fragment>
                <AdditionalServiceFilter
                    onChange={this.handleFilterChange}
                    hideTitle={hideTitle}
                />
                <div className={classNames("additional-service-list clearfix", {
                    'additional-service-list--push-top': global.serviceFilterFocused
                })}>
                    {list.map(serviceItem)}
                </div>
                {showUncheckButton && uncheckButton}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader,
        services: state.services,
        dialog: state.dialog,
        global: state.global,
        filteredPredefinedServices: filterPredefinedServices(state),
    }
};

export default connect(
    mapStateToProps,
    {
        serviceCheck,
        serviceUncheck,
        uncheckAllServices,
        serviceFilterFocused,
    }
)(ServiceCheckStep(translate('translations')(AdditionalServiceList)));
