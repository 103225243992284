import React, {Component} from 'react';
import './GarageSortBy.scss';
import {translate} from "react-i18next";
import onClickOutside from "react-onclickoutside";
import classNames from 'classnames';
import {voucherHideElements} from "../../../utils/voucherHideElements";
import {connect} from "react-redux";
import {onSortChange} from "../../../store/actions/sort.actions";

class GarageSortBy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        };
    }

    componentDidMount() {
        // this.props.onSortChange(this.props.sort[0]);
    }

    handleSortChange = (sortOption) => {
        this.props.onSortChange(sortOption);
        this.toggleDropdown();
    };

    handleClickOutside = () => {
        this.setState({
            isOpened: false
        })
    };

    handleClickInside = (e) => {
        if (typeof e.target.classList[0] === 'undefined' || !e.target.classList[0].includes('gsb__cta-text')) {
            setTimeout(() => {
                this.setState({
                    isOpened: false
                })
            }, 0);
        }
    };

    toggleDropdown = () => {
        this.setState({
            isOpened: !this.state.isOpened
        })
    };

    renderFilterList = () => {
        const {isOpened} = this.state;
        const {t, sort} = this.props;

        if (!isOpened)
            return null;

        const label = (
            <div className="gsb-dropdown__title">
                <p>{t('pages.services.sort_by.title')}</p>
                <span className="gsb-dropdown__title-icon icon-menu"></span>
            </div>
        );

        const sortByOptions = sort.map((item, index) => (
            <div key={index}
                 className={classNames("gsb-dropdown__list-item", {
                     "gsb-dropdown__list-item--active": item.active === true
                 })}
                 onClick={() => this.handleSortChange(item)}
            >
                <p>{item.name}</p>
            </div>
        ));

        return (
            <div className="gsb-dropdown">
                {label}
                <div className="gsb-dropdown__options">
                    {sortByOptions}
                </div>
            </div>
        )
    };

    renderCTA = () => {
        const {isOpened} = this.state;
        const {sort} = this.props;

        if (voucherHideElements()) {
            return null;
        }

        const label = (
            <React.Fragment>
                <p className="gsb__cta-text"
                   onClick={this.toggleDropdown}
                >
                    <span className="gsb__cta-icon icon-sort"></span>
                    {sort.find(option => option.active === true).name}
                </p>
            </React.Fragment>
        );

        return (
            <div className="gsb__cta">
                {label}
                {isOpened && this.renderFilterList()}
            </div>
        )
    };

    render() {
        const {t, onSaveList} = this.props;

        const label = (
            <div className="gsb__title">
                <span className="gsb__title-icon icon-download"></span>
                <p onClick={onSaveList}>
                    <span className="hide-mobile">
                        {t('pages.services.sort_by.save_research')}
                    </span>
                    <span className="hide-desktop">
                        {t('pages.services.sort_by.save_research_mobile')}
                    </span>
                </p>
            </div>
        );

        return (
            <div className="gsb gsb__container clearfix" onClick={this.handleClickInside}>
                {!voucherHideElements() && label}
                {this.renderCTA()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        sort: state.sort
    };
};

export default connect(mapStateToProps, {
    onSortChange
})(translate('translations')(onClickOutside(GarageSortBy)));
