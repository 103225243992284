import React, {Component} from 'react';
import './Voucher.scss';
import imagesPath from "../../../utils/imagesPath";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {voucherHideElements} from "../../../utils/voucherHideElements";
import classNames from "classnames";

class Voucher extends Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        };
    }

    getLogo = (voucher) => {

        if (voucher.brand.logo) {
            return voucher.brand.logo;
        }

        return imagesPath('voucher-default.svg');
    };

    getTitle = (voucher) => {
        let title = voucher.brand.title;

        title = title.replace('{FIRST_NAME}', this.props.upto_user_first_name);

        return title;
    };

    toggle = () => {
        this.setState({
            isOpened: !this.state.isOpened
        })
    };

    renderArrow = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <p className="voucher-brand-text-show-more"
                   onClick={this.toggle}>{t('pages.services.voucher.upto.show_more')}</p>
                <div
                    className={classNames({
                        'voucher-brand-icon-more': true,
                        'voucher-brand-icon-more-closed': this.state.isOpened === false,
                        'voucher-brand-icon-more-opened': this.state.isOpened === true,
                    })}
                    onClick={this.toggle}>
                    <i className="icon-chevron-left-bold"></i>
                </div>
            </React.Fragment>
        );
    };

    renderShowMore = () => {
        const {t} = this.props;

        return (
            <div className="voucher-brand-more-description"
                 dangerouslySetInnerHTML={{__html: t('pages.services.voucher.upto.more_description')}}/>
        )
    };

    render() {
        const {voucher} = this.props;

        return (
            <div className="w-box">
                <div className={voucher.brand.css === 'ryd-voucher' ? 'w-box__body mobile-m--10' : 'w-box__body'}>
                    <div className={`voucher-brand-custom ${voucher.brand.css}`}>
                        <div className="voucher-brand-text">
                            <div className="voucher-brand-text__title">{this.getTitle(voucher)}</div>
                            <div className="voucher-brand-text__desc"
                                 dangerouslySetInnerHTML={{__html: voucher.brand.text}}/>

                            {voucherHideElements() ? this.renderArrow() : null}
                        </div>

                        <div className="voucher-brand-logo">
                            <img src={this.getLogo(voucher)} alt={voucher.brand.title}/>
                        </div>

                        {voucherHideElements() && this.state.isOpened === true ? this.renderShowMore() : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        upto_user_first_name: state.global.upto_user_first_name
    };
};

export default connect(
    mapStateToProps,
)(translate('translations')(Voucher));
