export const PREDEFINED_SERVICES = '[Predefined Services]';

export const PREDEFINED_SERVICES_SET = `${PREDEFINED_SERVICES} SET`;
export const PREDEFINED_SERVICES_REMOVE_ID_FROM_LIST = `${PREDEFINED_SERVICES} REMOVE ID FROM LIST`;

export const setPredefinedServices = services => ({
    type: PREDEFINED_SERVICES_SET,
    payload: {
        services
    }
});

export const removeIdFromPredefinedList = id => ({
    type: PREDEFINED_SERVICES_REMOVE_ID_FROM_LIST,
    payload: {
        id
    }
});
