export const FLASH_MESSAGE = '[Flash Message]';

export const FLASH_MESSAGE_ADD = `${FLASH_MESSAGE} ADD`;
export const FLASH_MESSAGE_REMOVE = `${FLASH_MESSAGE} REMOVE`;
export const FLASH_MESSAGE_GET_ALL = `${FLASH_MESSAGE} GET_ALL`;

export const getFlashMessages = () => ({
    type: FLASH_MESSAGE_GET_ALL
});

export const addFlashMessage = (message, type) => ({
    type: FLASH_MESSAGE_ADD,
    payload: {
        message,
        type
    }
});

export const removeFlashMessage = id => ({
    type: FLASH_MESSAGE_REMOVE,
    payload: {
        id
    }
});
