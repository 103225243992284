export const SORT = '[Sort]';

export const SORT_BY_CHANGE = `${SORT} BY CHANGE`;
export const ON_SORT_CHANGE = `${SORT} ON CHANGE`;

export const onSortChange = (option) => ({
    type: ON_SORT_CHANGE,
    payload: {
        option
    }
});

export const sortByChange = id => ({
    type: SORT_BY_CHANGE,
    payload: {
        id
    }
});
