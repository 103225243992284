import { ON_SORT_CHANGE, sortByChange } from "../../actions/sort.actions";
import { sortListByKey } from "../../../utils/common";
import { setGarageList } from "../../actions/garageList.actions";
import store from "../../store";

export const sortMiddleware = ({ dispatch }) => next => {

    return action => {
        next(action);
        let list = store.getState().garageList;

        switch (action.type) {
            case ON_SORT_CHANGE:
                dispatch(sortByChange(action.payload.option.id));

                if ( action.payload.option.key === "best_match" ) {
                    let bestMatchResult = [];

                    let minPriceGarage = Object.assign([], list).sort((a, b) => sortListByKey(a, b, "price"))[0],
                        officialGarage,
                        closestGarage,
                        biggestRatingGarage;

                    bestMatchResult.push(minPriceGarage);

                    let listByDistance = Object.assign([], list).sort((a, b) => sortListByKey(a, b, "distance"));
                    let listWithHighestRating = Object.assign([], list).sort((a, b) => sortListByKey(a, b, "rating", false));
                    let listWithOfficials = list.filter(garage => garage.is_official_for_car_make === true);

                    if (listWithOfficials.length > 0) {
                        officialGarage = listWithOfficials.find(garage => {
                            if (!bestMatchResult.find(bestMatchGarage => bestMatchGarage.id === garage.id)) {
                                return garage;
                            }
                        });

                        if(officialGarage){
                            bestMatchResult.push(officialGarage);
                        }
                    }

                    if (listByDistance.length > 0) {
                        closestGarage = listByDistance.find(garage => {
                            if (!bestMatchResult.find(bestMatchGarage => bestMatchGarage.id === garage.id)) {
                                return garage;
                            }
                        });

                        if(closestGarage){
                            bestMatchResult.push(closestGarage);
                        }
                    }

                    if (listWithHighestRating.length > 0) {
                        biggestRatingGarage = listWithHighestRating.find(garage => {
                            if (!bestMatchResult.find(bestMatchGarage => bestMatchGarage.id === garage.id)) {
                                return garage;
                            }
                        });

                        if(biggestRatingGarage){
                            bestMatchResult.push(biggestRatingGarage);
                        }
                    }

                    bestMatchResult.map(bestMatchGarage => {
                        list.forEach((garage, index) => {
                            if (bestMatchGarage.id === garage.id) {
                                list.splice(index, 1);
                            }
                        });
                    });
                    dispatch(setGarageList([
                        ...bestMatchResult,
                        ...list
                    ]))
                }

                if ( action.payload.option.key === "price" ) {
                    dispatch(setGarageList(list.sort((a, b) => sortListByKey(a, b, action.payload.option.key))))
                }

                if ( action.payload.option.key === "distance" ) {
                    dispatch(setGarageList(list.sort((a, b) => sortListByKey(a, b, action.payload.option.key))))
                }

                break;
            default:
                break;
        }
    };
};

export default {
    sortMiddleware
};
