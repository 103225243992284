export const TERMIN = `[Simple Date Picker]`;
export const TERMIN_SET_DATE = `${TERMIN} SET DATE`;
export const TERMIN_SET_TIME = `${TERMIN} SET TIME`;
export const TERMIN_RESET = `${TERMIN} RESET`;

export const terminSetDate = date => ({
    type: TERMIN_SET_DATE,
    payload: {
        date
    }
});

export const terminSetTime = (time, period) => ({
    type: TERMIN_SET_TIME,
    payload: {
        time,
        period
    }
});

export const terminReset = () => ({
    type: TERMIN_RESET
});
