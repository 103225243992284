import {LOADER_API_LIST_SERVICES, LOADER_INIT_OFFER} from "../consts/loader.constants";

export default {
    global: false,
    [LOADER_INIT_OFFER]: false,
    serviceCheck: {},
    formVehicle: {},
    licencePlate: false,
    [LOADER_API_LIST_SERVICES]: false,
};
