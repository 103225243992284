import React, { Component } from 'react';
import './index.scss';

class IllustrationCard extends Component {
    render() {
        const { title, description, imagePath } = this.props;

        return (
            <div className="illustration-card container">
                <div className="col-sm-4">
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
                <div className="col-sm-4">
                    <img src={imagePath} alt={title} />
                </div>
            </div>
        );
    }
}

export default IllustrationCard;
