//@flow
import React, { Component } from 'react';
import './InputCheckout.scss';

type Props = {
    value?: string,
    label?: string,
    placeholder?: string,
    name: string,
    id: string,
    onChange?: Function,
    onBlur?: Function
};

type State = {
    checked: boolean
};

class InputCheckout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            checked: props.checked || ''
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            checked: nextProps.checked
        });
    }

    handleChange = (e: Event) => {
        this.setState({ checked: e.target.checked });
    };

    render() {
        const { value } = this.state;
        const {
            name,
            id,
            placeholder,
            label,
            onChange,
            onClick,
            onBlur,
            error,
            checked,
            inputControlClass
        } = this.props;

        return (
            <div
                className={
                    inputControlClass ? 'input-control ' + inputControlClass : 'input-control'
                }
            >
                <div className="input-wrapper">
                    <input
                        type="checkbox"
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onChange={e => {
                            this.handleChange(e);

                            if (onChange) {
                                onChange(e);
                            }
                        }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                        value={value}
                        checked={checked}
                        autoComplete="off"
                    />
                    <label htmlFor={name}>
                        {label &&
                            (label)
                        }
                    </label>
                </div>
                {error && (
                    <span className="form-error">
                        <p>{error}</p>
                    </span>
                )}
            </div>
        );
    }
}

export default InputCheckout;
