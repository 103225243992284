//@flow
import React, { Component } from 'react';
import './Button.scss';

class Button extends Component {
    render() {
        const { children, size, type, icon, extraClass, onClick, disabled, id } = this.props;

        return (
            <button
                id={id}
                type="submit"
                className={`btn btn--${size} btn--${type} ${extraClass}`}
                onClick={e => {
                    if (onClick) onClick(e);
                }}
                disabled={disabled}
            >
                {icon && (
                    <div className="btn__icon">
                        <span className={icon} />
                    </div>
                )}
                {children}
            </button>
        );
    }
}

export default Button;
