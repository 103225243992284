import { ZIP_DATA_CLEAR, ZIP_DATA_SET } from '../actions/zipData.actions';
import zipData from '../models/zipData';

export default function(state = zipData, action) {
    switch (action.type) {
        case ZIP_DATA_SET:
            return action.payload.zipData;

        case ZIP_DATA_CLEAR:
            return zipData;

        default:
            return state;
    }
}
