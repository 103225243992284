//@flow
import React, { Component } from 'react';
import Button from "../../shared/Button/Button";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { saveGarageList } from "../../../store/actions/garageList.actions";
import "./DialogAdditionalService.scss";

class DialogAdditionalServiceFooter extends Component<{}, {}> {

    render() {
        const { t, onCloseDialog, onUncheckAllServices } = this.props;

        return (
            <div className="modal__footer">
                <div className="modal__footer__item">
                    <Button size="lg" extraClass="btn-grayed-out" onClick={onUncheckAllServices} >
                        {t('global.additional_service.uncheck_all')}
                    </Button>
                </div>
                <div className="modal__footer__item">
                    <Button size="lg" type="primary" onClick={onCloseDialog}>
                        {t('global.additional_service.save')}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global: state.global,
        dialog: state.dialog,
        formSubmitting: state.formSubmitting
    }
};

export default connect(
    mapStateToProps, {
        saveGarageList,
    }
)(translate('translations')(DialogAdditionalServiceFooter));
