/* eslint-disable */
import React from "react";

export default {
    translations: {
        links: {
            home: 'Home',
            services: 'Services',
            garage: 'Garage'
        },
        titles: {
            home: 'Carhelper – Entrez les informations à propos de votre voiture',
            services: 'Carhelper – Comparez les prix de garages à {{city}}',
            garage: 'Carhelper – Réservez un service chez {{garageName}}',
            booking: 'Carhelper – Votre demande à été envoyé.',
        },
        global: {
            filter: {
                title: 'Filtres',
                hide: 'Masquer les filters',
                distance: 'Distance',
                price: 'Prix',
                else: 'Autre',
                ratings: {
                    label: 'Avec note',
                    description: 'Uniquement les garages avec notes'
                },
                instant_booking: {
                    label: 'Instant booking',
                    description: 'Uniquement les garages avec Instant Booking'
                },
                official_garage: {
                    label: 'Garage officiel',
                    description: 'Uniquement les garages certifiés {carBrand}',
                },
                no_filters: 'Aucun filtre sélectionné',
                save: 'Tout décocher',
                reset: 'Tout décocher',
            },
            illustration_card: {
                no_garages_nearby: {
                    title: "Pas de garage à proximité offrant des services sélectionnés",
                    description: "Modifiez votre position pour voir les offres dans différents secteurs."
                }
            },
            currency: 'CHF',
            tax: 'Taxes',
            total: 'Total',
            total_with_vat: 'Total TTC',
            discount: 'Rabais',
            service: 'Service',
            to: 'to',
            sponsored: 'Sponsorisé',
            sponsored_post: 'Sponsorisé',
            km: 'km',
            car: {
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                go_back: 'Editer',
                update_info: 'Mettre à jour les informations sur la voiture'
            },
            official_service: {
                title: 'RECOMMANDATION POUR VOTRE VOITURE',
                description: 'Grâce aux données que vous nous avez fournies et les spécifications du constructeur {{carBrand}}, nous avons mis en place un service personnalisé pour votre voiture:',
                includes: 'COMPREND'
            },
            preselected_service: {
                title: 'Service présélectionné: '
            },
            additional_service: {
                open: 'Liste complète de services',
                title: 'Maintenance',
                description: 'Définissez des préférences supplémentaire afin d\'affiner les résultats.',
                uncheck_all: 'Tout décocher',
                save: 'Sauver'
            }
        },
        tab: {
            car: 'DONNÉES DU VÉHICULE',
            services_car: 'Ma voiture',
            services_maintenance: 'Entretien et réparation',
            order_overview: 'Résumé de la commande',
        },
        form: {
            placeholder: {
                range: 'Sélectionnez le modèle',
                type: 'Sélectionnez la version',
                gear: 'Sélectionnez un équipement',
                first_registration: '2019',
                qual_md_list: 'Veuillez sélectionner l’équipement',
                qual_lt_list: 'Veuillez sélectionner la carrosserie',
                mileage: 'Sélectionnez le kilométrage',
                zip: 'Sélectionnez le ZIP',
                make: 'Marque',
                username: 'Votre nom d\'utilisateur',
                year: '2019',
                km_stand: 'Sélectionnez le nombre de km parcourus',
                first_name: 'Nom',
                last_name: 'Prénom',
                email: 'Email',
                phone: 'Numéro de téléphone',
                password: 'Mot de passe',
                city: 'Ville',
                color: 'Couleur',
                canton: 'VD',
                plate_number: '18 341',
                default: {
                    select: 'Veuillez sélectionner',
                },
                date: 'choisir la date',
                morning: 'Matin',
                afternoon: 'Après-midi',
                voucher: 'Code coupon',
            },
            label: {
                licence_plate: 'Plaque d\'immatriculation',
                make: 'Marque',
                range: 'Modèle',
                type: 'Version',
                gear: 'Équipement',
                first_registration: 'Année d\'inscription',
                qual_md_list: 'Caractéristiques de la voiture',
                qual_lt_list: 'Version de voiture',
                mileage: 'Kilométrage',
                zip: 'ZIP',
                username: 'Nom d\'utilisateur',
                year: 'Année d\'inscription',
                km_stand: 'Nombre de km',

                first_name: 'Prénom',
                last_name: 'Nom',
                phone: 'Numéro de téléphone',
                email: 'Email',
                password: 'Mot de Passe',
                agb_agreement: 'Accepter les',
                terms_and_condition: ' termes et conditions',
                date: 'Date',
                availability: 'Disponibilité',
                bookFor: 'Réserver pour',
                book: 'Réserver',
                city: 'Ville',
                color: 'Couleur:',
            },
            button: {
                next: 'Prochain',
                confirm: 'Confirmer',
                submit: 'Chercher des offres',
            },
            select: {
                no_result: 'Aucun résultat'
            },
            saveGarageList: {
                title: 'Sauvegarder la recherche',
                description: 'Enregistrez cette recherche pour plus tard en entrant votre adresse e-mail. Vous recevrez un lien dans quelques minutes',
                submit: 'Sauvegarder la liste de garage',
                save: 'Sauvegarder',
                success: "Nous avons bien enregistré votre liste. Vous recevrez une confirmation par email dans quelques minutes.",
            },
            resetPassword: {
                link: 'Avez-vous oublié votre mot de passe?',
                title: 'Réinitialiser le mot de passe',
                description: 'Avez-vous oublié votre mot de passe? Entrez votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser.',
                submit: 'Réinitialiser',
                success: "Si un e-mail existe, vous devriez recevoir un e-",
            },
        },
        validations: {
            label: {
                plate_number: 'plate number [fr]',
                make: 'marque',
                range: 'modèle',
                type: 'version',
                first_registration: 'année d\'enregistrement',
                gear: 'équipement',
                qual_md_list: 'caractéristiques de la voiture',
                qual_lt_list: 'version de voiture',
                mileage: 'kilométrage',
                zip: 'zip',
                km_stand: 'Nombre de km',

                firstname: 'prénom',
                lastname: 'nom',
                phone: 'numéro de téléphone',
                email: 'email',
                password: 'mot de passe',
                agb_agreement: 'termes et conditions',

                zip_not_found: 'Aucune ville avec le code postal {{name}}.',
                zip_not_valid: 'Veuillez sélectionner un code postal valide.',
                zip_required: 'ZIP est requis.',
                zip_must_be_number: 'Doit contenir des nombres.',

                voucher: 'Voucher',
            },
            default: {
                mixed: {
                    required: 'Remplissez ${label}'
                },
                string: {
                    email: 'Email invalide’',
                    min: 'Valeur trop courte',
                    max: 'Valeur trop longue',
                    test: 'Acceptez ${label}',
                    matches: 'Entrée invalide',
                },
                boolean: {
                    required: 'Acceptez ${label}'
                }
            },
            termin_picker: {
                drop_off_title: "Disponibilité pour dépot",
                drop_off_description: "Sélectionnez la date et l'heure à laquelle vous souhaitez déposer votre voiture chez le garagiste.",
                drop_off_description_upto: "Sélectionnez la date et l'heure à laquelle vous souhaitez déposer votre voiture chez le garagiste.",
                maximum: 'Le nombre maximum de dates à choisir est 5.',
                required: 'Veuillez choisir une date',
            },
            licence_plate: {
                canton: {
                    valid: 'Canton valide',
                    nonValid: 'Canton erroné”'
                },
                number: {
                    valid: 'Numéro valide',
                    nonValid: 'Numéro erroné'
                }
            },
            attempts: 'Tu as {{attempt}} essaies supplémentaires.',
            attempt: 'Tu as 1 essai supplémentaire.',
            voucher_minimum_price: "La remise n\'est pas appliquée.\nValeur de commande minimum de {{price}} CHF",
        },
        pages: {
            home: {
                title: 'Entrez les informations à propos de votre voiture',
                chat_text: 'Un doute? ',
                chat_button: ' Live Chat',
                licence_plate: {
                    title: 'Entrez les données de votre véhicule et recevez les offres instantanément.',
                    manual: 'Vous ne connaissez pas votre numéro de plaque?',
                    sourcing: 'Recherche de votre véhicule',
                    sourced_success: 'Nous avons trouvé les données de votre voiture.',
                },
                form_sidebar_label_1: '2 ans de garantie sur le travail et les pièces',
                form_sidebar_label_2: 'Comparaison de plus de 450 garages',
                form_sidebar_label_3: 'Annulation gratuite',
                licence_plate_tooltip: 'L’identification du véhicule est réalisée à l’aide des sources suivantes: Office fédéral de la voirie, OFROU.',
                source_provider: 'Source: Office fédéral des routes (OFROU) - Astra.',
            },
            services: {
                labour_search_item_tooltip: "La maintenance suivante est incluse dans ce service:",
                title: 'Parfait! Il ne reste plus qu\'à choisir un garage',
                submit: 'Voir les offres',
                title_garages: 'Garages',
                make_recommendation: 'Service recommandé pour',
                save_up_to: 'Nos clients économisent jusqu\'à {{percent}}%',
                labour: {
                    title: 'Liste de travaux d\'entretien',
                    not_found: 'Aucun résultat trouvé...',
                    not_exist: 'Oups, il semble que ce service ne soit pas disponible pour votre véhicule. S\'il vous plaît, ou contactez-nous pour une offre individuelle pour cela.',
                },
                sort_by: {
                    best_match: 'Meilleur résultat',
                    affordability: 'Moins cher',
                    proximity: 'Proximité',
                    save_research: 'Sauvegarder la recherche',
                    title: 'Trier par',
                },
                rating: {
                    rate1: 'Moyen',
                    rate2: 'Convenable',
                    rate3: 'Bien',
                    rate4: 'Très bien',
                    rate5: 'Génialissime',
                },
                garage_api_error: '[FR] Oops, something happened on the server... We couldn\'t fetch garages at the moment.',
                garages_no_results: 'Aucun garage trouvé.',
                garages_load_more: 'Voir plus',
                ad_baloise: {
                    title: 'Besoin d\'une assurance automobile? Calculez maintenant la prime pour votre voiture',
                    description: '   L\'assurance auto la moins chère en Suisse avec les meilleures ' +
                        'notes de clients et les avantages de l\'assurance individuelle.',
                    btn: 'Calculer sur baloise.ch',
                },
                garage_item: {
                    highly_booked: 'Fortement réservé',
                    garage_of_year: 'Garage de l\'année',
                    cheapest: 'Best deal',
                    show_details: 'Afficher les détails',
                    certificated: 'Partenaire {{make}} certifié',
                    book_now: 'Voir l’offre',
                    axa_partner: 'AXA Premium Partner',
                },
                official_not_found_too_young: 'Whoops, nous n’avons trouvé aucun service recommandé pour votre voiture. Veuillez les sélectionner manuellement ci-dessous.',
                official_not_found: 'Whoops, nous n’avons trouvé aucun service recommandé pour votre voiture. Veuillez les sélectionner manuellement ci-dessous.',
                voucher: {
                    upto: {
                        show_more: 'Comment ça marche',
                        more_description: `
                        <ul>
                        <li><span>1</span> <span>Sélectionnez un garage dans les environs</span></li>
                        <li><span>2</span> <span>Choisissez une date pour le rendez-vous</span></li>
                        <li><span>3</span> <span>Une confirmation vous sera envoyée</span></li>
                        </ul> `,
                    }
                }
            },
            garage: {
                checkoutPaymentTitle: "Paiement",
                checkoutPaymentDescription: '<p>Vous ne serez facturé que lorsque le service aura été complété avec succès par le garage et que vous en serez satisfait.</p><p>Le paiement peut être effectué directement au garage lorsque vous récupérez votre voiture.</p>',
                login_via_google: 'S\'inscrire avec Google',
                login_via_facebook: 'S\'inscrire avec Facebook',
                login_separator_or: 'ou',

                // TODO REMOVE
                please_login: 'Veuillez vous connecter ou',
                link_sign_up: 'vous inscrire',
                register_to_finalize_booking: 'pour finaliser votre réservation.',
                login_to_finalize_booking: 'ou inscrivez-vous pour finaliser votre réservation.',
                please: 'Veuillez',
                btn_login: 'vous connecter',
                // TODO REMOVE

                includes: 'INCLUS',
                price_details: 'Voir les détails',
                btn_continue: 'Continuer',
                btn_finish_booking: 'Confirmer la réservation',
                btn_checkout_for: 'Checkout pour {{price}}',
                btn_checkout: 'Réserver',
                link_logout: 'Si vous souhaitez vous déconnecter, veuillez cliquer',
                link_logout_here: 'ici',
                logged_as: 'Connecté en tant que',
                add_more_inputs: 'Et une dernière chose',
                add_confirm_inputs: 'Veillez ajouter ou confirmer votre numéro de téléphone afin que votre' +
                    'garage puisse vous contacter.',
                show_summary: 'Résumé de la commande',


                auth: {
                    register: 'Veuillez vous inscrire ou <1> connecter vous </1> pour compléter votre réservation .',
                    login: 'Veuillez vous connecter ou <1> inscrivez-vous </1> pour finaliser votre réservation.',
                },

                termin_picker: {
                    i_am_flexible: 'Flexible'
                },
                why_carhelper: {
                    title: 'Pourquoi réserver un service via Carhelper? ',
                    item1: 'Prix fixes attractifs',
                    item2: 'Plus de 450 garages à comparer',
                    item3: 'Haute qualité et transparence grâce aux notes de clients',
                    item4: '2 ans de garantie sur le travail et les pièces',
                },
                predefined: {
                    selected: 'Garage sélectionné',
                    nearby: 'Garages à proximité',
                    inactive: 'Ce garage n’est pas encore inscrit sur Carhelper. Nous ne pouvons donc pas calculer le prix pour ce service. Veuillez sélectionner un garage alternatif de la même région ci-dessous:',
                    out_of_make: 'Ce garage ne propose pas de service pour les véhicules {{make}}.',
                },
                progress_load: {
                    searching_for_offers: 'Recherche d’offres près de {{city}}',
                    sourcing_prices: 'Établissement des prix de garages',
                    finding_deals: 'Recherche des meilleures offres',
                },
                ab_test_discount: {
                    title: 'Offre Carhelper - Seulement valable aujourd\'hui',
                    value: 'Économisez {{discount}}%  sur toutes les commandes. Garantie du meilleur prix.',
                },
                ab_test_discount_checkout: {
                    title: 'Offre Carhelper',
                    value: 'Économisez {{discount}}%  sur toutes les commandes.',
                },
                testimonials_title: 'Commentaires d\'autres clients',
                termin: 'Rendez-vous',
                customer_data: 'Vos coordonnées',
            },
            confirm: {
                title: 'Félicitations! Demande envoyée.',
                description: `Le garage va maintenant vérifier la commande et la confirmer dès que possible. Vous recevrez la confirmation par e-mail et SMS. Si le garage ne peut pas respecter les dates souhaitées, il vous contactera par téléphone pour convenir d'un autre rendez-vous. Avez-vous des questions sur votre commande? Vous pouvez nous contacter à tout moment.`,
                order_number: 'Numéro de commande:',
                carhelper_team: 'Ton équipe Carhelper',
                order_overview: 'Résumé de la commande',
            }
        },

        preparedServices: {
            oil: {
                name: null,
                second_name: null
            },
            zahriemen: {
                name: 'courroie de distribution',
                second_name: 'vérifier'
            },
            break_fluid: {
                name: null,
                second_name: null
            },
            airfilter: {
                name: null,
                second_name: null
            },
            klimafilter: {
                name: null,
                second_name: null
            }
        }
    }
};
