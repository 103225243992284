export const GARAGE = '[Garage]';

export const GARAGE_SET = `${GARAGE} SET`;
export const GARAGE_SELECT = `${GARAGE} SELECT`;
export const GARAGE_CLICK = `${GARAGE} CLICK`;

export const selectGarage = garage => ({
    type: GARAGE_SELECT,
    payload: {
        garage
    }
});

export const setGarage = garage => ({
    type: GARAGE_SET,
    payload: {
        garage
    }
});

export const garageClick = garageId => ({
    type: GARAGE_CLICK,
    payload: {
        garageId
    }
});
