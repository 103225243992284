import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';

const getLocale = () => {
    if ( window.settings.lang === 'de' ) {
        return de;
    } else if ( window.settings.lang === 'fr') {
        return fr;
    } else if ( window.settings.lang === 'en' ) {
        return en;
    } else {
        return en;
    }
};

registerLocale('datepicker-locale', getLocale());
