import {GARAGE_DISCOUNT_SET} from "../actions/garageDiscount.actions";
import garageDiscount from "../models/garageDiscount";

export default function(state = garageDiscount, action) {
    switch (action.type) {

        case GARAGE_DISCOUNT_SET:
            return action.payload.discount;

        default:
            return state;
    }
}
